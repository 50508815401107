<template>
    <div class="planPackages">
        <div class="carousel">
            <div class="carousel-type">
                <div class="inner" >
                    <div :class="'scroll-'+this.coverageOption" class="slide-area">
                        <SumInsureItem 
                            v-for="(item, index) in optionItem" :key="index"
                            :text="String((item == 'Not covered' ? this.notCoverTxt : formatNumber(item)))"
                            :active="item == defaultSelected"
                            @action="selectSumInsureItem(item,this.coverageOption,index)"
                            :class="'item-pack'+' ' + slideClassName"
                            :subtitle="String(this.slideData[item])"
                            :numberType="item != 'Not covered'"
                            :currencySymbol="item != 'Not covered'"
                        />
                        {{ item }}
                        
                    </div>
    
    
                </div>
            </div>
        </div>
    
    </div>
    </template>
    <script>
    import Mixin from "@/components/Mixin";
    import SumInsureItem from './SumInsureItems.vue';
    export default {
        name: "PlanPackages",
        mixins: [Mixin],
        props: {
            packages: {
                type: Object,
            },
            paymentType: {
                type: String,
                default: ""
            },
            addOnSelected:{
                type: Object,
            },
            multiOptSelected:{
                type: Object,
            },
            defaultSelected: {
                type: String,
                default: null
            },
            language: {
                type: String,
                default: "id"
            },
            coverageOption: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                innerStyles: {},
                step: "",
                isMinus: false,
                slideData:{},
                from: 0,
                to: 4,
                lengthSlide: 0,
                counter: 1,
                slideClassName: "",
                width: 0,
                windowWidth: 0,
                paymentTriger: "",
                optionItem: [],
                optItems: []
            }
        },
        components: { 
            SumInsureItem 
        },
        watch: {
            windowWidth: function(value) {
                if(value > 480) {
                    this.to = 4;
                } else {
                    this.to = 3;
                }
            },
            paymentType: function(value) {
                if(value !== this.paymentTriger) {
                    this.paymentTriger = value;
                }
            },
            packages: function(value) {
                this.getItems();
            },
            addOnSelected:{
                handler(){
                    this.getItems();
                },
                deep: true
            },
            multiOptSelected:{
                handler(){
                    this.getItems();
                },
                deep: true
            }
        },
    
        created(){
            this.getItems();
        }, 
    
        mounted () {
        
            this.lengthSlide = this.to === 3 ? Math.floor(this.optionItem.length - 3) + 1 : Math.floor(this.optionItem.length - 4) + 1;
    
            this.windowWidth = window.innerWidth;
            window.addEventListener('resize', () => (this.windowWidth = window.innerWidth));
            this.paymentTriger = this.paymentType;
        },
        methods: {
    
            getItems(){
                this.slideData = this.packages[this.coverageOption];
                if(this.coverageOption !== 'VehicleSumAssured'){
                    this.slideData['Not covered'] = "0";   
                }
                this.sortItems();
            },
    
            selectSumInsureItem(value,coverage,index) {
                this.$emit('selected', String(value));
                $(".scroll-"+coverage).each(function(){
                    var scrollBoxWidth = $(this).width();
                    var wrapperWidth = ($(this).find(".item-pack").length * 112) + (($(this).find(".item-pack").length -1) * 10);
                    var x = 112 * index;
                    if( wrapperWidth > scrollBoxWidth ){
                        $(this).animate({scrollLeft: x}, 500);
                    }
                });
            },

            numberFormat(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            millionFormat(x){
                return (Math.abs(x) > 999999 ? Math.sign(x)*((Math.abs(x)/1000000).toFixed(1)) : x);
            },
            handleSign(value) {
                if (value) return "-";
                else if (this.subtitle == 0) return "";
                else return "+";
            },
            reformat(value) {
                if (value < 0) {
                    this.isMinus = true;
                    return Math.abs(value);
                }
                this.isMinus = false;
                return value;
            },
    
            sortItems() {   
                this.optItems = Object.keys(this.slideData).sort((a, b) => {
                    if (a === "Not covered") {
                        return -1;
                    } else {
                        return parseInt(a) - parseInt(b);
                    }
                });
    
                this.optionItem = this.optItems
            },
        },
        computed: {
            notCoverTxt() {
                return this.$i18n.t('motorVehicle.notCoverTxt');
            }
    
        }
    }
    </script>
    <style scoped>
    .carousel {
        width: 100%;
    }
    .slide-area{
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px !important;
        /* padding-bottom: 16px; */
    }
    
    
    .slide-area::-webkit-scrollbar {
        height: 8px;
    }
    .slide-area::-webkit-scrollbar-track {
        margin-left: 48px;
        margin-right: 48px;
        background: #F2F2F2;
        -webkit-border-radius: 12px;
        border-radius: 12px;
    }
    .slide-area::-webkit-scrollbar-thumb {
      -webkit-border-radius: 12px;
      border-radius: 12px;
      background: #00539A;
    }
    .slide-area::-webkit-scrollbar-thumb:window-inactive {
      background: #00539A;
    }
    
    
    /* * {
        scrollbar-width: thin;
        scrollbar-color: #CED6E0 #F1F2F6;
    }
    
    *::-webkit-scrollbar {
        height: 8px;
    }
    
    *::-webkit-scrollbar-track {
        background: #F2F2F2;
        border-radius: 12px;
        margin-left: 48px;
        margin-right: 48px;
    }
    
    *::-webkit-scrollbar-thumb {
        background-color: #00539A;
        border-radius: 12px;
        border: 3px solid #00539A;
    } */
    
    /* .slide-area {
        display: inline-flex;
        width: 100%;
        padding-bottom: 16px;
    } */
    
    .carousel-type {
        display: block;
    }
    
    .slideInLeft {
        -webkit-animation-name: slideInLeft;
        animation-name: slideInLeft;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    
    .slideInRight {
        -webkit-animation-name: slideInRight;
        animation-name: slideInRight;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    @-webkit-keyframes slideInLeft {
        0% {
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
            visibility: visible;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
    @keyframes slideInLeft {
        0% {
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
            visibility: visible;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    } 
    
    @-webkit-keyframes slideInRight {
        0% {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
            visibility: visible;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
    @keyframes slideInRight {
        0% {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
            visibility: visible;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    } 
    
    @media screen and (max-width: 480px) {
        .packageItem {
            max-height: 175px;
        }
        .packageItem .package {
            /* padding: 20px 10px; */
            font-size: 10px!important;
        }
        .carousel {
            width: 100%!important;
            overflow: visible!important;
        }
        .packageItem .package {
            font-size: 10px;
        }
    
    }
    </style>