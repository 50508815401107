<template>
    <div class="garage">
        <!-- <h1>{{ $t('motorVehicle.popUpGarages', {garagesFound: garageLength}) }}</h1> -->
        <div class="garageLists">
            <div class="garageCard" v-for="(garage, index) in garages" :key="index">
                <div class="garageTitle">
                    <div class="icon">
                        <i :class="garage.garageType == 'Any' ? 'mv-garage-autho' : 'mv-garage-panel'"></i>
                    </div>
                    <div class="title">
                        <h2>{{garage.name}}</h2>
                        <div class="pref">{{ $t(garage.garageType == 'Any' ? 'motorVehicle.preferredDealer' : 'motorVehicle.preferredPanel') }}</div>
                    </div>
                </div>
                <div class="garageDetails">
                    <div class="address">
                        <div class="icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5 6.5C12.5 5.30653 12.0259 4.16193 11.182 3.31802C10.3381 2.47411 9.19347 2 8 2C6.80653 2 5.66193 2.47411 4.81802 3.31802C3.97411 4.16193 3.5 5.30653 3.5 6.5C3.5 8.346 4.977 10.752 8 13.634C11.023 10.752 12.5 8.346 12.5 6.5ZM8 15C4.333 11.667 2.5 8.833 2.5 6.5C2.5 5.04131 3.07946 3.64236 4.11091 2.61091C5.14236 1.57946 6.54131 1 8 1C9.45869 1 10.8576 1.57946 11.8891 2.61091C12.9205 3.64236 13.5 5.04131 13.5 6.5C13.5 8.833 11.667 11.667 8 15Z" fill="black"/>
                                <path d="M8 8C8.39782 8 8.77936 7.84196 9.06066 7.56066C9.34196 7.27936 9.5 6.89782 9.5 6.5C9.5 6.10218 9.34196 5.72064 9.06066 5.43934C8.77936 5.15804 8.39782 5 8 5C7.60218 5 7.22064 5.15804 6.93934 5.43934C6.65804 5.72064 6.5 6.10218 6.5 6.5C6.5 6.89782 6.65804 7.27936 6.93934 7.56066C7.22064 7.84196 7.60218 8 8 8ZM8 9C7.33696 9 6.70107 8.73661 6.23223 8.26777C5.76339 7.79893 5.5 7.16304 5.5 6.5C5.5 5.83696 5.76339 5.20107 6.23223 4.73223C6.70107 4.26339 7.33696 4 8 4C8.66304 4 9.29893 4.26339 9.76777 4.73223C10.2366 5.20107 10.5 5.83696 10.5 6.5C10.5 7.16304 10.2366 7.79893 9.76777 8.26777C9.29893 8.73661 8.66304 9 8 9Z" fill="black"/>
                            </svg>
                        </div>
                        <div class="descr">
                            {{garage.soiRoad}}
                        </div>
                    </div>
                    <!-- <div class="address">
                        <div class="icon">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.90653 1L4.09001 1.07901C4.54991 1.09565 4.95805 1.38608 5.13431 1.82212L5.77993 3.41928C5.93019 3.79095 5.88975 4.21502 5.67215 4.5498L4.84498 5.8225C5.33472 6.52576 6.66681 8.17347 8.07729 9.13797L9.12903 8.49069C9.39633 8.32617 9.71637 8.27733 10.0188 8.35485L12.1096 8.89101C12.6657 9.03363 13.0404 9.56691 12.9965 10.1531L12.8623 11.9431C12.8151 12.5712 12.301 13.0641 11.6908 12.9932C3.63547 12.0566 -1.08834 0.999982 1.90653 1V1Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div class="descr">
                            021-53662372
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="foot-btn">
            <button class="btn btn-block next2btn" @click="close">{{ $t('motorVehicle.close') }}</button>
        </div>
    </div>
</template>
<script>
import { computed } from 'vue';
export default {
    name: "GarageNearby",
    props: {
        garages: {
            type: [Array, Object]
        },
    },
    methods: {
        close() {
            this.$emit('close');
        }
    },
    computed: {
        garageLength() {
            return this.garages.length
        }
    }
}
</script>
<style scoped>
.garage {
    margin: 16px 16px;
}
.garage h1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000000;
}
.garageLists {
    position: relative;
    overflow: hidden;
    height: 356px;
    padding-right: 8px;
    overflow-y: scroll;
}
* {
    scrollbar-width: auto;
    scrollbar-color: #CED6E0 #F1F2F6;
}

*::-webkit-scrollbar {
    width: 4px;
}

*::-webkit-scrollbar-track {
    background: #F2F2F2;
    margin-left: 10px;
    border-radius: 2px;
}

*::-webkit-scrollbar-thumb {
    background-color: #00539A;
    border-radius: 2px;
    border: 3px solid #00539A;
}
.foot-btn{
    margin-top:16px;
}
.next2btn {
    background: linear-gradient(180deg, #EF4A24 0%, #F26848 100%);
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    width: 100%;
    border: 0;
    padding: 13px;
}
.next2btn:disabled {
    border: 1px solid #ccc!important;
    color: #ccc!important;
    background-color: #fff!important;
    box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
}

.garageCard {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 8px;
}
.garageTitle {
    display: flex;
}
.garageTitle .icon {
    margin-right: 4px;
}
.garageTitle .title h2{
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #000;
    margin-bottom: 0px;
}
.garageTitle .title .pref {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #898A8F;
}
.garageDetails {
    margin: 4px 0px;
}
.garageDetails .address {
    display: flex;
}
.garageDetails .address .icon {
    margin-right: 4px;
}
.garageDetails .address .descr {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #666666;
}
.mv-garage-panel::before{
    content: url('../../../../public/images/motor-vehicle/GarageIconPanel.svg');
    display: inline-block;
    position: relative;
    top: 0px;
    display: inline-block;
}
.mv-garage-autho::before{
    content: url('../../../../public/images/motor-vehicle/GarageIconAutho.svg');
    display: inline-block;
    position: relative;
    top: 0px;
    display: inline-block;
}
@media (max-height: 600px) {
    .garageLists {
        height: 370px;
    }
}
</style>