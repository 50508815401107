<template>
    <div class="mv-packages">
        <HeaderPrice 
            v-if="showPriceHeader" 
            :price="formatNumber(this.coveragePrice.Policy.firstInstallmentAmount)"
            :paymentType="this.defaultPaymentFrequency"
            :rewardNum="formatNumber(Global.motorVehicle.Reward)"
            :opportunityNumber="policyObject.root.quoteData.opportunityNumber" 
            :isGetReward="Global.motorVehicle.isDefaultReward"
            :animateSection="this.triggerHeader"
            :triggerAnimation="this.triggerAnimateHeader"
            @openInfoReward="popUpReward(Global.motorVehicle.DefaultReward)"
        />
        <div id="drt-body" class="section packagesStage" v-if="this.coveragePrice.Policy">
            <div class="container">
                <div class="scroll-bg">
                     <img class="animated2fast bounceDown" style="margin-top:106px" src="/images/motor-vehicle/chevron-down.svg" width="50" height="50" @click="scrollDown()" />
                </div>
                <div id="price2top">
                    <h1 class="quote-title">{{ $t('motorVehicle.quotationNo') }} {{
                        policyObject.root.quoteData.opportunityNumber }}</h1>
                </div>
                <div class="payment-details">
                    <div class="payment" :class="{intermediary: Global.isAgentCustomerView}">
                        <span class="premi" @click="voucherSuccess = true;"> <img class="img-premi" src="/images/motor-vehicle/Cash.svg" width="20" height="20" /> {{ $t('motorVehicle.premi') }} </span>
                        <div class="d-flex flex-grow-1 justify-content-end" v-if="!Global.isAgentCustomerView || (Global.isAgentCustomerView && Global.brokerInfo.useInstallment)">
                            <div class="switch-label-installment text-right" :class="{ active: this.defaultPaymentFrequency  === '6 Months' }">{{
                                $t('motorVehicle.switchMonthly') }}
                            </div>
                            <div class="switch">
                                <ToogleSwitch 
                                    :optionsList="availablePaymentFrequency" 
                                    @selected="switchPaymentType"
                                    @enableAnimate="enableAnimate"
                                    :isSwitchedRight="isDefaultSwitchToYearly" 
                                    :defaultPaymentFrequency="defaultPaymentFrequency"
                                />
                            </div>
                            <div class="switch-label text-left" :class="{ active: this.defaultPaymentFrequency === 'Yearly' }">
                                {{ $t('motorVehicle.switchYearly') }} 
                            </div>
                            <span class="disc noto" :class="{active: this.defaultPaymentFrequency == 'Yearly'}">{{ $t('motorVehicle.disc13') }} </span>
                        </div>
                    </div>
                    <div class="summaryPrice2" :class="{mb16: !Global.motorVehicle.isDefaultReward}">
                        <h1 v-if="this.defaultPaymentFrequency === 'Yearly'">
                            <span class="currency" >{{ $t('motorVehicle.currencySymbol') }}</span>
                            <span class="price-sum" :class="{'square-animation': triggerAnimateHeader}"> {{ formatNumber(this.coveragePrice.Policy.firstInstallmentAmount) }} </span>
                            <!-- <span class="per-sym"> </span> -->
                            <span class="paid-frequency noto" > / {{ $t('motorVehicle.year') }} </span> 
                        </h1>
                        <h1 v-else>
                            <span class="currency">{{ $t('motorVehicle.currencySymbol') }}</span>
                            <span class="price-sum" :class="{'square-animation': triggerAnimateHeader}"> {{ formatNumber(this.coveragePrice.Policy.firstInstallmentAmount) }} </span>
                            <!-- <span class="per-sym"> </span> -->
                            <span class="paid-frequency noto"> x 6 {{ $t('motorVehicle.month') }} </span> 
                        </h1>
                    </div>

                    <div class="plus-area" v-if="Global.motorVehicle.isDefaultReward">
                        <hr class="line-plus" >  <img src="images/motor-vehicle/Plus.svg" alt="" width="15" height="32">  <hr class="line-plus">
                    </div>
                    
                    <div class="rewardPackage" v-if="Global.motorVehicle.isDefaultReward">
                        <Reward 
                            @action="openReward"
                            @openInfoReward="popUpReward"
                            :pointReward=formatNumber(Global.motorVehicle.Reward)
                            :valueReward=formatNumber(Global.motorVehicle.Reward)
                        />
                    </div>
                </div>
                <div style="margin-bottom:20px;">
                    <div class="plan padbot-0">
                        <InfoCard :text="$t('motorVehicle.packagesBlueCard1')" :isArrow=true icon-class="bar-cover-car" />
                        <div class="new-sum">
                            <h4 class="text-sum">{{ $t('motorVehicle.sumInsuredTitle')}}</h4>
                            <h4 class="text-value"> <span class="currency-insured" >{{ $t('motorVehicle.currencySymbol') }}</span> {{ formatNumber(parseInt(this.selectedPlan.MOTORVEHICLE.VehicleSumAssured)) }}</h4>
                        </div>
                        
                        <div class="bg-grey">
                            <h4 class="text-coverage noto">{{ $t('motorVehicle.sumInsuredSubtitle') }}</h4>
                            <div class="cover-list noto">
                                <ul>
                                    <li v-for="(item, index) in compLists" :key="index">
                                        <i class="icon-check"></i><span>{{ item.label }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <PlanPackagesContainer
                    initialNumber="1"
                    :containerLabel="$t('motorVehicle.part1ContainerLabel')"
                >
                    <div class="greyContainer bottom-spacer">
                        <div class="titlePkg">
                            <img class="cash-icon" src="/images/motor-vehicle/garage.svg" />
                            <span class="title">{{ $t('motorVehicle.questionWorkshop') }}</span>
                        </div>
                        <div class="alert alert-sum" style="margin-bottom: 0px;" role="alert" v-if="isCarCanGetaReward && this.popGetReward">
                            <div class="d-flex">
                                <img class="img-msg-sum-upd" src="/images/motor-vehicle/Info-Sum.svg" />
                                <span class="msg-sum noto">{{ $t('motorVehicle.rewardMessage1') }} <span class="bolder">{{ $t('motorVehicle.rewardMessage2') }}</span>{{ $t('motorVehicle.rewardMessage3') }}<span class="bolder">{{ $t('motorVehicle.currencySymbol') }}{{ formatNumber(parseInt(Global.motorVehicle.DefaultReward)) }}</span> {{ $t('motorVehicle.rewardMessage4') }}</span>
                                <div>
                                    <div class="btn-close-modal" @click="this.popGetReward = false">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3 9L9 3M3 3L9 9" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="alert alert-dealer my-3" role="alert" v-if="isCarAboveEight">
                            <div class="d-flex">
                                <img class="img-msg-dealer" src="/images/motor-vehicle/WarnAcc.svg" />
                                <span class="msg-dealer noto"> {{$t("motorVehicle.messageDealer8year")}} </span>
                            </div>
                        </div>
                        <div class="garageLists">
                            <div class="row" >
                                <div class="col d-flex flex-nowrap" style="padding-right: 4px; padding-left: 16px;">
                                    <!-- :disable="!this.coverageOptionPrice['MOTORVEHICLE'].Workshop.hasOwnProperty('Panel')  "  -->
                                    <PackageItem 
                                        :comeFromWorkshop="true"
                                        :active="this.selectedPlan.MOTORVEHICLE.Workshop === 'Panel'"
                                        :text="$t('motorVehicle.AnyWS')"
                                        @action="changeWorkshop('Panel');" 
                                    />
                                </div>
                                <div class="col d-flex flex-nowrap" style="padding-left: 4px; padding-right: 16px;">
                                    <!-- !this.coverageOptionPrice['MOTORVEHICLE'].Workshop.hasOwnProperty('Dealer') ||  -->
                                    <PackageItem 
                                        :comeFromWorkshop="true"
                                        :active="this.selectedPlan.MOTORVEHICLE.Workshop === 'Dealer'"
                                        :disable="isCarAboveEight"
                                        :text="$t('motorVehicle.PanelWS')" 
                                        @action="changeWorkshop('Dealer');" 
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="optionForDealerWs noto">
                            <div>{{ $t('motorVehicle.optionForDealerWs1') }} <span class="bolder">{{ $t('motorVehicle.currencySymbol') }} 300.000 </span> {{ this.garageType == 'Dealer' ? $t('motorVehicle.optionForDealerWs2')  :  $t('motorVehicle.optionForPanelWs2') }}</div>
                        </div>
                        <div class="garagebuttoncontainer" v-if="policyObject.root.quoteData.abTestingVersion == 'B'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M13 16H12V12H11M12 8H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <div class="garagebuttontext">{{ this.selectedPlan.MOTORVEHICLE.Workshop === 'Panel' ? $t('motorVehicle.garage.findNearestPanelLabel') : $t('motorVehicle.garage.findNearestDealerLabel') }}</div>
                            <div class="garagebuttonshow" @click="showModalGarage = true; changeTitleModalGarage(this.selectedPlan.MOTORVEHICLE.Workshop); eventLog('user_click_popup_garage', 'see garage')">{{ $t('motorVehicle.garage.findNearestButton') }}</div>
                        </div>
                    </div>
                    <div style="display:none">
                        {{ this.checkSelected }}
                        {{ this.selectedCoveragesForMultiOpt }}
                    </div>
                    <div class="greyContainer">  
                        <!--// activated when multiple Sum Insured is ready -->
                        <div>
                            <div class="titlePkg">
                                <img class="cash-icon" src="/images/motor-vehicle/sum-insured.svg" />
                                <span class="title">{{ $t('motorVehicle.questionSumInsured') }}</span>
                            </div>
                            <div class="descPkg">
                                {{ $t('motorVehicle.msgSumInsured') }}
                            </div>
                        </div>
                        <SliderInput 
                            v-if="Global.isAgentCustomerView"
                            :max="getMaxInsured" 
                            :min="getMinInsured"
                            :value="selectedPlan.MOTORVEHICLE.VehicleSumAssured"
                            :label="$t('motorVehicle.inputNominal')"
                            :onChange="changeSumInsured"
                        />
                        <div style="gap: 8px;" v-else>
                            <h4 class="text-coverage">{{ $t('motorVehicle.selectPlans') }}</h4>
                            <div class="alert alert-sum-insured my-3" :class="{show:this.showWarnSum}" role="alert">
                                <div class="d-flex">
                                    <img class="img-msg-sum-insured" src="/images/motor-vehicle/exclamation.svg" />
                                    <span class="msg-sum-insured noto"> {{$t("motorVehicle.msgWarnSumInsured")}} </span>
                                </div>
                            </div>
                            <SumInsured 
                                :packages="this.coverageOptionPrice['MOTORVEHICLE']" 
                                @selected="updateSelectedPlanPrice($event, 'MOTORVEHICLE', 'VehicleSumAssured');this.showWarnSum = false; "
                                :addOnSelected="this.checkSelected"
                                :multiOptSelected="this.selectedCoveragesForMultiOpt"
                                :paymentType="this.defaultPaymentFrequency"
                                :defaultSelected="this.selectedPlanForMOTOR" 
                                :language="policyObject.root.quoteData.prefLang"
                                :coverageOption="'VehicleSumAssured'"
                            />
                        </div>
                    </div>
                </PlanPackagesContainer>
                <PlanPackagesContainer
                    initialNumber="2"
                    :containerLabel="$t('motorVehicle.part2ContainerLabel')"
                >
                    <div class="greyContainer bottom-spacer">
                        <div>
                            <div class="titlePkg">
                                <img class="cash-icon" src="/images/motor-vehicle/BarCoverCar2.svg" />
                                <span class="title">{{ $t('motorVehicle.packagesTitle1') }}</span>
                            </div>
                            <div class="descPkg">
                                {{ $t('motorVehicle.packagesDescription1') }}
                            </div>
                        </div>
                        <div class="package-plan">
                            <h4 class="text-coverage">{{ $t('motorVehicle.selectPlans') }}</h4>
                            <div class="plan-packages" style="padding-bottom: 16px;">
                                <PlanPackages 
                                    :packages="this.coverageOptionPrice['TPL']" 
                                    :workshop="this.garageType"
                                    @selected="updateSelectedPlanPrice($event, 'TPL', 'TPLSumAssured')"
                                    :paymentType="this.defaultPaymentFrequency"
                                    :defaultSelected="this.selectedPlanForTPL" 
                                    :language="policyObject.root.quoteData.prefLang"
                                    :coverageOption="'TPLSumAssured'"
                                    ref='tplRef'
                                />
                            </div>
                        </div>
                    </div>
                    <div class="greyContainer">
                        <div>
                            <div class="titlePkg">
                                <img class="cash-icon" src="/images/motor-vehicle/InjuredIcon.svg" />
                                <span class="title">{{ $t('motorVehicle.packagesTitle2') }}</span>
                            </div>
                            <div class="descPkg">
                                {{ $t('motorVehicle.packagesDescription2') }}
                            </div>
                        </div>
                        <div class="package-plan">
                            <h4 class="text-coverage">{{ $t('motorVehicle.selectPlanPA') }}</h4>
                            <div class="plan-packages" style="padding-bottom: 16px;">
                                <PlanPackages 
                                    :packages="this.coverageOptionPrice['PAME']" 
                                    :workshop="this.garageType"
                                    @selected="updateSelectedPlanPrice($event, 'PAME', 'PAMESumAssured')"
                                    :paymentType="this.defaultPaymentFrequency"
                                    :defaultSelected="this.selectedPlanForPAME" 
                                    :language="policyObject.root.quoteData.prefLang"
                                    :coverageOption="'PAMESumAssured'"
                                    ref='pameRef'
                                />
                            </div>
                        </div>
                    </div>
                </PlanPackagesContainer>

                <!-- <div class="d-flex voucher-gif" v-if="Global.motorVehicle.isCancelVoucher" :class="{'hide' : isHideVoucher,'scrolling' : !Global.motorVehicle.isScrollingVoucher}">
                    <div class="next-new" @click="isHideVoucher = !isHideVoucher">
                        <svg class="chevron active" :class="{'hide' : isHideVoucher}" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.5 4.16683L13.3333 10.0002L7.5 15.8335" stroke="#00539A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                    </div>
                    <img src="/images/motor-vehicle/voucher-id.gif" @click="voucherPopUp = true;" v-if="Global.quote.prefLang == 'id'" alt="" height="144">
                    <img src="/images/motor-vehicle/voucher-en.gif" @click="voucherPopUp = true;" v-if="Global.quote.prefLang == 'en'" alt="" height="144">
                </div> -->

                <PlanPackagesContainer
                    initialNumber="3"
                    :containerLabel="$t('motorVehicle.part3ContainerLabel')"
                >
                    <AddOnCoverage 
                        :title="$t('motorVehicle.flood')"
                        :desc="$t('motorVehicle.floodDesc')"
                        :button="$t('motorVehicle.btnBenefitDetails')" 
                        class-name="fl"
                        @action="updateSelectedPlanPrice($event, 'FL', 'MOTORVEHICLE.VehicleSumAssured');this.checkSelected.FL = $event" 
                        :isSelected="this.checkSelected.FL || this.selectedPlan['FL'].defaultPlan != null && this.selectedPlan['FL'].defaultPlan != undefined"
                    ></AddOnCoverage>
                    <AddOnCoverage 
                        :title="$t('motorVehicle.earthquake')"
                        :desc="$t('motorVehicle.earthquakeDesc')"
                        :button="$t('motorVehicle.btnBenefitDetails')" 
                        class-name="natural-disasters-coverage"
                        @action="updateSelectedPlanPrice($event, 'EQ', 'MOTORVEHICLE.VehicleSumAssured');this.checkSelected.EQ = $event" 
                        :isSelected="this.checkSelected.EQ || this.selectedPlan['EQ'].defaultPlan != null && this.selectedPlan['EQ'].defaultPlan != undefined"
                    ></AddOnCoverage>
                    <AddOnCoverage 
                        :title="$t('motorVehicle.trrTitle')"                         
                        :desc="$t('motorVehicle.trrDesc')"                         
                        :button="$t('motorVehicle.btnBenefitDetails')" 
                        class-name="terorism"
                        @action="updateSelectedPlanPrice($event, 'TRR', 'MOTORVEHICLE.VehicleSumAssured');this.checkSelected.TRR = $event" 
                        :isSelected="this.checkSelected.TRR || (this.selectedPlan['TRR'].defaultPlan != null && this.selectedPlan['TRR'].defaultPlan != undefined)"
                    ></AddOnCoverage>
                    <AddOnCoverage 
                        :title="$t('motorVehicle.srccTitle')"                         
                        :desc="$t('motorVehicle.srccDesc')"                         
                        :button="$t('motorVehicle.btnBenefitDetails')" 
                        class-name="riot-coverage"
                        @action="updateSelectedPlanPrice($event, 'SRCC', 'MOTORVEHICLE.VehicleSumAssured');this.checkSelected.SRCC = $event" 
                        :isSelected="this.checkSelected.SRCC || (this.selectedPlan['SRCC'].defaultPlan != null && this.selectedPlan['SRCC'].defaultPlan != undefined)"
                    ></AddOnCoverage>
                    <AddOnCoverage 
                        :title="$t('motorVehicle.rsaTitle')" 
                        :desc="$t('motorVehicle.rsaDesc')" 
                        :price="formatNumber(this.coverageOptionPrice['RSA']['RSAOption']['1'])"
                        :button="$t('motorVehicle.btnBenefitDetails')" 
                        class-name="roadside-assistance-coverage"
                        @action="updateSelectedPlanPrice($event, 'RSA', 'RSAOption');this.checkSelected.RSA = $event"
                        :isSelected="this.checkSelected.RSA || (this.selectedPlan['RSA'].defaultPlan != null && this.selectedPlan['RSA'].defaultPlan != undefined)"
                    ></AddOnCoverage>
                    <AddOnCoverage 
                        :title="$t('motorVehicle.taTitle')" 
                        :desc="$t('motorVehicle.taDesc')" 
                        :price="formatNumber(this.coverageOptionPrice['TA']['TAOption']['1'])"
                        :button="$t('motorVehicle.btnBenefitDetails')" 
                        class-name="tcc"
                        @action="updateSelectedPlanPrice($event, 'TA', 'TAOption');this.checkSelected.TA = $event" 
                        :isSelected="this.checkSelected.TA || (this.selectedPlan['TA'].defaultPlan != null && this.selectedPlan['TA'].defaultPlan != undefined)"
                    ></AddOnCoverage>
                </PlanPackagesContainer>

                <div class="summary-plan">
                    <div class="title-card-text">
                        {{ $t('motorVehicle.sumInsuredLabel') }}
                    </div>              
                    <div class="plan padtop-16 summary-new-container">
                        <div class="resume">
                            <div class="resume-text">{{ $t('motorVehicle.sumInsuredLabel2') }}</div>
                            <div class="resume-text">
                                <div class="image">
                                    <img :src="carBrand" alt="car" width="50"/>
                                </div>
                                <div class="car-brand">
                                    {{ policyObject.root.quoteData.vehicleData.make }} {{ policyObject.root.quoteData.vehicleData.modelFamily }} {{ policyObject.root.quoteData.vehicleData.yearOfManufacture }}
                                </div>
                            </div>
                            <hr style="margin: 16px 0px 24px 0px;">
                            <div class="text-coverage">
                                <h4 class="text-sum">{{ $t('motorVehicle.sumInsuredTitle')}}</h4>
                                <h4 class="text-value"  style="display: flex; align-items: center; justify-content: center; gap: 4px;"><span>{{ $t('motorVehicle.sumInsuredValue')}}</span> {{ formatNumber(parseInt(this.selectedPlan.MOTORVEHICLE.VehicleSumAssured)) }}</h4>
                                <!-- {{ $t('motorVehicle.sumInsured', { sumInsured: formatNumber(parseInt("800000000")) }) }} -->
                            </div>
                        </div>
                        
                        <div class="coverage">
                            <h4 class="text-coverage">{{ $t('motorVehicle.selectedBenefits') }}:</h4>
                            <div class="cover-list noto">
                                <ul>
                                    <li v-for="(item, index) in benefit" :key="index">
                                        <i class="icon-check"></i><span>{{ item.label }} <span class="bolder">{{ item.bold }}</span></span>
                                    </li>
                                    <!-- tpl -->
                                    <li v-if="this.selectedPlan != null && this.selectedPlan.TPL.defaultPlan != null && this.selectedPlan.TPL.defaultPlan != undefined">
                                        <i class="icon-check"></i><span>{{ benefitCoverage.tpl}} <span class="bolder">{{ $t('motorVehicle.currencySymbol') }}{{ benefitCoverage.valueTpl}}</span> </span>
                                    </li>
                                    <!-- pame -->
                                    <li v-if="this.selectedPlan != null && this.selectedPlan.PAME.defaultPlan != null && this.selectedPlan.PAME.defaultPlan != undefined">
                                        <i class="icon-check"></i><span>{{ benefitCoverage.pame}} <span class="bolder">{{ $t('motorVehicle.currencySymbol') }}{{ benefitCoverage.valuePame}}</span>{{ benefitCoverage.perPerson }}</span>
                                    </li>
                                    <!-- coveredToBe -->
                                    <li v-for="(item, index) in selectedCoverage" :key="index">
                                        <i class="icon-check"></i><span>{{ $t('motorVehicle.notCover.' + item.toLowerCase())
                                        }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="not-cover" v-if="noSelectedCoverage.length > 0 || ((this.selectedPlanForPAME == 'Not covered' ? 0 : Number(this.selectedPlanForPAME)) <= 0 || (this.selectedPlanForTPL == 'Not covered' ? 0 : Number(this.selectedPlanForTPL)) <= 0)">
                            <h4 class="text-coverage">{{ $t('motorVehicle.youNotChoose') }}:</h4>
                            <div class="cover-list noto">
                                <ul>
                                    <!-- tpl -->
                                    <li v-if="this.selectedPlan.TPL.defaultPlan == null || this.selectedPlan.TPL.defaultPlan == undefined">
                                        <span>
                                            <i class="icon-uncheck"></i>{{ benefitCoverage.tpl}}
                                            <a class="link-reward" @click="showModalTPL = true;">{{ $t('motorVehicle.addTxt') }}</a>
                                        </span>
                                    </li>
                                    <!-- pame -->
                                    <li v-if="this.selectedPlan.PAME.defaultPlan == null || this.selectedPlan.PAME.defaultPlan == undefined">
                                        <span>
                                            <i class="icon-uncheck"></i>{{  benefitCoverage.pame}}
                                            <a class="link-reward" @click="showModalPAME = true;">{{ $t('motorVehicle.addTxt') }}</a>
                                        </span>
                                    </li>
                                    <li class="d-flex" v-for="(item, index) in noSelectedCoverage" :key="index">
                                        <span>
                                            <i class="icon-uncheck"></i>{{ $t('motorVehicle.notCover.' + item.toLowerCase()) }}
                                            <a class="link-reward" @click="getCoverageDetail(item)">{{ $t('motorVehicle.addTxt') }}</a>
                                        </span> 
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="alert alert-sum my-3" role="alert" v-if="isCarCanGetaReward">
                            <div class="d-flex">
                                <img class="img-msg-sum-upd" src="/images/motor-vehicle/Info-Sum.svg" />
                                
                                <span class="msg-sum noto"> {{ $t('motorVehicle.UpdaterewardMessage1') }} <span class="bolder">{{ $t('motorVehicle.UpdaterewardMessage2') }}</span>{{ $t('motorVehicle.UpdaterewardMessage3') }}<span class="bolder">{{ $t('motorVehicle.currencySymbol') }}{{ formatNumber(parseInt(Global.motorVehicle.DefaultReward)) }}</span> {{ $t('motorVehicle.UpdaterewardMessage4') }} </span>
                                <a class="link-sum" @click="change2preffered = true">
                                    {{ $t('motorVehicle.btnRewardMessage') }}
                                </a>
                            </div>
                        </div>

                        <div class="next-step">
                            <div class="button-lst">
                                <div class="button-next">
                                    <button class="btn btn-lg next2btn" @click="next">
                                        {{ $t('motorVehicle.next') }}
                                    </button>
                                </div>
                                <div class="call-label" v-if="Global.isAgentCustomerView">{{ $t("motorVehicle.labelEmailMe") }}</div>
                                <div class="d-flex">
                                    <div 
                                        class="button-email"
                                        :class="{ full: Global.isAgentCustomerView}"
                                    >
                                        <button 
                                            class="btn email-me" 
                                            @click="Global.isAgentCustomerView? sendAgentEmail() : emailOffers = true;eventTracking('Oppo','quotationResultPackages', 'Opportunity_email_quote', 'CAR');" 
                                        >
                                            <img src="/images/motor-vehicle/Email.svg" alt="">
                                            <span class="email-text">{{$t("motorVehicle.btnEmailQuote") }}</span>
                                        </button>
                                    </div>
                                    <div class="button-call" v-if="!Global.isAgentCustomerView">
                                        <button class="btn call-me" @click="phoneCall = true">
                                            <img src="/images/motor-vehicle/Call.svg" alt="">
                                            <span>{{ $t('motorVehicle.callMe') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="section packagesStage whenLoading" v-else></div>

        <div v-if="showFloating">
            <FloatingContinueAgent
                v-if="Global.isAgentCustomerView"
                @sendEmail="sendAgentEmail" 
            />
            <FloatingContinue 
                v-else 
                @phoneCallAction="phoneCall = true" 
                @sendEmail="emailOffers = true; eventTracking('Oppo','quotationResultPackages', 'Opportunity_email_quote', 'CAR');" 
            />
        </div>

        <Modal 
            :show="showModalGarage"
            :classModal="'garage'" 
            @close="showModalGarage = false;" 
            :title="titleModalGarage"
            width="328px"
            ref="closeModalGarage"
        >
            <Garage
                :workshopType = "this.selectedPlan.MOTORVEHICLE.Workshop"
                @close="this.$refs.closeModalGarage.close()"
                @changeTitle="changeTitleModalGarage"
                :vehicleBrand="policyObject.root.quoteData.vehicleData.make"
            />
        </Modal>
        <Modal 
            :show="showModalTPL"
            :classModal="'pametpl'" 
            @close="invalidItemTPL = false; showModalTPL = false; selectedItemModalTPL = null;" 
            :title="$t('motorVehicle.packagesTitle1')"
            width="320px"
        >
            <div style="padding: 0 16px 16px 16px;">
                <div class="descPkg">
                    {{ $t('motorVehicle.packagesDescription1') }}
                </div>
                <div class="package-plan">
                    <h4 class="text-coverage mt-3">{{ $t('motorVehicle.selectPlans') }}</h4>
                    <div class="plan-packages mt-2" style="padding-bottom: 16px;">
                        <PlanPackages 
                            :packages="this.coverageOptionPrice['TPL']" 
                            @selected="preSelectedPlanPrice($event, 'TPL', 'TPLSumAssured')"
                            :paymentType="this.defaultPaymentFrequency"
                            :defaultSelected="this.selectedItemModalTPL" 
                            :language="policyObject.root.quoteData.prefLang"
                            :coverageOption="'TPLSumAssured'"
                            callFromModal="true"
                        />
                    </div>
                    <div class="invalidInput" v-if="invalidItemTPL">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#DA1E28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {{ $t('motorVehicle.invalidTPLInfo') }}
                    </div>
                    <div class="button-center">
                        <div class="cancelbutton" @click="invalidItemTPL = false; showModalTPL = false; selectedItemModalTPL = null;">{{ $t('motorVehicle.cancelButton') }}</div>
                        <span></span>
                        <div class="addbutton" @click="selectAndUpdatePlan('TPL')">{{ $t('motorVehicle.addButton') }}</div>
                    </div>
                </div>
            </div>
        </Modal>

        <Modal 
            :show="showModalPAME"
            :classModal="'pametpl'" 
            @close="invalidItemPAME = false; showModalPAME = false; selectedItemModalPAME = null;" 
            :title="$t('motorVehicle.packagesTitle2')"
            width="320px"
        >
            <div style="padding: 0 16px 16px 16px;">
                <div class="descPkg">
                    {{ $t('motorVehicle.packagesDescription2') }}
                </div>
                <div class="package-plan">
                    <h4 class="text-coverage mt-3">{{ $t('motorVehicle.selectPlans') }}</h4>
                    <div class="plan-packages mt-2" style="padding-bottom: 16px;">
                        <PlanPackages 
                            :packages="this.coverageOptionPrice['PAME']" 
                            @selected="preSelectedPlanPrice($event, 'PAME', 'PAMESumAssured')"
                            :paymentType="this.defaultPaymentFrequency"
                            :defaultSelected="this.selectedItemModalPAME" 
                            :language="policyObject.root.quoteData.prefLang"
                            :coverageOption="'PAMESumAssured'"
                            callFromModal="true"
                        />
                    </div>
                    <div class="invalidInput" v-if="invalidItemPAME">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#DA1E28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {{ $t('motorVehicle.invalidPAMEInfo') }}
                    </div>
                    <div class="button-center">
                        <div class="cancelbutton" @click="invalidItemPAME = false; showModalPAME = false; selectedItemModalPAME = null;">{{ $t('motorVehicle.cancelButton') }}</div>
                        <span></span>
                        <div class="addbutton" @click="selectAndUpdatePlan('PAME')">{{ $t('motorVehicle.addButton') }}</div>
                    </div>
                </div>
            </div>
        </Modal>

        <!-- <Modal 
            :show="showModalGarage"
            :classModal="'garage'" 
            @close="showModalGarage = false" 
            :title="$t('motorVehicle.popUpGarages',{garagesFound: garages === null ? 0 : garages.length})"
            width="320px"
        >
            <GarageNearby
                :garages="garages"  
                @close="showModalGarage = false" 
            />
        </Modal> -->

        <Modal 
            :show="voucherPopUp" 
            width="100%" 
            @close="voucherPopUp = false;Global.motorVehicle.isCancelVoucher = true;Global.motorVehicle.isScrollingVoucher = true;this.eventLog('free voucher', 'button close');"
            :voucher="voucherPopUp"
            :classModal="{'voucher':voucherPopUp,'slideIn':voucherPopUp}"
            class="modal-call-phone"
            :title="$t('motorVehicle.voucherTitle')"
        >
                <div class="modal-body body-thankyou">
                    <div class="mt-2 mb-4 d-flex">
                        <div class="bg-voucher">
                            <img class="img" src="/images/motor-vehicle/jojo-voucher.png" height="74" alt="">
                        </div>
                        <div class="msg-voucher">
                            <span class="msg">{{$t('motorVehicle.voucherMsg')}} <img class="img" src="/images/motor-vehicle/autoglaze.png" alt="" height="32"> </span> <br>
                            <span class="price">{{ $t('motorVehicle.currencySymbol') }}100.000 </span> <br>
                            <span class="hint">{{$t('motorVehicle.voucherHint')}} </span>
                        </div>
                        
                    </div>
                    <label for="inp" class="inp">
                        <input type="tel" :class="{success:isInvalidatePhoneNumber == false}" id="inp" maxlength="13" minlength="10" v-model.trim="policyObject.root.quoteData.policyHolderData.phoneNumber" placeholder="&nbsp;">
                        <span class="label">{{$t('motorVehicle.voucherWA')}}</span>
                        <span class="focus-bg"></span>
                    </label>
                    <p class="error-msg" v-if="isInvalidatePhoneNumber"> <img src="/images/motor-vehicle/warning.svg" alt="" style="margin-right:8px;"> {{$t('motorVehicle.voucherWAerror')}}</p>
                    <p class="example-msg">{{$t('motorVehicle.voucherPhoneExmple')}}</p>
                    <label for="inp" class="inp">
                        <input type="email" :class="{success:isInvalidateEmail == false}" id="inp" v-model.trim="policyObject.root.quoteData.policyHolderData.email" placeholder="&nbsp;">
                        <span class="label">{{$t('motorVehicle.voucherEmail')}}</span>
                        <span class="focus-bg"></span>
                    </label>
                    <p class="error-msg" v-if="isInvalidateEmail"> <img src="/images/motor-vehicle/warning.svg" alt="" style="margin-right:8px;"> {{$t('motorVehicle.voucherEmailError')}}</p>
                    <p class="example-msg">{{$t('motorVehicle.voucherEmailExmple')}}</p>
                    <label for="inp" class="inp carplate">
                        <input type="text" :class="{success:isInvalidateCarPlate == false}" id="inp" maxlength="13" minlength="10" v-model.trim="policyObject.root.quoteData.vehicleData.carPlateNumber" placeholder="&nbsp;">
                        <span class="label">{{$t('motorVehicle.voucherCarPlate')}}</span>
                        <span class="focus-bg"></span>
                    </label>
                    <p class="error-msg" v-if="isInvalidateCarPlate"> <img src="/images/motor-vehicle/warning.svg" alt="" style="margin-right:8px;">{{$t('motorVehicle.voucherCarPlateError')}}</p>
                    <p class="example-msg">{{$t('motorVehicle.voucherCarPlateExmple')}}</p>
                    <div
                        class="text-center voucust"
                    >
                    {{ $t("motorVehicle.voucherCust") }} 
                    </div>
                    <div class="thankyou-button">
                        <button @click="checkVoucher();">{{ $t("motorVehicle.redeemVoucher") }}</button>
                        <a class="link-voucher" @click="voucherPopUp = false;Global.motorVehicle.isCancelVoucher = true;Global.motorVehicle.isScrollingVoucher = true;this.eventLog('free voucher', 'button cancel');">{{ $t('motorVehicle.cancelVoucher') }}</a>
                    </div>
                </div>
        </Modal>

        <Modal 
            :show="showModalWarranty" 
            @close="showModalWarranty = false" 
            width="320px"
        >
            <Warranty 
                :className="styleModalAcc"
                :vehicleAccessoriesSelected="policyObject.root.quoteData.vehicleData.accessoriesData"
                :GlobalSumInsured="parseInt(this.selectedPlan.MOTORVEHICLE.VehicleSumAssured)" 
                @cancel="closeWarranty"
                @save="saveCarWarranty"
                @limit="reachLimitAcc"
                :showError="isAccessoriesWarning" 
                ref="warrantyAction" 
            />
        </Modal>

        <Modal 
            :show="showModalWarningAcc" 
            width="320px"
        >   
            <div class="modalWarnAcc">
                <div class="warn-acc">
                    <img class="my-3" src="/images/motor-vehicle/WarnAcc.svg" />
                    <div class="text-warn-acc">
                        {{ $t("motorVehicle.messageWarningAcc",{value: formatNumber((parseInt(this.selectedPlan.MOTORVEHICLE.VehicleSumAssured) < 200000000 ? parseInt(this.selectedPlan.MOTORVEHICLE.VehicleSumAssured) * 0.1 : 20000000))}) }}
                    </div>
                    <div class="warn-acc-button">
                        <button class="button-warn-acc" @click="showModalWarningAcc = false;showModalWarranty=true">{{ $t("motorVehicle.rewardModalClose") }}</button>
                    </div>
                </div>
            </div>
                
        </Modal>

        <Modal
            :show="showModalSumInsured"
            width="100%" 
            @close="showModalSumInsured = false" 
            :title="$t('motorVehicle.titleModalSumInsured')"
        >
            <div class="modalReward">
                <div class="roojai-rewards">
                    <div class="text-reward">
                        {{ $t("motorVehicle.msgModalSumInsured") }}
                    </div>
                    <div class="reward-button">
                        <button class="insured" @click="showModalSumInsured = false">{{ $t("motorVehicle.voucherErrButton") }}</button>
                    </div>
                </div>
            </div>
        </Modal>

        <Modal
            :show="isShowInstallmentModal" 
            width="100%" 
            @close="isShowInstallmentModal = false"
            class="modal-installment-option"
        >
            <div id="installment-detail-modal">
                <div class="modal-header" style="border:0;">
                    <button type="button" data-selenium-name="installment-modal-close-btn" @click="isShowInstallmentModal = false" class="close"><span aria-hidden="true">x</span></button>
                    <h2 class="text-center h4 mt-5">{{$t("instalmentOptions")}}</h2>
                    <p class="text-center" style="font-size: 16px;">{{$t("motorVehicle.instalmentCreditDebitMV")}}</p>
                </div>
                    <div class="panel installment-option mb-5" v-bind:class="{ active: paymentType?.name === 'Yearly' }">
                        <div class="panel-body">
                            <div class="mb-3">
                                <button class="btn-pk-radio" data-dismiss="modal" data-selenium-name="installment-option" data-selenium-value="Yearly" v-on:click="switchPaymentType({name : 'Yearly', value: true});isShowInstallmentModal = false; eventLog('Instalment', 'Yearly');">
                                    <span class="glyphicons glyphicons-ok-sign"></span>
                                </button>
                                {{ this.paymentOptionPrice["yearly"] }}
                                <p class="float-right pt-2">{{$t("option1")}}</p>
                            </div>

                            <div class="text-right">
                                <h3 class="text-head">{{$t("fullPayment")}}</h3>
                            </div>
                        </div>
                        <div class="panel-footer text-right" data-selenium-name="fullPayment-totalPrice" :data-selenium-value="formatNumber(this.paymentOptionPrice['Yearly'].totalPremium)">
                            <i18n-t tag="div" keypath="totalPrice">
                              <strong><small>IDR </small>{{formatNumber(this.paymentOptionPrice["Yearly"].totalPremium)}}</strong>
                            </i18n-t>
                        </div>
                    </div>

                    <div class="panel installment-option mb-5"  v-bind:class="{ active: paymentType?.name === 'Months' }">
                        <div class="panel-body">
                            <div class="mb-3">
                                <button class="btn-pk-radio" data-dismiss="modal" data-selenium-name="installment-option" data-selenium-value="6 months" v-on:click="switchPaymentType({ name :'Months',value: true});isShowInstallmentModal = false; eventLog('Instalment', '12 months, no deposit');">
                                    <span class="glyphicons glyphicons-ok-sign"></span>
                                </button>
                                <p class="float-right pt-2">{{$t("option2")}}</p>
                            </div>

                            <div class="text-right">
                                <h3 class="text-head">{{$t("6MonthlyInstalments")}}</h3>
                                <i18n-t tag="p" keypath="payForMonths">
                                  <strong><small>IDR </small>{{formatNumber(this.paymentOptionPrice["6 Months"].firstInstallmentAmount)}}</strong>
                                  <strong>6</strong>
                                </i18n-t>
                            </div>
                        </div>
                        <div class="panel-footer text-right" data-selenium-name="monthly-nodeposit-totalPrice" :data-selenium-value="formatNumber(this.paymentOptionPrice['6 Months'].firstInstallmentAmount)">
                            <i18n-t tag="div" keypath="totalPrice">
                              <strong><small>IDR </small> {{formatNumber(this.paymentOptionPrice["6 Months"].totalPremium)}}</strong>
                            </i18n-t>
                        </div>
                    </div>
                </div>
        </Modal>

        <Modal 
            :show="change2preffered" 
            width="100%" 
            @close="change2preffered = false" 
            class="modal-call-phone"
            :title="$t('motorVehicle.confirmation')"
        >
                <div class="call-me-back" style="padding-top:4px;">
                    <div
                        class="text-dark text-change2pref" style="margin-bottom:16px"
                    >
                    {{ $t("motorVehicle.change2preffered1") }}
                    <span class="bolder">{{ $t("motorVehicle.change2preffered2") }}</span>
                    {{ $t("motorVehicle.change2preffered3") }}
                    <span class="bolder">{{ $t("motorVehicle.change2preffered4") }}</span>
                    
                    </div>
                    <div class="call-me-back-footer">
                        <button type="button" class="btn-cancel btn-change2pref" @click="change2preffered = false">
                            {{ $t("motorVehicle.change2prefferedNo") }}
                        </button>
                        <button type="button" class="btn-confirm btn-change2pref" @click="changeWorkshop('Panel');change2preffered = false">
                            {{ $t("motorVehicle.change2prefferedYes") }}
                        </button>
                    </div>
                </div>
        </Modal>

        <Modal 
            :show="addOnPopUp" 
            width="100%" 
            @close="addOnPopUp = false;addOnPopUpPrice = ''; addOnPopUpList = [];addOnPopUpTitle=null;addOnPopUpOption=''" 
            class="modal-call-phone"
            :title="addOnPopUpTitle == 'RSA' ? $t('motorVehicle.rsaTitle') : addOnPopUpTitle == 'TA' ? $t('motorVehicle.taTitle') : addOnPopUpTitle == 'FL' ? $t('motorVehicle.flood') : addOnPopUpTitle == 'EQ' ? $t('motorVehicle.earthquake') : addOnPopUpTitle == 'TRR' ? $t('motorVehicle.trrTitle') : $t('motorVehicle.srccTitle')"
        >
                <div class="call-me-back">
                    <!-- <span class="addOn-msg" v-if="addOnPopUpPrice != '' && addOnPopUpPrice != '0'" >{{ $t('motorVehicle.addPremi') }} + {{ $t('motorVehicle.currencySymbol') }} <span class="addOn-price" >{{ numberFormat(addOnPopUpPrice) }}</span></span> -->
                    <div class="addOn-content">
                        <h2 class="addOn-sub" v-if="addOnPopUpTitle == 'TA' || addOnPopUpTitle == 'RSA'">{{ $t('motorVehicle.addSubtitle') }}</h2>
                        <h2 class="addOn-sub" v-else>{{ $t('motorVehicle.addSubtitleOthers') }}</h2>
                        <div class="addOn-descr" v-for="(item, index) in addOnPopUpList" :key="index">
                            <div class="icon">
                                <i class="icon-check"></i>
                                <!-- <i class="glyphicons glyphicons-remove" v-else></i> -->
                            </div>
                            <div class="unity">
                                <h2>{{ item.title }}</h2>
                            </div>
                        </div>
                    </div>
                    <div class="add-coverage" v-if="addOnPopUpTitle == 'TA' || addOnPopUpTitle == 'RSA'">
                        {{ $t('motorVehicle.addCoverage') }}
                    </div>
                    <div class="add-coverage" v-else>
                        {{ $t('motorVehicle.addCoverageOthers') }}
                    </div>
                    <div class="call-me-back-footer">
                        <button type="button" class="btn-cancel btn-change2pref" @click="addOnPopUp = false;addOnPopUpPrice = ''; addOnPopUpList = [];addOnPopUpTitle='';addOnPopUpOption=''">
                            {{ $t("motorVehicle.change2prefferedNo") }}
                        </button>
                        <button type="button" class="btn-confirm btn-change2pref" @click="showModalBenefitAdded = true; updateSelectedPlanPrice(addOnPopUp, addOnPopUpTitle, addOnPopUpOption);addOnPopUp = false;addOnPopUpTitle == 'RSA' ? this.checkSelected.RSA = true :addOnPopUpTitle == 'TA' ? this.checkSelected.TA = true :addOnPopUpTitle == 'FE' ? this.checkSelected.FE = true : this.checkSelected.SRCC = true ">
                            {{ $t("motorVehicle.change2prefferedYes") }}
                        </button>
                    </div>
                </div>
        </Modal>

        <Modal
            :show="ModalvoucherMsg"
            width="100%" 
            @close="Global.motorVehicle.isCancelVoucher = true;Global.motorVehicle.isScrollingVoucher = true;policyObject.root.quoteData.sendEmailFlag = false;ModalvoucherMsg = false;" 
            :classModal="{'slideRight':ModalvoucherMsg}"
            :title="$t('motorVehicle.voucherSuccTitle')"
        >
            <div class="modalReward">
                <div class="roojai-rewards">
                    <div class="text-reward" style="max-width:266px;">
                        {{ $t('motorVehicle.voucherSuccMsg') }}
                    </div>
                    <div class="alert alert-voucher" role="alert">
                        <div class="d-flex" style="align-items: center;">
                            <img class="img-msg-voucher-upd" src="/images/motor-vehicle/Info-Sum.svg" />
                            <span class="alert-msg-voucher noto"> {{ $t('motorVehicle.noteVoucher') }} </span>
                        </div>
                    </div>
                    <div class="reward-button">
                        <button @click="policyObject.root.quoteData.sendEmailFlag = false;ModalvoucherMsg = false;">{{ $t("motorVehicle.voucherSuccButton") }}</button>
                    </div>
                </div>
            </div>
        </Modal>

        <!-- phone -->
        <Modal 
            :show="phoneCall" 
            width="100%" 
            @close="invalidInputPhoneNumber = false; phoneCall = false;"
            :title="$t('motorVehicle.callmeback.phone.title')" 
            class="modal-call-phone"
        >
            <div class="call-me-back">
                <div class="call-me-back-body">
                    <div class="text-muted mt-2">
                        <span v-if="!Global.isAgentCustomerView">{{ $t("motorVehicle.callmeback.phone.desc") }}</span>
                        <span v-if="Global.isAgentCustomerView">{{ $t("pleaseEnterYourMobileNumberAgent") }}</span>
                    </div>
                    <label for="inp" class="inp">
                        <input @focus="invalidInputPhoneNumber = false;" type="tel" id="inp" maxlength="13" minlength="10" v-model.trim="policyObject.root.quoteData.policyHolderData.phoneNumber" placeholder="&nbsp;">
                        <span class="label">{{$t('yourMobilePhone')}}</span>
                        <span class="focus-bg"></span>
                    </label>
                </div>
                <div class="invalidInputCallMe" v-if="invalidInputPhoneNumber">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                      <path d="M8.00751 6V7.33333M8.00751 10H8.01418M3.38441 12.6667H12.6306C13.6579 12.6667 14.3 11.5556 13.7864 10.6667L9.16328 2.66667C8.6496 1.77778 7.36541 1.77778 6.85173 2.66667L2.22864 10.6667C1.71496 11.5556 2.35706 12.6667 3.38441 12.6667Z" stroke="#FA4D56" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{ $t("motorVehicle.callmeback.phone.invalid") }}
                    {{ $t("motorVehicle.callmeback.phone.tryagain") }}
                  </div>
                <div class="call-me-back-footer mt-3">
                    <button type="button" class="btn-cancel" @click="invalidInputPhoneNumber = false; phoneCall = false;">
                        {{ $t("motorVehicle.callmeback.phone.cancelbtn") }}
                    </button>

                    <button type="button" class="btn-confirm" @click="callMeBackPhone">
                        {{ $t("motorVehicle.callmeback.phone.sendbtn") }}
                    </button>
                </div>
            </div>
        </Modal>

        <Modal 
            :show="phoneCallthankyou" 
            width="100%" 
            @close="phoneCallthankyou = false" 
            class="modal-call-phone"
            :title="(Global.isAgentCustomerView ? $t('thankyoucallExc1Agent') : $t('motorVehicle.callmeback.phone.successTitle'))"
        >
                <div class="modal-body body-thankyou">
                    <div
                        class="text-dark"
                        v-if="!Global.isAgentCustomerView"
                    >
                    {{ $t("motorVehicle.callmeback.phone.opsHour1") }} <br>
                    {{ $t("motorVehicle.callmeback.phone.opsHour2") }} <br>
                    {{ $t("motorVehicle.callmeback.phone.opsHour3") }} <br>
                    {{ $t("motorVehicle.callmeback.phone.opsHour4") }}
                    </div>
                    <div
                        class="text-dark"
                        v-if="Global.isAgentCustomerView"
                    >
                        {{ $t("thankyoucallAgent") }} 
                    </div>
                    <div class="thankyou-button">
                        <button @click="phoneCallthankyou = false">{{ $t("motorVehicle.callmeback.phone.closebtn") }}</button>
                    </div>
                </div>
        </Modal>

        <!-- email -->
        <Modal 
            :show="emailOffers" 
            width="100%" 
            @close="emailOffers = false;" 
            class="modal-call-phone"
            :title="emailOffersWithPhone ? $t('motorVehicle.callmeback.phone.title') : $t('motorVehicle.callmeback.email.title')"
        >
            <div v-if="!emailOffersWithPhone" class="call-me-back">
                <div class="call-me-back-body">
                    <div class="text-muted mt-2">
                        <span v-if="!Global.isAgentCustomerView">{{ $t('motorVehicle.callmeback.email.desc') }}</span>
                        <span v-if="Global.isAgentCustomerView">{{ $t('motorVehicle.emailCallmeWordAgent') }}</span>
                    </div>
                    <label for="inp" class="inp">
                        <input @focus="invalidInputEmail = false" type="email" id="inp" v-model.trim="policyObject.root.quoteData.policyHolderData.email" placeholder="&nbsp;">
                        <span class="label">{{$t('motorVehicle.callmeback.email.placeholder')}}</span>
                        <span class="focus-bg"></span>
                    </label>
                </div>
                <div class="invalidInputCallMe" v-if="invalidInputEmail">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M8.00751 6V7.33333M8.00751 10H8.01418M3.38441 12.6667H12.6306C13.6579 12.6667 14.3 11.5556 13.7864 10.6667L9.16328 2.66667C8.6496 1.77778 7.36541 1.77778 6.85173 2.66667L2.22864 10.6667C1.71496 11.5556 2.35706 12.6667 3.38441 12.6667Z" stroke="#FA4D56" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{ $t("motorVehicle.callmeback.email.invalid") }}
                </div>
                <div class="inputExample" v-if="!invalidInputEmail">
                    {{ $t("motorVehicle.callmeback.email.sample") }} vira@gmail.com
                </div>
                <div @click="policyObject.root.quoteData.policyHolderData.PersonHasOptedOutOfEmail = !policyObject.root.quoteData.policyHolderData.PersonHasOptedOutOfEmail" v-if="!Global.isAgentCustomerView" class="d-flex justify-content-center align-items-center newslettercontainer">
                    <div class="newsletterCheck" :class="{activeCheck: !policyObject.root.quoteData.policyHolderData.PersonHasOptedOutOfEmail}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20 20" fill="none">
                            <path d="M4.16667 10.8334L7.5 14.1667L15.8333 5.83337" stroke="#E8E8E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="newsletterText">{{ $t("emailOptedOut") }}</div>
                </div>
                <!-- <div v-if="!Global.isAgentCustomerView" style="color: #FF5011!important; font-size:13px;">
                  {{ $t("getMoreDetails") }}
                </div> -->
                <div v-if="Global.isAgentCustomerView" :class="{'text-center':Global.isAgentCustomerView}" style="font-size:14px;margin: 8px 0 16px 0;font-weight:bold;">
                  {{ $t("getEmail") }}
                </div>
                <div class="call-me-back-footer mt-3" >
                    <button type="button" class="btn-cancel" @click="invalidInputEmail=false;emailOffers = false;">
                        {{ $t("motorVehicle.callmeback.email.cancelbtn") }}
                    </button>

                    <button type="button" class="btn-confirm" @click="sendMeEmailOffer">
                        {{ $t("motorVehicle.callmeback.email.sendbtn") }}
                    </button>
                </div>
            </div>
            <div v-else class="call-me-back">
                <div class="call-me-back-body">
                    <div class="text-muted mt-2">
                        <span>{{ (Global.isAgentCustomerView ? $t("eQuoteExplainViaMobileAgent") :$t("eQuoteExplainViaMobile")) }}</span>
                    </div>
                    <label for="inp" class="inp">
                        <input @focus="invalidInputPhoneNumber = false;" type="tel" id="inp" maxlength="13" minlength="10" v-model.trim="policyObject.root.quoteData.policyHolderData.phoneNumber" placeholder="&nbsp;">
                        <span class="label">{{$t('yourMobilePhone')}}</span>
                        <span class="focus-bg"></span>
                    </label>
                </div>
                <div class="invalidInputCallMe" v-if="invalidInputPhoneNumber">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                      <path d="M8.00751 6V7.33333M8.00751 10H8.01418M3.38441 12.6667H12.6306C13.6579 12.6667 14.3 11.5556 13.7864 10.6667L9.16328 2.66667C8.6496 1.77778 7.36541 1.77778 6.85173 2.66667L2.22864 10.6667C1.71496 11.5556 2.35706 12.6667 3.38441 12.6667Z" stroke="#FA4D56" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{ $t("motorVehicle.callmeback.phone.invalid") }}
                    {{ $t("motorVehicle.callmeback.phone.tryagain") }}
                </div>
                <div class="mt-4 text-center">
                    <a
                    href="javascript:void(0)"
                    style="color: #FF5011!important;"
                    data-selenium-name="btn-createPolicy-skip"
                    @click="emailOffers = false; emailOffersWithPhone = false; showModalsuccessEmail = true;"
                    >{{ $t("eQuoteSkipNext") }}</a>
                </div>
                <div class="call-me-back-footer mt-3">
                    <button type="button" class="btn-cancel" @click="invalidInputPhoneNumber = false; emailOffersWithPhone = false;">
                        {{ $t("motorVehicle.callmeback.phone.cancelbtn") }}
                    </button>

                    <button type="button" class="btn-confirm" @click="callMeBackPhone('email');">
                        {{ $t("motorVehicle.callmeback.phone.sendbtn") }}
                    </button>
                </div>
            </div>
        </Modal>

        <!-- modal reward -->
        <Modal
            :show="showModalReward"
            width="100%" 
            @close="showModalReward = false" 
            :title="'Roojai Rewards'"
        >
            <div class="modalReward">
                <div class="roojai-rewards">
                    <div class="text-reward">
                        {{ $t("motorVehicle.rewardReedem") }}
                    </div>
                    <div class="reward-option">
                            <img src="../../../public/images/motor-vehicle/tokopedia-logo.svg" />
                            <img src="../../../public/images/motor-vehicle/Logo-Pertamina.svg" />
                    </div>
                    <div class="text-img-reward">
                        <span style="margin-top:8px;">Voucher Tokopedia</span>
                        <span style="margin-left: 10px;margin-top:8px;">Voucher Pertamina</span>
                    </div>
                    <div class="reward-button">
                        <button @click="showModalReward = false">{{ $t("motorVehicle.rewardModalClose") }}</button>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal 
            :show="showModalRecomendReward" 
            width="100%" 
            class="modal-call-phone"
        >
                <div class="call-me-back">
                    <div
                        class="text-dark"
                        style="margin:16px 0;"
                    >
                    {{ $t("motorVehicle.popUpRewardRecomend1") }}
                    <span class="bolder">{{ formatNumber(Global.motorVehicle.DefaultReward) }}</span>
                    {{ $t("motorVehicle.popUpRewardRecomend2") }}
                    </div>
                    <div class="call-me-back-footer">
                        <button type="button" class="btn-cancel btn-change2pref" @click="showModalRecomendReward = false;">
                            {{ $t("motorVehicle.change2prefferedNo") }}
                        </button>
                        <button type="button" class="btn-confirm btn-change2pref" @click="showModalRecomendReward = false;change2preffered = true">
                            {{ $t("motorVehicle.change2prefferedYes") }}
                        </button>
                    </div>
                </div>
        </Modal>
        <Modal
            :show="showModalInfoReward"
            width="100%" 
            @close="showModalInfoReward = false" 
        >
            <div class="modalReward">
                <div class="roojai-rewards">
                    <div class="text-reward" style="max-width:266px;">
                        {{ $t('motorVehicle.tooltipReward') }}
                    </div>
                    <div class="reward-button">
                        <button @click="showModalInfoReward = false">{{ $t("motorVehicle.rewardModalClose") }}</button>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal
            :show="showModalsuccessEmail"
            width="100%" 
            @close="showModalsuccessEmail = false"
            :title="$t('motorVehicle.callmeback.email.successTitle')" 
        >
                <div class="modal-body body-thankyou">
                    <div
                        class="text-dark"
                        v-if="!Global.isAgentCustomerView"
                    >
                    {{ $t("motorVehicle.callmeback.email.successDesc") }}
                    </div>
                    <div
                        class="text-dark"
                        v-if="Global.isAgentCustomerView"
                    >
                    {{ $t("motorVehicle.emailThankyouAgent") }}
                    </div>
                    <div class="thankyou-button">
                        <button @click="showModalsuccessEmail = false">{{ $t("motorVehicle.callmeback.email.closebtn") }}</button>
                    </div>
                </div>
        </Modal>
        <Modal
            :show="showModalsuccessEmailAgent"
            width="100%" 
            @close="showModalsuccessEmailAgent = false"
            :title="$t('motorVehicle.callmeback.email.successTitle')" 
        >
                <div class="modal-body body-thankyou">
                    <div
                        class="text-dark"
                    >
                    {{ $t("motorVehicle.callmeback.email.successDesc") }}
                    </div>
                    <div class="thankyou-button">
                        <button @click="showModalsuccessEmailAgent = false">{{ $t("motorVehicle.callmeback.email.closebtn") }}</button>
                    </div>
                </div>
        </Modal>
        <Toast
            :show="showModalBenefitAdded"
            width="100%" 
            @close="showModalBenefitAdded = false"
            :tooltipsText="$t('motorVehicle.benefitHasBeenAdded')"
        >
        </Toast>
    </div>
</template>
 
<script>
import Global from "@/store/global.js";
import Mixin from "@/components/Mixin";
import AddOnCoverage from './components/AddOnCoverage.vue';
import FloatingContinue from "./components/FloatingContinue.vue";
import FloatingContinueAgent from "./components/FloatingContinueAgent.vue";
import GarageNearby from "./components/GarageNearby.vue";
import Garage from "./components/Garage.vue";
import HeaderPrice from "./components/HeaderPrice.vue";
import InfoCard from './components/InfoCard.vue';
import Modal from "./components/Modal.vue";
import Toast from "./components/Toast.vue";
import PackageItem from './components/PackageItem.vue';
import PlanPackages from './components/PlanPackages.vue';
import PlanPackagesContainer from './components/PlanPackagesContainer.vue';
import NewAddOnCoverage from './components/NewAddOnCoverage.vue';
import SumInsured from './components/SumInsurePackages.vue';
import ToogleSwitch from './components/ToogleSwitch.vue';
import Warranty from "./components/Warranty.vue";
import Reward from "./components/Reward.vue";
import {createInputPricingObj, policyServiceMappingObj} from "./data/util.js"
import { policyObject } from "./data/PolicyObject";
import SliderInput from "@/components/SliderInput/SliderInput.vue";
import {v4 as uuidv4} from 'uuid';

import { formattedDate,
    getRegisterIGNSdk, 
    setInterceptorObj, 
    validateEmail,
    filterNumberOnly, 
    ymdFormatedDate,
    dayMonthYearFormatedDate
} from "./lib/index.js";

import {
    setClientRegInfo,
    // setPolicyServiceMappingFromInputPriceResponse,
    calculatePlanOptionsPrice,
    getCoverageOptionPrice,
    getCoveragePrice,
    setInputPricing,
    getDependencyMapping,
    updateSelectedPlan,
    getSelectedPlan,
    getAvailablePaymentFrequency,
    getDefaultPaymentFrequency,
    getPolicyPaymentOptionPrice,
    updatePaymentFrequency,
    getRewardPoint,
    saveQuote,
    getInputPricing,
    getPolicyServiceInputObj,
    setPolicyServiceInputObj
} from "./lib/IGNLibrary/IGNPricingEngine.js";
import inputPricing from "./data/inputPricingObj.js";
import axios from "axios";


let clientRegInfo;

export default {
    name: "MvPackage",
    beforeRouteLeave (to, from, next) {
        if(to.name == 'mv-policydetails') next()
        if(window.pageYOffset > 0) {
            this.scrollToTopPage()
            next(false)
        } else next()
    },
    mixins: [Mixin],
    data() {
        return {
            policyObject,
            Global,
            reward:0,
            popGetReward : false,
            triggerHeader: null,
            showModalInfoReward : false,
            showModalRecomendReward: false,
            triggerAnimateHeader:false,
            checkSelected:{
                TA: false,
                RSA: false,
                FL: false,
                SRCC: false,
                TRR: false,
                EQ: false,
            },
            showPriceHeader: false,
            showScrollDown: false,
            showFloating: false,
            showModalWarranty: false,
            showModalWarningAcc: false,
            showModalGarage: false,
            showModalReward: false,
            showModalsuccessEmail: false,
            voucherPopUp: false,
            voucherSuccess: false,
            voucherError:false,
            modalOTP: false,
            phoneCall: false,
            phoneCallthankyou: false,
            change2preffered: false,
            emailOffers: false,
            addOnPopUp: false,
            addOnPopUpOption: '',
            addOnPopUpTitle: '',
            addOnPopUpPrice: '',
            addOnPopUpList: [],
            coveragePrice: {},
            coverageOptionPrice: {},
            selectedPlan: {},
            dependencyMappingObject: {},
            inputPricingObject: {},
            availablePaymentFrequency: [],
            defaultPaymentFrequency: null,
            garageType : null,
            coverType: null,
            type: [],
            selectedCoverage: [],
            noSelectedCoverage: [],    
            selectedGarage: null,
            selectedCoveragesForMultiOpt: null,
            selectedPlanForMOTOR: null,
            selectedPlanForTPL: null,
            selectedPlanForPAME: null,
            garages: null,
            isShowInstallmentModal: false,
            counting: false,
            refID: "",
            OTP: "",
            OTPcountdown: 30,
            ModalvoucherMsg: false,
            showModalSumInsured : false,
            showWarnSum: false,
            calculatedData: null,
            rsaAddOn: null,
            taAddOn: null,
            srccAddOn: null,
            feAddOn: null,
            tplAddOn: null,
            pameAddOn: null,
            isHideVoucher: false,
            isScrollingVoucher: null,
            addOnNotSelected: [],
            workshopChosen: "",
            sumInsured: "",
            sumCarWarranty: 0,
            DefaultReward: 0,
            // isCancelVoucher: null,
            isDefaultReward: false,
            isCalculatePlanOptionsPrice: true,
            isInvalidateEmail: null,
            isInvalidatePhoneNumber: null,
            isInvalidateCarPlate: null,
            isAccessoriesWarning: false,
            styleModalAcc: "",
            showModalTPL: false,
            showModalPAME: false,
            selectedItemModalTPL: null,
            invalidItemTPL: false,
            selectedItemModalPAME: null,
            invalidItemPAME: false,
            invalidInputEmail: false,
            showModalsuccessEmail: false,
            showModalsuccessEmailAgent: false,
            invalidInputPhoneNumber: false,
            phoneCallthankyou: false,
            showModalBenefitAdded: false,
            emailOffersWithPhone: false,
            titleModalGarage: '',
        }
    },
    watch: {
        "policyObject.root.quoteData.policyHolderData.email": function(emailValue) {
            if(validateEmail(emailValue)) {
                this.isInvalidateEmail = false;
            } else {
                this.isInvalidateEmail = true;
            }
        },
        "policyObject.root.quoteData.policyHolderData.phoneNumber": function(phoneValue) {
            const regxIndoPhone = /^(^\+62|62|^08)(\d{3,4}-?){2}\d{2,3}$/g;
            let phone = policyObject.root.quoteData.policyHolderData.phoneNumber 
            if(phone !== null){
                policyObject.root.quoteData.policyHolderData.phoneNumber = filterNumberOnly(phoneValue);
                if(filterNumberOnly(phoneValue) && phoneValue.match(regxIndoPhone)) {
                    this.isInvalidatePhoneNumber = false;
                } else {
                    this.isInvalidatePhoneNumber = true;
                }
            } else this.isInvalidatePhoneNumber = true;
        },
        "policyObject.root.quoteData.vehicleData.carPlateNumber": function(carPlateValue) {
            const regxIndoCarPlate = /^[A-Z]{1,2}\d{1,4}[A-Z]{1,3}$/gm;
            policyObject.root.quoteData.vehicleData.carPlateNumber = carPlateValue.toUpperCase();
            if(carPlateValue.toUpperCase().match(regxIndoCarPlate)) {
                this.isInvalidateCarPlate = false;
            } else {
                this.isInvalidateCarPlate = true;
            }
        },
    },
    components: {
        ToogleSwitch,
        InfoCard,
        PackageItem,
        PlanPackages,
        SumInsured,
        AddOnCoverage,
        Modal,
        Warranty,
        HeaderPrice,
        FloatingContinue,
        FloatingContinueAgent,
        GarageNearby,
        Reward,
        PlanPackagesContainer,
        NewAddOnCoverage,
        Toast,
        Garage,
        SliderInput,
    },
    async created() {
        window.addEventListener('scroll', this.onScrollNavbar);

        clientRegInfo = getRegisterIGNSdk();
        await setClientRegInfo(clientRegInfo);
        this.policyObject.root.quoteData.onlineScreen = "/mv/packages";
        this.policyObject.root.quoteData.onlineStage = "Packages";
        await this.getData();
        var mv = document.getElementById("app")
        mv.scrollIntoView({ behavior: "smooth", block: "start"})
        if( isNaN(this.coveragePrice.Policy.firstInstallmentAmount)){
            // this.errorModal(this.$t("packageUnavailable"),"mv error");
            this.errorModal(this.$t("packageUnavailable"), 'title', this.$t("packageUnavailableTitle"), this.$t("packageUnavailableContact"), this.$t("packageUnavailable2"),'reload');
        }
        
        this.firstrewardPoint();        
    },
    mounted(){
        window.scrollTo(0, 0);
    },
    updated(){
        var scroll;
        window.addEventListener('scroll', function (event) {
        window.clearTimeout(scroll);
        Global.motorVehicle.isScrollingVoucher = false;
        scroll = setTimeout(function () {
            Global.motorVehicle.isScrollingVoucher = true;
        }, 250);
        }, false);
    },

    methods: {
        toTitleCase(str){
            return str.replace(/\w\S*/g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        },
        changeTitleModalGarage(value){
            this.titleModalGarage = value == 'Panel' ? this.$i18n.t('motorVehicle.garage.titlePanel') : this.$i18n.t('motorVehicle.garage.titleDealer', {car: this.toTitleCase(this.policyObject.root.quoteData.vehicleData.make)})
        },
        handleFloatingFooter(elm, threshold, show, mode) {
            threshold = threshold || 0;
            var rect = elm.getBoundingClientRect();
            var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            var below = rect.top - viewHeight - threshold >= 0;
            this.showFloating = show && below ? true : false;
        },
        preSelectedPlanPrice(value,type){
            if(type == 'TPL'){
                this.invalidItemTPL = false
                this.selectedItemModalTPL = value
            }
            if(type == 'PAME'){
                this.invalidItemPAME = false
                this.selectedItemModalPAME = value
            }
        },
        selectAndUpdatePlan(type){
            if(type == 'TPL'){
                if(this.selectedItemModalTPL && this.selectedItemModalTPL != null){
                    this.updateSelectedPlanPrice(this.selectedItemModalTPL, 'TPL', 'TPLSumAssured')
                    setTimeout(() => {
                        this.invalidItemTPL = false; this.showModalTPL = false; this.selectedItemModalTPL = null; this.showModalBenefitAdded = true;
                    }, 100);
                } else this.invalidItemTPL = true
            }
            if(type == 'PAME'){
                if(this.selectedItemModalPAME && this.selectedItemModalPAME != null){
                    this.updateSelectedPlanPrice(this.selectedItemModalPAME, 'PAME', 'PAMESumAssured')
                    setTimeout(() => {
                        this.invalidItemPAME = false; this.showModalPAME = false; this.selectedItemModalPAME = null; this.showModalBenefitAdded = true;
                    }, 100);
                } else this.invalidItemPAME = true
            }
        },
        async getData() {
            this.loading("show");
            try {
                let inputPricingObj = createInputPricingObj();
                setInputPricing(inputPricingObj);
                await calculatePlanOptionsPrice(true);
                await this.bindData();
                // this.getGarage();               
                this.garageType = this.selectedPlan.MOTORVEHICLE.Workshop
                this.getSelectedCoverages();

                this.testbenefitCoverage();
                this.testbenefit();              
                this.selectedPlanMultiOptions('PAME');
                this.selectedPlanMultiOptions('TPL');
                this.selectedPlanMultiOptions('MOTORVEHICLE');
                if (Global.isAgentCustomerView && this.policyObject.root.quoteData.policyHolderData.email === "" ) {
                    let myuuid = uuidv4();
                    let randomNumber = myuuid.split('-',1)
                    this.policyObject.root.quoteData.policyHolderData.email = 'dummyemail' + randomNumber + '@roojai.co.id'
                }
                if(Global.motorVehicle.flagRetrievePolicy == false){
                    this.saveQuoteFetch();
                    this.firstSumInsured();
                }
                else if(Global.motorVehicle.flagRetrievePolicy == true){
                    setPolicyServiceInputObj(this.policyObject);
                }
                this.loading("hide");
            } catch (error) {
                this.loading("hide");
                // this.errorModal(this.$t("packageUnavailable"),"mv error");
                this.errorModal(this.$t("packageUnavailable"), 'title', this.$t("packageUnavailableTitle"), this.$t("packageUnavailableContact"), this.$t("packageUnavailable2")); //packageUnavailable
                // this.errorModal(this.$t("packageUnavailable"));
                console.log(error)
            }
            
        },
        firstSumInsured(){
            if(Global.motorVehicle.firstSumInsured == null && this.coveragePrice.Policy.firstInstallmentAmount > 0){
                Global.motorVehicle.firstSumInsured = this.selectedPlan.MOTORVEHICLE.VehicleSumAssured
            }
        },
        firstVoucherTrigger(){
            if (Global.motorVehicle.voucherTrigger == null && this.coveragePrice.Policy.firstInstallmentAmount > 0) {
                setTimeout(() => {
                    this.voucherPopUp = true;
                    this.eventLog('free voucher', 'Get pop up');
                    Global.motorVehicle.voucherTrigger = true;
                }, 9500);
            }
        },
        voucherValidateEmail(){
            if(!validateEmail(policyObject.root.quoteData.policyHolderData.email)){
                this.isInvalidateEmail = true;
            }else{
                this.isInvalidateEmail = false;
            }
        },
        voucherValidatePhone(){
            const regxIndoPhone = /^(^\+62|62|^08)(\d{3,4}-?){2}\d{2,3}$/g;
            if(!policyObject.root.quoteData.policyHolderData.phoneNumber.match(regxIndoPhone) ){
                this.isInvalidatePhoneNumber = true;
            }else{
                this.isInvalidatePhoneNumber = false;
            }
        },
        voucherValidateCarPlate(){
            const regxIndoCarPlate = /^[A-Z]{1,2} \d{1,4} [A-Z]{1,3}$/gm;
            if(!policyObject.root.quoteData.vehicleData.carPlateNumber.toUpperCase().match(regxIndoCarPlate)){
                this.isInvalidateCarPlate = true;
            }
            else{
                this.isInvalidateCarPlate = false;
            }
        },
        checkValidateVoucher(){
            if(this.isInvalidatePhoneNumber == null || this.isInvalidatePhoneNumber) {
                // this.voucherValidatePhone()
                this.isInvalidatePhoneNumber = true;
            }
            if(this.isInvalidateEmail == null || this.isInvalidateEmail){
                // this.voucherValidateEmail()
                this.isInvalidateEmail = true;
            }
            if( this.isInvalidateCarPlate == null || this.isInvalidateCarPlate){
                // this.voucherValidateCarPlate()
                this.isInvalidateCarPlate = true;
            }
            
            if(!this.isInvalidateEmail && !this.isInvalidateCarPlate && !this.isInvalidatePhoneNumber){
                return true
            }
            else{
                return false
            }
        },
        async changeSumInsured(value){
            await this.resetAdditionalCoverage()
            try {
                this.changeWorkshop('Panel', 'agentSI')
                const listOptionPrice = Object.keys(this.coverageOptionPrice['MOTORVEHICLE'].VehicleSumAssured)
                if (listOptionPrice.includes(String(value))) {
                    this.updateSelectedPlanPrice(value, 'MOTORVEHICLE', 'VehicleSumAssured');
                    return;
                }
                this.loading("show");
                policyObject.root.quoteData.vehicleData.adjustedVehicleSumInsured = value.toString();
                let inputPricingObj = createInputPricingObj();
                setInputPricing(inputPricingObj);
                await calculatePlanOptionsPrice(true);
                this.bindData();
                this.loading("hide");
            } catch (error) {
                this.loading("hide");
                // this.errorModal(this.$t("packageUnavailable"),"mv error");
                this.errorModal(this.$t("packageUnavailable"), 'title', this.$t("packageUnavailableTitle"), this.$t("packageUnavailableContact"), this.$t("packageUnavailable2")); //packageUnavailable
                // this.errorModal(this.$t("packageUnavailable"));
                console.log(error)
            }
            this.loading("hide");
        },
        async resetAdditionalCoverage(){
            await this.updateSelectedPlanPrice('Not covered', 'PAME', 'PAMESumAssured')
            await this.updateSelectedPlanPrice('Not covered', 'TPL', 'TPLSumAssured')
            await this.updateSelectedPlanPrice(false, 'FL', 'MOTORVEHICLE.VehicleSumAssured')
            await this.updateSelectedPlanPrice(false, 'EQ', 'MOTORVEHICLE.VehicleSumAssured')
            await this.updateSelectedPlanPrice(false, 'TRR', 'MOTORVEHICLE.VehicleSumAssured')
            await this.updateSelectedPlanPrice(false, 'SRCC', 'MOTORVEHICLE.VehicleSumAssured')
            await this.updateSelectedPlanPrice(false, 'RSA', 'RSAOption')
            await this.updateSelectedPlanPrice(false, 'TA', 'TAOption')
            let resetSelection = {
                FL: false, EQ: false, TRR: false, SRCC: false, RSA: false, TA: false
            }
            this.checkSelected = resetSelection;
            this.selectedPlanForTPL = 'Not covered';
            this.selectedPlanForPAME = 'Not covered';
            this.$refs.tplRef.selectPackageItem('Not covered','TPLSumAssured',0)
            this.$refs.pameRef.selectPackageItem('Not covered','PAMESumAssured',0)
        },
        async checkVoucher(){
            if (this.checkValidateVoucher()) {
                this.voucherPopUp = false;
                this.loading("show");
                let otpResponse = await this.generateOTP();
                if (otpResponse.data.success === true) {
                    this.refID = otpResponse.data.refID;
                    this.modalOTP = true;
                    this.counting = true;
                    this.loading("hide");
                }
            }
        },
        generateOTP: function () {

            this.refID = "";
            this.OTPcountdown = 60;
            return axios.post(Global.roojaiService.url + "/otp/generate", {
                destination: policyObject.root.quoteData.policyHolderData.phoneNumber,
                locale: Global.quote.prefLang,
                inputType: "phone",
                source: "insure"
            },
                {
                    headers: { Authorization: "Bearer " + Global.roojaiService.token }
                }
            );
        },
        resendOTP: function() {
            var vm = this;
            vm.modalOTP = false;
            vm.loading("show");
            vm.generateOTP()
            .then(function(otpResponse) {
                if (otpResponse.data.success === true) {
                    vm.refID = otpResponse.data.refID;
                    vm.OTP = "";
                    vm.modalOTP = true;
                    vm.counting = true
                    vm.loading("hide");
                    // vm.OTPcountdown = 30
                    // vm.countdownOTP();
                }
            })
            .catch(function(err) {
                console.log("resendOTP", err);
            });
        },
        countdownOTP: function () {
            var vm = this;
            vm.counting = true
            vm.isOTPcountdown = true;

            setTimeout(function () {
                vm.isOTPcountdown = false;
            }, 60000);
        },
        resetOTPModal: function () {
            this.isOTPprocessing = false;
            this.counting = false
            this.isChangeNumber4OTP = false;
            this.isOTPverificationfailed = false;
        },
        async getGarage(){
            try {
                let reqGarage = {
                    "province" : this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].province,
                    "district" : this.policyObject.root.quoteData.policyHolderData.contactAddresses[0].district,
                    "garageType" : this.selectedPlan.MOTORVEHICLE.Workshop == 'Dealer' ? "Any" : "Panel",
                }
                this.garageType = this.selectedPlan.MOTORVEHICLE.Workshop 
                if(Global.motorVehicle.isDefaultReward && Global.motorVehicle.DefaultReward > 0 && this.garageType == 'Dealer'){
                    this.popGetReward = true
                }    
                const response = await this.request().post(Global.roojaiService.url + "/garageData/getGarageData", reqGarage,{
                    headers: { Authorization: "Bearer " + Global.roojaiService.token }
                });
                if (response.data.success == true) {
                    this.garages = response.data.items;
                    return response
                } else {
                    Global.isValidated.postcode = false;
                    this.garages = [];
                    return response
                }
            } catch (error) {
                console.error("getProvince => ", error);
            }
        },
        firstrewardPoint(){
            let rewardRes = getRewardPoint();
            if (rewardRes > 0 && Global.motorVehicle.isDefaultReward == null) {
                Global.motorVehicle.isDefaultReward = true;
                Global.motorVehicle.DefaultReward = rewardRes;
                this.isDefaultReward = true
                this.DefaultReward = rewardRes
            }
            this.policyObject.root.quoteData.rewardPoint = rewardRes;
            Global.motorVehicle.Reward = rewardRes;
        },
        enableAnimate(value){
            // console.log(value);
            if (value == 'reward') {
                this.triggerHeader = 'reward'
                this.triggerAnimateHeader = true;
                setTimeout(()=> {
                    this.triggerHeader = null;
                    this.triggerAnimateHeader = false;
                },10);
            }else if(value == 'si'){
                this.triggerHeader = 'sumInsured'
                this.triggerAnimateHeader = true;
                setTimeout(()=> {
                    this.triggerHeader = null;
                    this.triggerAnimateHeader = false;
                },10);
            }else if(value == 'price'){
                this.triggerHeader = 'price'
                this.triggerAnimateHeader = true;
                setTimeout(()=> {
                    this.triggerHeader = null
                    this.triggerAnimateHeader = false
                },10);
            }
            
        },
        onCountdownEnd: function () {
            this.counting = false;
        },

        async saveQuoteFetch() {

            let dobPolicyHolder = null;
            let dobInsuredData= null;
            this.policyObject.root.quoteData.policyHolderData.dob = dayMonthYearFormatedDate(this.policyObject.root.quoteData.policyHolderData.dob,"day");
            dobPolicyHolder = ymdFormatedDate(String(this.policyObject.root.quoteData.policyHolderData.dob).split("-").reverse().join("-"))           
            dobInsuredData = ymdFormatedDate(String(this.policyObject.root.quoteData.insuredData.dob))           
            this.policyObject.root.quoteData.policyHolderData.dob = dobPolicyHolder; 
            if(this.policyObject.root.quoteData.vehicleData.isMainDriverPH === false){
                this.policyObject.root.quoteData.insuredData.dob = dobInsuredData;
            }else{
                this.policyObject.root.quoteData.insuredData.dob = dobPolicyHolder;
            }
            // this.policyObject.root.quoteData.vehicleData.vehicleSumInsured = this.selectedPlan.MOTORVEHICLE.VehicleSumAssured;
            this.policyObject.root.quoteData.paymentData.paymentFrequency = this.defaultPaymentFrequency;
            
            setPolicyServiceInputObj(this.policyObject);
            // setPolicyServiceMappingFromInputPriceResponse(policyServiceMappingObj);
            let interceptorObj = setInterceptorObj();

            let resQuote = await saveQuote(interceptorObj);
            this.policyObject.root.quoteData.opportunityNumber = resQuote.opportunityNumber;
            if(resQuote.sfAccountId){
                this.policyObject.root.quoteData.policyHolderData.sfAccountId = resQuote.sfAccountId;
            }
            Global.quote.opportunityNumber = resQuote.opportunityNumber
        },

        async bindData() {
            this.dependencyMappingObject = getDependencyMapping();
            this.coverageOptionPrice = getCoverageOptionPrice();
            this.selectedPlan = getSelectedPlan();
            this.availablePaymentFrequency = getAvailablePaymentFrequency();
            this.defaultPaymentFrequency = getDefaultPaymentFrequency();
            this.coveragePrice = getCoveragePrice();
            this.paymentOptionPrice = getPolicyPaymentOptionPrice();
            Global.motorVehicle.Reward = getRewardPoint();  
            this.policyObject.root.quoteData.rewardPoint = getRewardPoint();
            
        },
        async changeWorkshop(value, agentSI){
            await this.resetAdditionalCoverage()
            if(Global.motorVehicle.firstSumInsured != this.selectedPlan.MOTORVEHICLE.VehicleSumAssured && this.garageType != value){
                this.showModalSumInsured = true
                this.showWarnSum = true
                await this.updateSelectedPlanPrice(Global.motorVehicle.firstSumInsured, 'MOTORVEHICLE', 'VehicleSumAssured', agentSI)
            }
            this.updateSelectedPlanPrice (value, 'MOTORVEHICLE', 'Workshop', agentSI);
            await this.bindData();
            this.garageType = value
            if (value == 'Dealer' && !agentSI) {
                this.popGetReward = true;
            }
        },
        onScrollNavbar() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            var mvFooter = document.getElementById('mv-footer');
            this.handleFloatingFooter(mvFooter, 100, currentScrollPosition > 240)
            // console.log(currentScrollPosition);

            if (!Global.isAgentCustomerView && currentScrollPosition < 10) {
                $(".scroll-bg").fadeIn();
                this.showScrollDown = true;
            } else if(Global.isAgentCustomerView && currentScrollPosition < 30){
                $(".scroll-bg").fadeIn();
                this.showScrollDown = true;
            } else {
                $(".scroll-bg").fadeOut();
                this.showScrollDown = false;
            }
            // console.log(this.showScrollDown);

            if (currentScrollPosition > 240) {
                this.showPriceHeader = true;
            } else {
                this.showPriceHeader = false;
            }
        },
        scrollDown(){
            window.scrollTo({
                top: 350,
                behavior: "smooth",
            })
        },

        switchPaymentType(value) {
            updatePaymentFrequency(value, true);       
            this.bindData();          
            this.defaultPaymentFrequency = value;
            this.eventLog('Instalment-MotorCar', this.defaultPaymentFrequency)
        },

        hideVoucher(){
            this.isHideVoucher = !this.isHideVoucher
        },

        async updateSelectedPlanPrice(value, codeKey, optionName, agentSI) {
            let optionIndx = this.getOptIndx(codeKey, optionName);
            if (this.coverageOptionPrice[codeKey].flagYesOrNo) {
                updateSelectedPlan(codeKey, optionIndx, value);
                this.eventLog('Update-Selected-PlanPrice', value)
            } else {
                updateSelectedPlan(codeKey, optionIndx, value);
                this.eventLog('Update-Selected-PlanPrice', value)
            }
            
            await this.bindData();
            this.getSelectedCoverages();
            this.testbenefitCoverage();
            this.testbenefit();
            if(codeKey == 'PAME')
            this.selectedPlanMultiOptions('PAME');
            if(codeKey == 'TPL')
            this.selectedPlanMultiOptions('TPL');
            if(codeKey == 'MOTORVEHICLE')
            this.selectedPlanMultiOptions('MOTORVEHICLE');
            if (value == 'Dealer' || value == 'Panel') {
                this.enableAnimate('reward')
            }else if(codeKey == 'MOTORVEHICLE'){
                this.enableAnimate('si')
            }else if(codeKey != 'MOTORVEHICLE'){
                this.enableAnimate('price')
            }
            if(Global.motorVehicle.isDefaultReward && Global.motorVehicle.DefaultReward > 0 && this.garageType == 'Dealer' && !agentSI){
                this.popGetReward = true
            }
            return;   
        },

        selectedPlanMultiOptions(code){
            if(code === 'PAME'){

                if(this.selectedPlan[code].defaultPlan == null){
                    this.selectedPlanForPAME = 'Not covered';
                }else{
                    this.selectedPlanForPAME = this.selectedPlan[code].defaultPlan.split("_")[0];
                }

            }else if(code === 'TPL'){

                if(this.selectedPlan[code].defaultPlan == null){
                    this.selectedPlanForTPL = 'Not covered';
                }else{
                    this.selectedPlanForTPL = this.selectedPlan[code].defaultPlan.split("_")[0];
                }

            }else{
                this.selectedPlanForMOTOR = this.selectedPlan[code].VehicleSumAssured;
            }
            
        },
        getCoverageDetail(item){
            if (item == 'RSA') {
                this.addOnPopUpList = this.rsaLists;
                this.addOnPopUpPrice = this.coverageOptionPrice[item][item+'Option']['1'] !== undefined ? this.coverageOptionPrice[item][item+'Option']['1'] : '';
                this.addOnPopUpOption = 'RSA'+'Option';
                this.addOnPopUpTitle =  'RSA';
            }else if(item == 'TA'){
                this.addOnPopUpList = this.taLists;
                this.addOnPopUpPrice = this.coverageOptionPrice[item][item+'Option']['1'] !== undefined ? this.coverageOptionPrice[item][item+'Option']['1'] : '';
                this.addOnPopUpOption = 'TA'+'Option';
                this.addOnPopUpTitle =  'TA';
            }else if(item == 'FL'){
                this.addOnPopUpList = this.flLists;
                this.addOnPopUpOption = 'MOTORVEHICLE.VehicleSumAssured';
                this.addOnPopUpTitle =  'FL';
            }else if(item == 'EQ'){
                this.addOnPopUpList = this.eqLists;
                this.addOnPopUpOption = 'MOTORVEHICLE.VehicleSumAssured';
                this.addOnPopUpTitle =  'EQ';
            }else if(item == 'SRCC'){
                this.addOnPopUpList = this.srccLists;
                this.addOnPopUpOption = 'MOTORVEHICLE.VehicleSumAssured';
                this.addOnPopUpTitle =  'SRCC';
            }else if(item == 'TRR'){
                this.addOnPopUpList = this.trrLists;
                this.addOnPopUpOption = 'MOTORVEHICLE.VehicleSumAssured';
                this.addOnPopUpTitle =  'TRR';
            }
            this.addOnPopUp = true
        },

        getSelectedCoverages(){

            this.selectedCoverage = [];
            this.noSelectedCoverage = [];
            Object.keys(this.selectedPlan).forEach(codeKey => {
                if(this.coverageOptionPrice[codeKey].flagYesOrNo){
                    if(this.selectedPlan[codeKey].defaultPlan != null && this.selectedPlan[codeKey].defaultPlan != undefined){
                    this.selectedCoverage.push(codeKey);
                }else{
                    this.noSelectedCoverage.push(codeKey);
                }
                }
                
            })
            
        },

        getOptIndx(codeKey, optionName){
            const selectOptions = this.dependencyMappingObject[codeKey].selectOptions;
            for (let optKey of Object.keys(selectOptions)) {
                if (selectOptions[optKey].optionName === optionName) {                   
                    return optKey;
                }
            }
        },
        numberFormat(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        
        async next() {
            Global.motorVehicle.flagRetrievePolicy = false
            this.$router.push({ name: 'mv-policydetails' })
        },
        callMeBackPhone(type) {
            var vm = this;
            const regxIndoPhone = /^(^\+62|62|^08)(\d{3,4}-?){2}\d{2,3}$/g;
            let phone = this.policyObject.root.quoteData.policyHolderData.phoneNumber
            if(phone !== null && phone.match(regxIndoPhone)){
                this.policyObject.root.quoteData.callMeBack = true;
                this.policyObject.root.quoteData.callMeBackRequest = "Yes";
                this.policyObject.root.quoteData.sendEmailFlag = false;
                this.policyObject.root.quoteData.onlineStage = "Call me back";
    
                this.eventTracking('Oppo', 'quotationResultPackages', 'Opportunity_save_Phone', 'CAR');
                this.saveQuoteFetch();
                if (Global.motorVehicle.flagcallmeback && Global.motorVehicle.flagemailmequote) {
                    this.eventTracking('Oppo', 'quotationResultPackages', 'Opportunity_save_Email_phone', 'CAR');
                }
                if(type == 'email'){
                    this.emailOffers = false
                    this.emailOffersWithPhone = false
                    this.showModalsuccessEmail = true
                } else {
                    this.phoneCall = false;
                    this.phoneCallthankyou = true;
                }
            } else {
                this.invalidInputPhoneNumber = true
            }
        },

        async sendAgentEmail() {
            this.policyObject.root.quoteData.sendEmailFlag = true;
            this.policyObject.root.quoteData.onlineStage = "Email me my quote";

            this.eventTracking('Oppo', 'quotationResultPackages', 'Opportunity_save_Email', 'CAR');
            this.loading("show");
            await this.saveQuoteFetch();
            this.loading("hide");
            this.policyObject.root.quoteData.sendEmailFlag = false;
            this.showModalsuccessEmailAgent = true;
        },

        async sendMeEmailOffer() {
            var vm = this;
            if(vm.validate(policyObject.root.quoteData.policyHolderData.email).isEmail()){
                this.policyObject.root.quoteData.sendEmailFlag = true;
                this.policyObject.root.quoteData.onlineStage = "Email me my quote";
    
                this.eventTracking('Oppo', 'quotationResultPackages', 'Opportunity_save_Email', 'CAR');
                // this.emailOffers = false;
                this.emailOffersWithPhone = true
                await this.saveQuoteFetch();
                this.policyObject.root.quoteData.sendEmailFlag = false;
            } else {
                vm.invalidInputEmail = true;
            }
        },

        reachLimitAcc(){
            this.showModalWarranty = false
            this.showModalWarningAcc = true;
        },
        closeWarranty() {
            if (this.policyObject.root.quoteData.vehicleData.accessoriesData.length == 0) {
                this.showModalWarranty = false;
                this.$refs.accAction.unselected();
            } else {
                this.showModalWarranty = false;
            }
            this.isAccessoriesWarning = false;
            this.styleModalAcc = "";

            const notSelect = [];
            const moveToCovered = [];

            this.updateListAddOnSelected();
        },
        openReward() {
            this.showModalReward = true;
        },
        popUpReward(value){
            if(value >= 0 && this.garageType == 'Panel'){
                this.showModalInfoReward = true;
            }else if(this.isCarCanGetaReward){
                this.showModalRecomendReward = true;
            }
            // }else{
            //     this.showModalInfoReward = true;
            // }
        },

        openModalgarage() {
            this.showModalGarage = true;
        },

        testbenefit() {
            const item = this.selectedPlan.MOTORVEHICLE.Workshop === 'Dealer'? 'Dealer' : this.selectedPlan.MOTORVEHICLE.Workshop === 'Panel' && 'Panel'
            return item;
        },
        testbenefitCoverage() {
            this.selectedCoveragesForMultiOpt = {
                "tpl": this.selectedPlan.TPL.defaultPlan != null ? String(this.formatNumber(Number(this.selectedPlan.TPL.defaultPlan.split("_")[0]))) : '0' ,
                "pame": this.selectedPlan.PAME.defaultPlan != null ? String(this.formatNumber(Number(this.selectedPlan.PAME.defaultPlan.split("_")[0]))) : '0'
            }
            return this.selectedCoveragesForMultiOpt
        },
    },
    computed: {
        benefit() {
            const item = [
                {
                    label: this.$i18n.t('motorVehicle.benefit.repairGarage'),
                    bold: this.garageType == 'Dealer'? this.$i18n.t('motorVehicle.benefit.dealerGarage') : this.garageType == 'Panel' && this.$i18n.t('motorVehicle.benefit.partnerGarage')
                },
            ];
            return item;
        },
        benefitCoverage() {
            return {
                "tpl": this.$i18n.t('motorVehicle.benefit.thirdParty'),
                "pame": this.$i18n.t('motorVehicle.benefit.personalAccident'),
                "valuePame": this.selectedPlanForPAME == 'Not covered' ? '' : this.formatNumber(Number(this.selectedPlanForPAME)),
                "valueTpl": this.selectedPlanForTPL == 'Not covered' ? '' : this.formatNumber(Number(this.selectedPlanForTPL)),
                "perPerson" : this.$i18n.t('motorVehicle.perPerson')
            }
        },

        compLists() {
            // try to fill sum insured
            const comp = [
                {
                    label: this.$i18n.t('motorVehicle.comp.damage', { sumInsured: this.formatNumber(parseInt(this.selectedPlan.MOTORVEHICLE.VehicleSumAssured)) })
                },
                {
                    label: this.$i18n.t('motorVehicle.comp.crime')
                },
                {
                    label: this.$i18n.t('motorVehicle.comp.stealing')
                },
                {
                    label: this.$i18n.t('motorVehicle.comp.fire')
                },
                {
                    label: this.$i18n.t('motorVehicle.comp.crane')
                }
            ];

            return comp;
        },
        srccLists() {
            const srcc = [
                {
                    title: this.$i18n.t('motorVehicle.srcc.item1')
                },
            ];
            return srcc;
        },
        trrLists() {
            const trr = [
                {
                    title: this.$i18n.t('motorVehicle.trr.item1')
                },
            ];
            return trr;
        },
        flLists() {
            const naturalDisaster = [
                {
                    title: this.$i18n.t('motorVehicle.fl.item1title'),
                    text: this.$i18n.t('motorVehicle.fl.item1text'),
                }
            ];
            return naturalDisaster;
        },
        eqLists() {
            const naturalDisaster = [
                {
                    title: this.$i18n.t('motorVehicle.eq.item1title'),
                    text: this.$i18n.t('motorVehicle.eq.item1text'),
                },
            ];
            return naturalDisaster;
        },
        rsaLists() {
            const roadsideLists = [
                {
                    title: this.$i18n.t('motorVehicle.rsa.item1')
                },
            ];
            return roadsideLists;
        },
        taLists() {
            const transportation = [
                {
                    title: this.$i18n.t('motorVehicle.ta.item1')
                },
            ];

            return transportation;
        },
        carBrand(){
            var carBrands = ["Toyota","Honda","Mitsubishi","Daihatsu","Suzuki","Nissan","Wuling","Hyundai","Mazda"]
            if (carBrands.includes(Global.motorVehicle.quote.Make)) {
                return "images/motor-vehicle/"+ Global.motorVehicle.quote.Make + ".svg"
            }else{
                return "images/motor-vehicle/Car.svg"
            }
        },
        palanLists() {
            return Object.keys(this.selectedPlan);
        },
        vehicleSumAssuredPlan() {
            let vehicleSumAssure = null;
            if (this.calculatedData) {
                this.calculatedData.coverages.map((key) => {
                    if (key.coverageName === 'VehicleSumAssured') {
                        vehicleSumAssure = key;
                        return vehicleSumAssure;
                    }
                });
            }
            return vehicleSumAssure;
        },
        workshopPlan() {
            let workshop = null;
            if (this.calculatedData) {
                this.calculatedData.coverages.map((key) => {
                    if (key.coverageName === 'Workshop') {
                        workshop = key;
                        return workshop;
                    }
                });
            }
            return workshop;
        },
        isRsaAddOn() {
            let rsa = false;
            if (this.calculatedData) {
                this.calculatedData.additionalCoverage.map((key) => {
                    if (key.addOnName === 'RSA') {
                        rsa = true;
                        return rsa;
                    }
                });
            }
            return rsa;
        },
        accessoriesBtnTxt() {
            const isFound = Global.motorVehicle.addOnSelected.find(key => key === "ACC");
            if (!isFound) return this.$i18n.t('motorVehicle.btnAccAdon');
            else return this.$i18n.t('motorVehicle.editBtnAccAdon');
        },
        isAvailablePanel(){
            return this.coverageOptionPrice['MOTORVEHICLE'].Workshop.hasOwnProperty('Panel')
        },
        isAvailableDealer(){
            return this.coverageOptionPrice['MOTORVEHICLE'].Workshop.hasOwnProperty('Dealer')
        },
        isDefaultSwitchToYearly() {
            return this.defaultPaymentFrequency == 'Yearly';
        },
        isCarAboveEight(){
            const d = new Date();
            let year = d.getFullYear() - this.policyObject.root.quoteData.vehicleData.yearOfManufacture
            return year >= 9
        },
        isCarCanGetaReward(){
            return Global.motorVehicle.isDefaultReward && Global.motorVehicle.DefaultReward >= 0 && this.garageType == 'Dealer'
        },
        getMinInsured(){
            return Math.ceil(parseInt(this.policyObject.root.quoteData.vehicleData.vehicleSumInsured) * 0.8);
        },
        getMaxInsured(){
            return Math.ceil(Math.max((this.policyObject.root.quoteData.vehicleData.newPrice)))
        },
        
    },
}
</script>
    
<style scoped>

.garagebuttoncontainer{
    display: flex;
    padding: 8px 16px;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 4px;
    border-top: 0.5px solid #CCC;
    border-right: 0.5px solid #CCC;
    border-bottom: 2px solid #CCC;
    border-left: 0.5px solid #CCC;
    background: #FFF;
}
.garagebuttontext{
    color: #6F6F6F;
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    flex: 1;
}
.garagebuttonshow{
    display: flex;
    padding-bottom: 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-bottom: 1px solid #EF4A24;
    color: var(--Orange-60, #EF4A24);
    text-align: right;
    font-family: Public Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: 0.24px;
    cursor: pointer;
}
.newslettercontainer{
    cursor: pointer;
    margin-bottom: 16px;
    gap: 8px;
}
.newslettercontainer:hover .newsletterCheck{
    border: 2px solid#EF4A24;
}
.newsletterText {
    text-align: left; 
    color: #6F6F6F; 
    font-family: Noto Sans; 
    font-size: 12px; 
    font-style: normal; 
    font-weight: 400; 
    line-height: 14px;
}
.newsletterCheck {
    border-radius: 6px;
    border: 2px solid#CCC;
    background: #FFF;
    padding: 1px;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    display: flex;
}
.activeCheck {
    background: #EF4A24;
    border: 2px solid#EF4A24;
}
.activeCheck svg path{
    stroke: #FFFFFF;
}
.invalidInputCallMe {
  display: flex;
  padding: 4px;
  gap: 4px;
  align-items: center;
  border-radius: 4px;
  background: #FFF1F1;
  color: #DA1E28;
  font-family: Public Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  letter-spacing: 0.2px;
  margin-bottom: 16px;
}
.inputExample {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  background: #F0FAFF;
  color: #003A6E;
  font-family: Public Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  letter-spacing: 0.2px;
  margin-bottom: 16px;
}
.mv-packages {
    padding: 24px 0px;
    background-image: linear-gradient(#003A6E 3%, #E8E8E8 3%);
    font-family:'Public Sans';
}
@media screen and (max-width: 377px){
    .packagesStage .payment .img-premi{
        margin-right: 2px !important;
    }
}

@media screen and (max-width: 480px) {
    .mv-packages {
        padding: 24px 0px;
        padding-bottom: 0px;
        background-image: linear-gradient(#003A6E 3%, #E8E8E8 3%);
    }
    .mv-packages .container{
        padding-left: 0px;
        padding-right: 0px;
    }
    .scroll-bg{
        width: 100% !important;
    }
    .packagesStage .payment .premi{
        width: 72px !important;
    }
    .packagesStage .payment .switch{
        margin: 0px 4px !important;
    }
    .packagesStage .payment{
        padding: 16px !important;
    }
    .packagesStage .payment.intermediary{
        padding: 16px 16px 8px 16px !important;
    }
    .packagesStage .summaryPrice2{
        padding: 0px 16px !important;
    }
    .plus-area{
        padding: 0px 16px !important;
    }
    .rewardPackage{
        padding: 0px 16px !important;
    }
    .btn-close-modal{
        background-position: top 7px left 7.2px !important;
    }
    .btn-close-modal:hover{
        background-position: top 7px left 7.2px !important;
    }  
    
}

.body-thankyou{
    padding: 8px 16px 16px 16px;
}

.noto{
    font-family: 'Noto Sans';
}
.bolder{
    font-weight: 800 !important;
}

.public{
    font-family: 'Public Sans';
}

.plus-area{
    width: 100%;
    display: flex;
    padding: 0px 72px 0px 72px;
}
.line-plus{
    width: 50%;
    margin-left: 0px;
    margin-right: 8px;
    border-top: 1px solid #E8E8E8;
}
.voucust{
    font-size: 10px;
    font-weight: 400;
    color: #E8E8E8;
}
.line-plus:last-child{
    width: 50%;
    margin-left: 8px;
    margin-right: 0px;
    border-top: 1px solid #E8E8E8;
}

.payment-details{
    background-color: white;
    margin: 16px 0px;
    border-width: 1px 8px 1px 8px;
    border-style: solid;
    border-color: rgba(0, 83, 154, 1);
    border-radius: 8px;
}

.body-thankyou .inp{
    margin-bottom: 2px;
}

.body-thankyou .inp.carplate{
    margin-bottom: 2px;
}

.example-msg{
    background: #E2EFF9;
    border-radius: 4px;
    padding: 4px 4px 6px 8px;
    color: #003A6E;
    font-size:10px;
}
.error-msg{
    background: #FFF1F1;
    border-radius: 4px;
    padding: 4px 4px 6px 8px;
    color: #DA1E28;
    font-size:10px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
}
.text-white{
    color: #fff !important;
}
.voucher-gif{
    position:fixed;
    transition: all 0.75s ease;
    bottom:120px;
    z-index: 1001;
    right:0;
}
.voucher-gif.scrolling{
    right: -180px;
}
.voucher-gif.hide{
    right: -100px;
}
.bg-voucher{
    background: linear-gradient(180deg, #EF4A24 0%, #F26848 100%);
    width: 80px;
    border-radius: 8px;
}
.bg-voucher .img{
    margin-top: 8px;
    padding-left: 4px;
    padding-right: 4px;
}

.msg-voucher{
    background-color:#fff;
    height: 86px;
    border-radius: 8px;
    width:100%;
    padding: 4px 8px 8px 16px;
}

.msg-voucher .msg{
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #000;
}
.msg-voucher .price{
    font-size: 24px;
    line-height: 22px;
    font-weight: 800;
    color: #000;
}
.msg-voucher .hint{
    font-size: 8px;
    line-height: 22px;
    font-weight: 400;
    color: #000;
}

.btn-update {
    height: 30px;
    padding: 6px;
    font-size: 12px;
    border-radius: 8px;
    margin-bottom: 0px;
    color: #EF4A24;
    background-color: #fff;
    font-weight: 800;
    border: 1px solid #C73E1E;
    transition: background 0.5s step-start;
}
.btn-update:hover{
    background: #FFE7E1;
}

div div >>> .otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 2px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
/* Background colour of an input field with value */
div div >>> .otp-input.is-complete {
    background-color: #E6F9EB;
    border-color: #24A148;
    border-width: 2px;
}
div div >>> .otp-input::-webkit-inner-spin-button,
div div >>> .otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.padbot-0{
    padding-bottom: 0px !important;
}

.package-plan{
    padding-top: 0px !important;
}

.alert-dealer{
  background: #FFF3F0;
  border: 1px solid #ED1C24;
  border-radius: 8px;
}
.img-msg-dealer{
    margin-top: 6px;
    height: 20px;
    width: 20px;
}
.msg-dealer{
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6f6f6f;
    margin-left: 12px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.alert-sum-insured{
  background: #FEFCE8;
  border-top: 0.5px solid #CA8A04;
  border-right: 0.5px solid #CA8A04;
  border-bottom: 2px solid #CA8A04;
  border-left: 0.5px solid #CA8A04;
  border-radius: 8px;
  visibility: hidden;
  display: none;
  transition: visibility 0s, opacity 0.5s linear;
  padding:8px 16px;
}
.alert-sum-insured.show{
    visibility: visible;
    display: block;
}
.img-msg-sum-insured{
    margin-top: 5px;
    height: 20px;
    width: 20px;
}
.msg-sum-insured{
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #CA8A04;
    margin-left: 12px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.modalReward .roojai-rewards .reward-button button.insured{
    padding: 6px !important;
}

.bg-grey{
    /* background: #f2f2f2; */
    margin-left: -20px;
    margin-right: -20px;
    padding: 0px 24px 24px 24px;
    border-radius: 3px;
}

.packagesStage .plan .bg-grey .cover-list ul {
    margin-bottom: 0px;
    margin-top: 8px;
}

.packagesStage .plan .bg-grey .cover-list ul li {
    line-height: 24px;
    margin-bottom: 4px;
    display: flex;
}

.packagesStage .plan .bg-grey .cover-list ul li:last-child {
    margin-bottom: 0px;
}

.mb16{
    margin-bottom: 16px !important;
}

.btn-close-modal{
    padding: 4px;
    width: 26px;
    height: 26px;
    background-color: #F4F9FF;
    border-radius: 13px;
    color: #A8A8A8;
    display: flex;
    justify-content: center;
    align-items: center;

}
.btn-close-modal svg{
    width: 12px;
    height: 12px;
}
.btn-close-modal:hover{
    background-color: #D6E6FF;
    cursor: pointer;
}  
.btn-close-modal:hover svg path{
    stroke: #000;
}  

.alert-voucher{
  background: #E6F9EB;
  border-width: 0.5px 0.5px 3px 0.5px;
  border-style: solid;
  border-color: #24A148;
  border-radius: 4px;
  margin-top: 16px;
}
.img-msg-voucher-upd{
  margin-top: 6px;
  height: 20px;
  width: 20px;
}
.img-msg-voucher{
  height: 20px;
  width: 20px;
}
.alert-msg-voucher{
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #000;
    max-width: 100%;
    margin-left: 12px;
    align-self: center;
}
.link-sum{
    color: #EF4A24;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: #EF4A24;
    text-decoration-thickness: 2.3px;
    text-underline-offset: 5px;
    line-height: 26px;
    cursor: pointer;
    margin-left: auto;
    font-size: 10px;
    font-weight: 700;
    align-self: center;
}
.alert-reward{
  background: #E6F9EB;
  border: 1px solid #24A148;
  border-radius: 8px 16px;
}
.img-msg-reward-upd{
  margin-top: 6px;
  height: 24px;
  width: 24px;
}
.img-msg-reward{
  height: 24px;
  width: 24px;
}
.msg-reward{
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #424242;
    margin-left: 12px;
    margin-right: 4px;
    margin-top: 4px;
    margin-bottom: 4px;

}

.alert-sum{
    display: flex;
    padding: 8px 16px;
    gap: 12px;
    border-radius: 4px;
    border-top: 0.5px solid #24A148;
    border-right: 0.5px solid #24A148;
    border-bottom: 2px solid #24A148;
    border-left: 0.5px solid #24A148;
    background: #E6F9EB;
}
.img-msg-sum-upd{
  margin-top: 6px;
  height: 24px;
  width: 24px;
}
.img-msg-sum{
  height: 24px;
  width: 24px;
}
.msg-sum{
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #424242;
    max-width: 440px;
    margin-left: 12px;
    margin-right: 4px;
    align-self: center;
}
.link-sum{
    color: #EF4A24;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: #EF4A24;
    text-decoration-thickness: 2.3px;
    text-underline-offset: 5px;
    cursor: pointer;
    padding-top: 4px;
    margin-left: auto;
}
.text-change2pref{
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
}

.btn-change2pref{
    height: 48px;
    padding: 0px !important;
}

.section {
    max-width: 560px;
    width: 100%;
    padding: 0 15px;
    margin: auto;
}

.packagesStage #price2top {
    text-align: center;
}

.packagesStage #price2top .quote-title {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #fff;
}

.packagesStage .payment {
    display: flex;
    justify-content: space-between;
    max-width: 524px;
    padding: 24px 68px 24px 68px;
}

.packagesStage .payment.intermediary{
    padding: 24px 68px 8px 68px ;
}

.packagesStage .payment .switch-label-installment {
    width: 10%;
    min-width: 51px;
    padding-top: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    color: #8D8D8D;
}

.packagesStage .payment .switch-label {
    width: 10%;
    min-width: 36px;
    padding-top: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    color: #8D8D8D;
}
.packagesStage .payment .img-premi{
    margin-right: 8px;
}

.packagesStage .payment .premi{
    height: 20px;
    width: 110px;
    color: #8D8D8D;
    margin-top: 5px;
    font-size: 10px;
    font-weight: 400;
}
.packagesStage .payment .disc{
    height: 18px;
    width: 50px;
    margin-top: 5px;
    margin-left: 8px;
    border-radius: 4px;
    background-color: #F7F7F7;
    color: #A8A8A8;
    padding: 2px 4px;
    font-weight: 500;
    text-align: center;
    font-size: 10px;
}
.packagesStage .payment .disc.active{
    background-color: #E6F9EB !important;
    color: #198038 !important;
}
.packagesStage .payment .text-left span{
    font-weight: 400!important;
}
.packagesStage .payment .active {
    color: #FF4713;
    font-weight: 700;
}

.button-lst .button-next{
    width:100%;
}

.button-lst .button-email{
    width:35%;
}

.button-lst .button-email.full{
    width:100%;
}
.button-lst .call-label{
    color: #FF4713;
    display: flex;
    flex-direction: row;
}
.button-lst .call-label:before,
.button-lst .call-label:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #FF4713;
    margin: auto;
}
.button-lst .call-label:before {
    margin-right: 10px;
}
.button-lst .call-label:after {
    margin-left: 10px;
}

.button-lst .button-call{
    width:65%;
    margin-left: 8px;
}

.packagesStage .payment .switch {
    margin: 0px 4px;
}

.packagesStage .summaryPrice2 {
    width: 100%;
    text-align: center;
    padding: 0px 72px 8px 72px;
}

.packagesStage .summaryPrice2 h1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.packagesStage .summaryPrice2 span {
    font-weight: 500;
}

.packagesStage .summaryPrice2 span.type-paid {
    margin-left: -0px;
}

.packagesStage .alert-warning {
    background: #FFF9F2;
    border: 1px solid #EECEB0;
    border-radius: 8px;
    color: #CD7B2E;
    margin-bottom: 32px;
}

.packagesStage .alert-warning .reward-text {
    display: inline-flex;
    font-weight: 600;
    font-size: 12px;
}

.packagesStage .alert-warning .reward-text .a2round {
    width: 20px;
    min-width: 20px;
    height: 20px;
    border: 2px solid #CD7B2E;
    display: block;
    border-radius: 50%;
    margin-left: 8px;
    font-size: 12px;
    font-weight: 400;
}

.packagesStage .coverage {
    background: linear-gradient(270deg, #E6F6FF 0%, rgba(230, 246, 255, 0) 100%);
    margin-right: -16px;
    margin-left: -16px;
    padding: 16px;
    /* padding: 20px 40px;
    border-left: 1px solid #D1D3D4;
    border-right: 1px solid #D1D3D4;
    border-bottom: 1px solid #D1D3D4;
    border-radius: 0px 0px 16px 8px; */
}

.packagesStage .plan .text-coverage {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #6F6F6F;
    /* margin-bottom: 8px; */
}

.packagesStage .plan .cover-list ul {
    list-style: none;
    padding: 0;
    margin-top: 21px;
}

.packagesStage .plan .cover-list ul li {
    line-height: 27px;
    margin-bottom: 16px;
    display: flex;
}

.packagesStage .plan .cover-list ul li span {
    color: #6F6F6F;
    align-self: center;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 18px
}

.packagesStage .plan .cover-list ul li span i {
    margin-right: 11px;
    color: #FF4713;
    font-size: 14px;
}

.packagesStage .coverage .text-coverage {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #6f6f6f;
}

.packagesStage .coverage .cover-list ul {
    list-style: none;
    padding-top: 0;
    margin-bottom:0px;
    margin-top:0px;
}

.packagesStage .coverage .cover-list ul li {
    line-height: 27px;
    padding: 0px 0px 8px 0px;
    display:flex;
    margin-bottom:0px
}
.packagesStage .coverage .cover-list ul li:last-child {
    padding: 0px;
}

.packagesStage .coverage .cover-list ul li span {
    color: #000;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px
}

.packagesStage .coverage .cover-list ul li span i {
    margin-right: 11px;
    color: #FF4713;
    font-size: 12px;
}

.packagesStage .not-cover {
    background: linear-gradient(270deg, #FFF1F1 0%, #FFFFFF 100%);
    margin-right: -16px;
    margin-left: -16px;
    padding: 16px;
}

.packagesStage .not-cover .text-coverage {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #6f6f6f;
}

.packagesStage .not-cover .cover-list ul {
    list-style: none;
    padding: 0;
    margin-top: 0px;
    margin-bottom: 0px;
}

#installment-detail-modal .modal-header{
  display: block;
}

#installment-detail-modal .modal-header .close{
  color: #ff5011;
}
@-webkit-keyframes bounceDown {

    0% 
    {
        -webkit-transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(13px);
    }
}

@keyframes bounceDown {

    0%
    {
        transform: translateY(0);
    }

    50% {
        transform: translateY(15px);
    }
}
.bounceDown{
    -webkit-animation-name: bounceDown;
    animation-name: bounceDown;
}
.animated2fast {
        animation-name: bounceDown;
        -webkit-animation-duration: 1.5s !important;
        animation-duration: 1.5s !important;
    }
@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}
@-webkit-keyframes rubberBand {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
@keyframes rubberBand {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

#installment-detail-modal .installment-option {
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  border: 1px solid #ece9e8;
  color: grey
}

#installment-detail-modal .installment-option .panel-body{
  padding: 15px;
}

#installment-detail-modal .installment-option.active {
    background-color: #fff !important;
    border: 1px solid #ff5011 !important;
    box-shadow: 0 2px 5px 1px rgba(154, 154, 154, .7) !important;
    color: #003b71 !important
}
#installment-detail-modal .installment-option.active .panel-footer {
    color: #fff;
    background-color: #ff5011;
}
#installment-detail-modal .installment-option.active .btn-pk-radio {
    border: 0
}
#installment-detail-modal .installment-option.active .btn-pk-radio .glyphicons.glyphicons-ok-sign {
    display: inline
}
#installment-detail-modal .installment-option .text-head {
    font-size: 16px
}
#installment-detail-modal .installment-option .text-desc {
    font-size: 16px
}
#installment-detail-modal .installment-option .btn-pk-radio {
    width: 30px;
    height: 30px;
    border-radius: 40px;
    background-color: #fff;
    border: 1px solid rgba(255, 53, 19, .3);
    padding: 0;
    margin: 0;
    z-index: 50;
    outline-color: #fff;
    cursor: pointer
}
#installment-detail-modal .installment-option .btn-pk-radio:hover {
    box-shadow: 0 2px 5px 1px rgba(154, 154, 154, .7);
    border: 0
}
#installment-detail-modal .installment-option .btn-pk-radio:hover .glyphicons.glyphicons-ok-sign {
    display: inline;
    color: #fa7f52
}
#installment-detail-modal .installment-option .btn-pk-radio .glyphicons {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 30px
}
#installment-detail-modal .installment-option .btn-pk-radio .glyphicons.glyphicons-ok-sign {
    color: #ff5011;
    display: none
}
#installment-detail-modal .installment-option .panel-footer {
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid #ece9e8;
    font-size: 17px;
    padding: 1rem 1rem;
}
#installment-detail-modal .installment-option .panel-footer p {
    margin: 0;
    padding: 0
}
.packagesStage .not-cover .cover-list ul li {
    line-height: 22px;
    padding: 0px 0px 8px 0px;
    margin-bottom: 0px;
}
.packagesStage .not-cover .cover-list ul li:last-child{
    line-height: 22px;
    padding: 0px 0px 0px 0px;
}

.packagesStage .not-cover .cover-list ul li span {
    display: flex;
    color: #525252;
    align-self: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px
}

.packagesStage .not-cover .cover-list ul li span i {
    margin-right: 11px;
    color: #525252;
    font-size: 14px;
}

.cash-icon{
    /* margin-top:5px; */
}

.addOn-content{
    margin-top: 16px;
    background: #F2F2F2;
    padding: 16px;
    border-radius: 8px;
}
.addOn-msg{
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #8D8D8D;
}
.addOn-sub{
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #161616;
}
.addOn-price{
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    padding: 2px 4px;
    font-size: 12px;
    line-height: 20px;
    color: #262626;
    background: #F2F2F2;
}
.addOn-descr{
    display: flex;
}
.unity h2{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 2px;
    color: #525252;
}

.packagesStage .check-icon::before {
    content: "\E207";
    display: inline-block;
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Regular';
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
}

/* .packagesStage .plan {
    margin: 32px 0px
} */

.next-new {
    position: absolute;
    bottom: 85px;
    right: 120px;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    box-shadow: 0 1px 5px 0 #e3e3e3;
    -webkit-box-shadow: #e3e3e3 0 1px 1px 0!important;
    width: 35px;
    height: 35px;
    padding: 6px 7px;
    border-radius: 50%;
    cursor: pointer;
}
.next-new .active{
    transition: all 0.5s ease;
    transform: rotate(0deg);
}
.next-new .active.hide{
    transform: rotateZ(180deg);
}

.icon-check{
    content: '';
    background: url('../../../public/images/motor-vehicle/Check.svg') no-repeat 0px 0px;
    display: inline-block;
    position: relative;
    align-self: baseline;
    margin-top: 3px;
    height: 18px;
    width: 24px;
    padding-right: 30px;
    vertical-align: top;
}
.icon-uncheck{
    content: '';
    background: url('../../../public/images/motor-vehicle/X.svg') no-repeat 0px 0px;
    display: inline-block;
    position: relative;
    align-self: center;
    height: 20px;
    width: 20px;
    vertical-align: top;
}

.packagesStage .plan .sum-insured-quest {
    display: flex;
    margin-bottom: 8px;
    align-items: flex-end;
}
.packagesStage .plan .sum-insured-text-quest {
    margin: 8px 0px;
    line-height: 22px;
    color: #6f6f6f;
    font-weight: 400;
    font-size: 14px;
}

.packagesStage .plan .garage-quest {
    display: flex;
    margin-bottom: 16px;
}

.packagesStage .plan .cash-quest{
    margin-right: 8px;
    align-self: baseline;
}

.packagesStage .plan .text-quest {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}

.packagesStage .plan .img-garage {
    text-align: center;
    margin: 16px 0;
}

.packagesStage .plan .garage-text {
    border-width: 0.5px 0.5px 2px 0.5px;
    border-style: solid;
    border-color: #C6C6C6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 4px 0px;
}

.packagesStage .plan .garage-text img {
    height: 20px;
    width: 20px;
    align-self: center;
}

.packagesStage .plan .garage-text span {
    color: #000;
    font-weight: 400;
    font-size: 12px;
    align-items: center;
    margin-left: 8px;
}

.packagesStage .plan .garage-text a {
    color: #EF4A24;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: #EF4A24;
    text-decoration-thickness: 2.3px;
    text-underline-offset: 4px;
    cursor: pointer;
    margin-left: 8px;
    margin-top: -6px;
    font-size: 10px;
    font-weight: 700;
    align-self: center;
}

.packagesStage .package-checklist {
    display: flex;
    /* margin: 16px 0; */
    border-left: 4px solid #F2F2F2;
    border-right: 4px solid #F2F2F2;
    padding: 8px 16px 16px 16px;
    background: #fff;
    margin-top: -1px;
}


.packagesStage .tpl-checklist.sum-insured{
    display: flex;
    align-items: center;
    padding: 16px 16px 0px 16px;
    border-left: 4px solid #F2F2F2;
    border-right: 4px solid #F2F2F2;
    border-top: 4px solid #F2F2F2;
    background: #fff;
}
.packagesStage .tpl-checklist{
    display: flex;
    align-items: center;
    padding: 16px 16px 0px 16px;
    border-left: 4px solid #F2F2F2;
    border-right: 4px solid #F2F2F2;
    background: #fff;
}

.packagesStage .pame-checklist{
    display: flex;
    align-items: center;
    padding: 16px 16px 0px 16px;
    border-left: 4px solid #F2F2F2;
    border-right: 4px solid #F2F2F2;
    background: #fff;
}

.packagesStage .tpl-checklist .icon {
    margin-right: 5px;
}

.packagesStage .tpl-checklist .title {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}

.packagesStage .pame-checklist .icon {
    margin-right: 5px;
}

.packagesStage .pame-checklist .title {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}

.scroll-bg{
    z-index: 100;
    width: 500px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    height: 192px;
    position: absolute;
    bottom: -96px;
    background: linear-gradient(0deg, #101F3F 0%, rgba(0, 58, 110, 0) 100%);
}

.packagesStage .package-checklist .icon {
    margin-right: 5px;
    color: #FF4713;
}

.packagesStage .package-checklist .checklist-area .title {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}

.packagesStage .package-checklist .checklist-area .descr {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #6f6f6f;
}

.packagesStage .resume {
    margin-bottom: 24px;
}

.packagesStage .resume .image {
    text-align: center;
    /* margin-top: 10px; */
}

.packagesStage .resume .resume-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    justify-content: center;
    color: #6F6F6F;
}
.resume-text .image {
    /* width: 20%; */
}
.resume-text .car-brand {
    font-weight: 800;
    font-size: 16px;
    margin-left: 16px;
    line-height: 24px;
    align-self: center;
    color: #161616;
}

.packagesStage .resume .text-coverage {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #161616;
    margin-top: 16px;
    margin-left: 0px;
}

.packagesStage .resume .text-coverage .text-sum {
    text-align: center;
    color: #6f6f6f;
} 
.packagesStage .resume .text-coverage .text-value {
    color: #161616;
    text-align: center;
}
.summary-plan{
    margin-top: 0px;
}
.summary-plan .title-card-text{
    background: linear-gradient(180deg, #0072C3 0%, #003A6E 100%);
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 16px;
    line-height: 20px; 
    letter-spacing: 0.32px;
}

.padtop-16{
    padding-top: 24px!important;
}

.add-coverage{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #525252;
    margin-bottom: 16px;
    margin-top:16px;
}

.plan {
    /* margin: 8px 0 32px 0; */
    /* border-left: 4px solid #F2F2F2;
    border-bottom: 4px solid #F2F2F2;
    border-right: 4px solid #F2F2F2; */
    padding: 16px;
    background: #fff;
    margin-top:-1px;
    border-radius: 0px 0px 4px 4px;
    border-radius: 16px;
}

.plan .text-coverage {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #0E5DA7;
    margin-left: 0px;
}

.recomendation {
    margin: 32px 0px 0px 0px;
}

.recomendation .text-coverage {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #003B71;
}

.recomendation .recomendation-lists {
    margin: 16px 0px 0px 0px;
}

.recomendation-content {
    margin-bottom: 8px;
}

.recomendation-content .title {
    display: flex;
}

.recomendation-content .title .unity {
    display: block;
    color: #6F6F6F;
}
.recomendation-content .title .unity .text {
    display: block;
}

.recomendation-content .title .activeAddonTxt h2 {
    color: #000000 !important;
    font-weight: 700;
}

.recomendation-content .title .activeAddonTxt .text {
    color: #000000 !important;
}
.recomendation-content .title .icon {
    margin-right: 8px;
}

.recomendation-content .title .icon i {
    color: #829FBF;
    line-height: 20px;
    font-size: 16px;
}

.recomendation-content .title .icon i.check-icon {
    color: #FF4713;
    line-height: 20px;
    font-size: 16px;
}

.recomendation-content .title h2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #6F6F6F;
}

.next-step .text-coverage {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #0E5DA7;
}

.next-step {
    margin: 0px;
}

.next-step .button-lst {
    margin-top: 16px;
}

.next2btn {
    color: #fff;
    border-radius: 8px;
    background-image: none;
    background: linear-gradient(180deg, #EF4A24 0%, #F26848 100%);
    padding: 12px 0px;
    height: 48px;
    margin: 6px auto;
    font-size: 16px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    transition: background 0.5s step-start;
}
.next2btn:hover {
    background: #C73E1E;
}
.btn-cancel{
    height: 40px;
    padding: 6px;
    width: 130px;
    border-radius: 8px;
    margin-bottom:0px;
    font-weight: 700;
    border: 0px solid #fff !important;
    transition: background 0.5s step-start;
    background: #FFEFEB;
}
.btn-cancel:hover{
    background: #FFE7E1;
}

.btn-confirm{
    height: 40px;
    padding: 6px;
    width: 130px;
    font-weight: 700;
    border-radius: 8px;
    margin-bottom:0px;
    background-color: linear-gradient(180deg, #EF4A24 0%, #F26848 100%);
    transition: background 0.5s step-start;
}
.btn-confirm:hover{
    background-color: #C73E1E;
}
.btn-confirm:disabled{
    color: #C6C6C6 !important;
    background: #F2F2F2 !important;
    border-color: #f2f2f2 !important;
    box-shadow: none !important;
}
/* @media (min-width: 768px) {
    
    .btn-cancel {
        width: 45%;
        margin-left:5px;
        margin-right:10px;
    }
    .btn-confirm{
        width: 50%;
    }
} */

.next2btn:disabled {
    border: 1px solid #ccc !important;
    color: #ccc !important;
    background-color: #fff !important;
    box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%) !important;
}

.email-me {
    color: #EF4A24;
    border-radius: 5px;
    height: 36px;
    margin: 6px 0;
    font-size: 14px;
    width: 100%;
    text-align: center;
    background: #FFEFEB;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px !important;
}
.email-me:hover {
    background: #FFE7E1;
}
.email-me span {
    margin-left: 4px;
}

.email-me .email {
    font-size: 32px;
    margin-top: -5px;
    color: #ff5011;
}

.call-me {
    color: #EF4A24;
    justify-content: center;
    border-radius: 8px;
    height: 36px;
    margin: 6px 0;
    font-size: 14px;
    width: 100%;
    text-align: center;
    background: #FFEFEB;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}
.call-me:hover {
    background: #FFE7E1;
}

@media (max-width: 480px) {
    .msg-sum{
    }
    .bg-grey{
        /* background: #fff;
        padding: 24px; */
        padding: 0 20px 16px 20px;
    }
    .summary-plan{
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media (max-width: 480px) {
    .button-lst .button-email{
        width:40%;
    }

    .button-lst .button-call{
        width: 60%;
        margin-left: 8px;
    }
}
.call-me span {
    margin-left: 4px;
}

/* .reward .reward-link {
    text-align: center;
    padding: 80px 0px 0px 0px;
} */
.link-reward.disabled{
    pointer-events: none;
    cursor: default;
    color: #C6C6C6 !important;
    text-decoration-color: #C6C6C6 !important;
}
.link-reward {
    color: #EF4A24;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: #EF4A24;
    text-decoration-thickness: 2.3px;
    text-underline-offset: 5px;
    cursor: pointer;
    font-weight:700;
    font-size:10px;
    margin-left:10px;
}

.link-voucher {
    color: #fff;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: #fff;
    text-decoration-thickness: 2.3px;
    text-underline-offset: 5px;
    cursor: pointer;
    font-weight:700;
    font-size:10px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
}

/* .btn-reward {
    color: #0E5DA7;
    margin: 0px auto;
    font-size: 14px;
    width: 75px;
    text-align: center;
    background: #EFF8FF;
    border: 1px solid #0E5DA7;
    border-radius: 18px;
    font-weight: 500;
    position: relative;
} */

.icon-wa::before {
    content: url('../../../public/images/motor-vehicle/WhatsApp.svg');
    display: inline-block;
    position: relative;
    top: 2px;
    display: inline-block;
}

.icon-phone::before {
    content: url('../../../public/images/motor-vehicle/Phone.svg');
    display: inline-block;
    position: relative;
    top: 2px;
    display: inline-block;
}

.inp {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
  border-radius: 3px;
  overflow: hidden;
}
.inp .label {
  position: absolute;
  top: 17px;
  left: 12px;
  font-size: 14px;
  color: #525252;
  font-weight: 500;
  transform-origin: 0 0;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
  pointer-events: none;
}
.inp .focus-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
}
.inp input {
  -webkit-appearance: none;
  -moz-appearance: none;
    appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 16px 12px 0 12px;
  height: 54px;
  font-size: 16px;
  font-weight: 400;
  background: #fff;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  color: #000;
  transition: all 0.15s ease;
}
.inp input:hover {
  background: #fff;
  border: 1px solid #000;
}
.inp input:not(:-moz-placeholder-shown) + .label {
  color: #00539A;
  font-size: 12px;
  font-weight: 700;
  top: 20px;
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:not(:-ms-input-placeholder) + .label {
  color: #00539A;
  font-size: 12px;
  font-weight: 700;
  top: 20px;
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:not(:placeholder-shown) + .label {
  color: #00539A;
  font-size: 12px;
  font-weight: 700;
  top: 20px;
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:focus {
  background: #fff;
  outline: none;
  border: 2px solid #0072C3;
}
.inp input.success{
    background-color: #E6F9EB;
    outline: none;
    border: 2px solid #0E6027;
    background-image: url('../../../public/images/motor-vehicle/checkForm.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: top 20px right 4px;
}

.inp input:focus + .label {
  color: #00539A;
  font-size: 12px;
  font-weight: 700;
  top: 20px;
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:focus + .label + .focus-bg {
  transform: scaleX(1);
  transition: all 0.1s ease;
}

.call-me-back {
    padding: 8px 16px 16px 16px;
}

.call-me-back h5 {
    text-align: center;
    margin-bottom: 16px;
}

.call-me-back .call-me-back-body {
    margin-bottom: 0;
}

.call-me-back .call-me-back-footer {
    display: flex;
    margin-bottom: 0px;
}

.call-me-back .call-me-back-footer button {
    margin-right: 8px;
}

.call-me-back .call-me-back-footer button:last-child {
    margin-right: 0px;
}

.call-me-back .text-muted {
    display: flex;
    padding-bottom: 16px;
    max-width: 245px;
}

.call-me-back .text-muted .glyphicons {
    margin-right: 5px;
}

.call-me-back-body .input-lg {
    text-align: center;
}
.call-me-back-body .input-lg::placeholder {
    font-size: 18px;
}

.modalWarnAcc{
    width: 320px;
    height: 270px;
    background: #FFFFFF;
    border-radius: 8px;
}

.text-warn-acc{
    text-align: center;
    color: #015CAF;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 0 15px;
}

.button-warn-acc{
    color: #fff;
    background: #FF4713;
    border-radius: 0px 0px 8px 8px;
    display: block;
    height: 55px;
    border-color: #FF4713;
    width: 100%;
    border: 0;
}

.warn-acc-button{
    padding-top: 42px;
}

.warn-acc{
    text-align: center;
    margin-top:1rem;
    margin-bottom:1rem;
}

.border-new-sum {
  max-width: 600px;
  margin: 0 auto;
  display: grid;
}

/* Screen larger than 600px? 2 column */
@media (min-width: 700px) {
  .border-new-sum { grid-template-columns: repeat(2, 1fr); }
}
@media (max-width: 600px) {
  .bg-sum-text{
    border-width: 2px 2px 1px 2px !important;
    border-radius: 8px 16px 0px 0px !important;
  }
  .bg-sum-value{
    border-radius: 0px 0px 8px 16px !important;
    border-width: 1px 2px 2px 2px !important
  }
}


.new-sum{
    background:#F7F7F7;
    text-align:center;
    padding: 8px 16px;
    border-radius: 8px;
    margin-bottom: 16px;
}

.text-sum{
    text-align: center;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 8px;
    color: #00539A;
}

.text-value{
    text-align: center;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0px;
    color: #003A6E;
}

.text-value span{
    color: #393939;
    text-align: center;
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}
.bg-sum-text{
    height:60px;
    background: #FFF1EE;
    border: solid #FF4713;
    border-width: 2px 1px 2px 2px;
    box-shadow: 0px 0px 20px 0px #0000001F;
    border-radius: 8px 0px 0px 16px;
}
.bg-sum-value{
    height:60px;
    border: solid #FF4713;
    box-shadow: 0px 0px 20px 0px #0000001F;
    border-width: 2px 2px 2px 1px;
    background: #FFFFFF;
    border-radius: 0px 16px 8px 0px;
}
.modalReward {
    margin: 16px;
}
.modalReward .roojai-rewards h1{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #424242;
}   
.modalReward .roojai-rewards .text-reward{
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    margin-bottom: 16px;
    max-width: 245px;
}    
.modalReward .roojai-rewards .reward-option {
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalReward .roojai-rewards .reward-option .tokopedia-reward {
    background: #41AC47;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    /* margin-right: 20px; */
    cursor: pointer;
}
.modalReward .roojai-rewards .reward-option .tokopedia-reward img {
    padding: 20px 10px;
    width: 128px;
    height: 58px;
}
.modalReward .roojai-rewards .reward-option .pertamina-reward {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;
}
.modalReward .roojai-rewards .reward-option .pertamina-reward img {
    padding: 10px 10px;
    width: 128px;
    height: 58px;
}
.modalReward .roojai-rewards .text-img-reward{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #525252;
    margin-bottom: 16px;
}
.modalReward .roojai-rewards .reward-button {
    margin-top: 16px;
}
.thankyou-button{
    margin-top: 16px;
}
 .thankyou-button button {
    background: linear-gradient(180deg, #EF4A24 0%, #F26848 100%);
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    width: 100%;
    border: 0;
    padding: 13px;
    transition: background 0.5s step-start;
}
.thankyou-button button:hover {
    background: #C73E1E;
}
.modalReward .roojai-rewards .reward-button button {
    background: linear-gradient(180deg, #EF4A24 0%, #F26848 100%);
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    width: 100%;
    border: 0;
    padding: 13px;
    transition: background 0.5s step-start;
}
.modalReward .roojai-rewards .reward-button button:hover {
    background: #C73E1E;
}
.infoCard .content-right {
    width: 70%;
    min-height: 30px;
}
.infoCard .content-left {
    width: 20%;
}

.optionForDealerWs {
    /* margin: 16px 0px; */
    line-height: 18px;
    text-align: center;
    color: #6f6f6f;
    font-weight: 400;
    font-size: 12px;
}
.per-sym {
    font-size: 28px;
    margin-left: -1px;
}
.price-sum{
    font-size: 40px;
    font-weight: 800 !important;
}
.paid-frequency {
    font-size: 16px;
    margin-left: 0px;
    color: #8D8D8D;
}
.currency-insured{
    font-size: 14px !important;
    font-weight: 500;
    color: #8D8D8D !important;
    font-family: 'Noto Sans';
}
.currency {
    font-size: 16px !important;
    color: #8D8D8D !important;
}
@media screen and (max-width: 480px) {
    .paid-yearly {
        margin-left: -12px!important;
    }
    .garageLists .col {
        padding-right: 5px;
        padding-left: 5px;
    }

    .price-sum{
        font-size: 32px;
    }

    .packagesStage .coverage .text-coverage{
        font-size: 16px;
    }
    .packagesStage .coverage .cover-list ul li span{
        font-size: 14px;
    }
    .packagesStage .plan .text-quest{
        font-size: 16px;
    }
    .packagesStage .plan .garage-text span{
        font-size: 12px;
        max-width: 200px;
    }
    .packagesStage .plan .garage-text a{
        font-size: 12px;
    }
    .packagesStage .package-checklist .checklist-area .descr{
        font-size: 14px;
    }
    .packagesStage .plan .cover-list ul li span {
        font-size: 14px;
    }
    .packagesStage .plan .text-coverage{
        font-size: 12px;        
    }
    .plan .text-coverage,
    .packagesStage .not-cover .cover-list ul li span{
        font-size: 14px;
    }
    /* .packagesStage .not-cover .text-coverage {
        font-size: 16px;
    } */

    .text {
        font-size: 12px;
    }

    .packagesStage .package-checklist .checklist-area .title {
        width: 85%;
    }
}

.whenLoading {
    height: 60vh;
}

.rewardPackage {
    margin-bottom: 24px;
    padding: 0px 72px 0px 72px;
}

.bottom-spacer {
    margin-bottom: 16px;
}
.greyContainer {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    background: #F7F7F7;
    width: 100%;
}
.greyContainer .titlePkg {
    display: flex;
    align-items: center;
    gap: 8px;
}
.greyContainer .titlePkg .title{
    color: #000;
    font-family: Public Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.32px;
}
.descPkg {
    color: #6F6F6F;
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    margin-top: 8px;
}
.text-coverage {
    color: #6F6F6F;
    font-family: Public Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; 
    letter-spacing: 0.24px;
    text-transform: capitalize;
}
.button-center{
    display: flex; 
    justify-content: center; 
    align-items: center;
    padding-top: 16px;
}
.button-center span{
    width: 8px;
}
.addbutton{
    display: flex;
    padding: 8px 8px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 8px;
    flex: 1 0 0;
    flex-wrap: wrap;
    color: #FFF;
    text-align: center;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; 
    letter-spacing: 0.28px;
    border-radius: 8px;
    background: linear-gradient(180deg, #EF4A24 0%, #F26848 100%);
    cursor: pointer;
    /* margin: 0 16px 16px 16px; */
}
.addbutton:hover{
    background: #C73E1E;
}
.cancelbutton{
    display: flex;
    padding: 8px 8px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 8px;
    flex: 1 0 0;
    flex-wrap: wrap;
    color: #EF4A24;
    text-align: center;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; 
    letter-spacing: 0.28px;
    border-radius: 8px;
    background: #FFEFEB;
    cursor: pointer;
    /* margin: 0 16px 16px 16px; */
}
.cancelbutton:hover{
    background: #FFE7E1;
}
.invalidInput {
    color: #DA1E28;
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
    letter-spacing: 0.2px;
    margin: 16px 0 0 0;
    display: flex;
    padding: 8px 16px;
    gap: 12px;
    border-radius: 4px;
    border-top: 0.5px solid #FF8389;
    border-right: 0.5px solid #FF8389;
    border-bottom: 2px solid #FF8389;
    border-left: 0.5px solid #FF8389;
    background: #FFF1F1;
    display: flex;
    align-items: center;
}

.summary-new-container {
    border-radius: 0px;
    border-right: 4px solid #F7F7F7;
    border-bottom: 4px solid #F7F7F7;
    border-left: 4px solid #F7F7F7;
}
.successtips {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.7) !important;
    color:  #FFF;
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
}

</style>