<template>
<div 
    class="addOnCoverage" 
    :class="className, {coverageSelected: selected}"
>
    <div class="head" @click="pickAddOn">
        <div 
            class="picker" 
            :class="{active: selected}"
            v-if="selected"
        >
            <i class="check-icon"></i>
        </div>
        <div 
            v-else
            class="picker"
        >
        </div>
        <div class="title d-flex flex-column flex-grow-1">
            <h4 :class="{activeTxt: selected}">{{ title }}</h4>
            <span>{{ desc }}</span>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: "AddOnCoverage",
    props: {
        className: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
        button: {
            type: String,
            default: ""
        },
        desc: {
            type: String,
            default: ""
        },
        isSelected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selected: false,
            open: false,
        }
    },
    mounted() {
        if(this.isSelected) {
            this.selected = this.isSelected;
        }
    },
    computed:{
        BtnTxtCoverage(){
            if (!this.open) return this.$i18n.t('motorVehicle.openbtnBenefitDetails');
            else return this.$i18n.t('motorVehicle.closebtnBenefitDetails');
        }
    },
    watch: {
        isSelected: function(value) {
            this.selected = value
        }
    },
    methods: {
        pickAddOn() {
            this.selected = !this.selected;
            if (this.selected) {
                this.open = true; 
                this.$emit('openModal', this.selected);
            } else {
                this.open = false;
                this.$emit('openModal', this.selected);
            }
            this.$emit("action", this.selected);
        },
        openDetail() {
            this.open = !this.open;
            if(this.callModal) {
                this.selected = true
                this.$emit('openModal', this.selected);
            }
        },
        defaultSelected(){
            this.selected = true;
        },
        unselected() {
            this.selected = false;
        },
        numberFormat(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    }
}
</script>
<style scoped>
.addOnCoverage {
    border-radius: 8px;
    padding: 24px 16px;
    display: flex;
    flex-grow: 1;
    width: 100%;
    border-bottom: 2px solid #F7F7F7;
}
.addOnCoverage :last-child {
    margin-bottom: 0px;
}
.addOnCoverage .head {
    display: flex;
}
.addOnCoverage .head .title {
    max-width: 350px;
    cursor: pointer;
}
.addOnCoverage .head .picker {
    background-image: url("../../../../public/images/motor-vehicle/checkGrey.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: top 5px left 4px;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    border: 1px solid #c6c6c6;
    margin-right: 16px;
    transition: 0.3s;
    cursor: pointer;
}

.addOnCoverage .head .picker:hover {
    border: 2px solid #EF4A24;
}
/* .addOnCoverage .head .picker i{
    padding: 3px 5px;
    position: absolute;
    top: -2px;
    color: #fff;
} */
.addOnCoverage .head .title h4{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #525252;
    margin-bottom: 4px;
}
.addOnCoverage .head .title span{
    color: #6F6F6F;
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.addOnCoverage .head .title .price{
    font-weight: 700;
    background: #F2F2F2;
    padding: 4px 2px;
    font-size: 12px;
    line-height: 24px;
    color: #000000;
}
.addOnCoverage .head .title span {
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: #8D8D8D;
}
.addOnCoverage .content {
    margin: 10px 0px;
    transform: rotateY(45deg);
}
.addOnCoverage .open {
    height: 100%;
    /* transform-origin: top; */
    transition: all 0.5s ease-out;
    transform: rotateY(0deg);
}
.addOnCoverage .footer-cover {
    display: flex;
    justify-content: flex-end;
}
.check-icon {
    background-image: url("../../../../public/images/motor-vehicle/checkWhite.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: top 0px left 13px;
    width: 24px;
    height: 24px;
    /* border-radius: 8px; */
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    /* border: 1px solid #E8E8E8; */
    margin-right: 16px;
    transition: 0.3s;
}
.activeTxt {
    color: #000000 !important;
}
.active {
    border-color: #FF4713 !important;
    background-color: #FF4713!important;
}
.link-coverage {
    color: #0072C3;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    -webkit-text-decoration-style: dotted;
    text-decoration-style: dotted;
    -webkit-text-decoration-color: #0072C3;
    text-decoration-color: #0072C3;
    text-decoration-thickness: 2.3px;
    text-underline-offset: 5px;
    cursor: pointer;
    font-size: 10px;
    font-weight: 700;
}

.link-coverage svg{
    background: #F2F2F2;
    height: 16px;
    width: 16px;
    border-radius: 2px 6px;
    display: inline-flex;
    padding: 4px;
    margin-top: 2px;
    margin-left: 6px;
    border-radius: 2px 6px;
}
.link-coverage svg.rotate{
    transform: rotate(0deg);
}
.link-coverage:hover, .link-coverage:active, .link-coverage:focus{
    background: #E5F6FF;
    color: #00539A;
}
.riot-coverage {
    background-image: url('../../../../public/images/motor-vehicle/RiotCoverage.svg');
    background-position: top 16px right 16px;
    background-repeat: no-repeat;
}
.natural-disasters-coverage {
    background-image: url('../../../../public/images/motor-vehicle/NaturalDisasters.svg');
    background-position: top 16px right 16px;
    background-repeat: no-repeat;
}
.roadside-assistance-coverage {
    background-image: url('../../../../public/images/motor-vehicle/RoadSide.svg');
    background-position: top 16px right 16px;
    background-repeat: no-repeat;
}
.transportation-cash-coverage {
    background-image: url('../../../../public/images/motor-vehicle/TransportationCash.svg');
    background-position: top 16px right 16px;
    background-repeat: no-repeat;
}
.accessories-coverage {
    background-image: url('../../../../public/images/motor-vehicle/AccessoriesGuarantee.svg');
    background-position: top 16px right 16px;
    background-repeat: no-repeat;
}


@media screen and (max-width: 480px) {
    .addOnCoverage .head .title h4{
        font-size: 16px;
    } 
    .addOnCoverage .head .title .price {
        font-size: 12px;
    }
    .addOnCoverage .head .title {
        max-width: 185px;
    }
    .btn-coverage {
        font-size: 12px;
    }
}

/* @media screen and (max-width: 360px) {
    .addOnCoverage .head .picker {
        width: 22px;
        height: 22px;
        margin-right: 8px;
    }

    .addOnCoverage .head .title h4 {
        font-size: 13px;
        line-height: 18px;

    }
    .addOnCoverage .head .picker i {
        top: -4px;
        right: -3px;
    }
} */

</style>