<template>
  <div>
    <div class="floating-continue">
      <div class="scroll-bg-floating">
        <svg
          class="animated2fast bounceDown"
          style="margin-top:0px"
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.6711 6L9.0001 10.6667L4.3291 6"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="floating-wrap">
        <div class="button-area">
          <div class="continue-button">
            <button class="btn btn-block next2btn" @click="next">
              {{ $t("motorVehicle.next") }}
            </button>
          </div>
          <div class="call-label">{{ $t("motorVehicle.labelEmailMe") }}</div>
          <div class="call-button">
            <div class="btn-call">
              <button class="btn call-me-btn" @click="sendEmail">
                <img
                  src="../../../../public/images/motor-vehicle/Email.svg"
                  alt=""
                />
                <span class="email-text">{{
                  $t("motorVehicle.btnEmailQuote")
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";

export default {
  name: "FloatingContinueAgent",
  mixins: [Mixin],
  data() {
    return {
      Global
    };
  },
  methods: {
    next() {
      Global.motorVehicle.flagRetrievePolicy = false;
      this.$router.push({ name: "mv-policydetails" });
    },
    sendEmail() {
      this.$emit("sendEmail");
    }
  }
};
</script>
<style scoped>
.floating-continue {
  position: fixed;
  bottom: 0;
  z-index: 999;
  margin: 0 auto;
  width: 100%;
  height: auto;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.floating-continue .floating-wrap {
  width: 600px;
  margin: 0 auto 0px;
  padding: 20px 30px 15px;
  min-height: 92px;
  filter: drop-shadow(0px 2px 42px rgba(0, 58, 109, 0.48));
  background-image: linear-gradient(180deg, #015caf, #015caf 75%, #003b71);
}
.scroll-bg-floating {
  z-index: 100;
  width: 600px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  height: 28px;
  position: absolute;
  bottom: 137px;
  background: linear-gradient(0deg, #101f3f 0%, rgba(0, 58, 110, 0) 100%);
}
.floating-continue .floating-wrap .button-area .call-button {
  display: flex;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 0px;
}
.floating-continue .floating-wrap .button-area .call-button .btn-call {
  width: 30%;
  margin-left: 0px;
  margin-right: 4px;
}

.floating-continue .floating-wrap .button-area .call-label {
  margin-top: 4px;
  color: #fff;
  display: flex;
  flex-direction: row;
}
.floating-continue .floating-wrap .button-area .call-label:before,
.floating-continue .floating-wrap .button-area .call-label:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
.floating-continue .floating-wrap .button-area .call-label:before {
  margin-right: 10px;
}
.floating-continue .floating-wrap .button-area .call-label:after {
  margin-left: 10px;
}

.floating-continue
  .floating-wrap
  .button-area
  .call-button
  .btn-call:last-child {
  width: 100%;
}
.next2btn {
  color: #fff;
  border-radius: 8px;
  height: 48px;
  background-image: none;
  background: linear-gradient(180deg, #ef4a24 0%, #f26848 100%);
  padding: 7px 12px;
  margin: 0px auto;
  box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
  font-size: 16px;
  width: 100%;
  text-align: center;
  font-weight: 800;
  font-family: "Public Sans";
  transition: background 0.5s step-start;
}
.next2btn:hover {
  background: #c73e1e;
}
.next2btn:disabled {
  border: 1px solid #ccc !important;
  color: #ccc !important;
  background-color: #fff !important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%) !important;
}
.call-me-btn {
  color: #ff4713;
  border-radius: 8px;
  background-image: none;
  background-color: #ffefeb;
  padding: 0px;
  margin: 4px auto 0px auto;
  /* border-color: #fff; */
  font-size: 16px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.call-me-btn:hover {
  background-color: #ffe7e1;
}
.call-me-btn img {
  align-self: center;
}
.call-me-btn .email-text {
  line-height: 21px;
  margin-left: 4px;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@media screen and (max-width: 360px) {
  .call-me-btn .email-text {
    line-height: 16px !important;
  }
}

@media (min-width: 361) and (max-width: 411px) {
  .call-me-btn .email-text {
    line-height: 14px !important;
  }
}
@media screen and (min-width: 481) and (max-width: 600px) {
  .floating-continue .floating-wrap {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .floating-continue .floating-wrap {
    width: 100%;
  }
  .call-me-btn {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .floating-continue .floating-wrap .button-area .call-button .btn-call {
    width: 35%;
    margin-left: 0px;
    margin-right: 4px;
  }
  .next2btn {
    padding: 8px 21px;
  }
  .scroll-bg-floating {
    width: 100% !important;
    bottom: 100px !important;
  }
  .floating-continue .floating-wrap {
    padding: 12px 24px;
  }

  .call-me-btn .email {
    font-size: 29px;
    margin-top: -9px;
    color: #ff5011;
  }

  .call-me-btn .email-text {
    line-height: 15px;
  }
}
</style>
