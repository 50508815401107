<template>
    <div class="reward">
        <!-- <div class="reward-icon">
            <img src="../../../../public/images/motor-vehicle/reward-icon.svg" />
        </div>
        <div class="reward-text">
            <h1>{{ $t('motorVehicle.rewardTitle', { pointReward: pointReward }) }}</h1>
            <div class="text">{{ $t('motorVehicle.rewardValue', { valueReward: valueReward }) }}</div>
        </div>
        <div class="reward-button">
            <button class="btn-reward" @click="buttonAction">{{ $t('motorVehicle.rewardBtn') }}</button>
        </div> -->
        <div class="justify-content-start">
            <div class="reward-text d-flex flex-column bd-highlight">
                <div class="text"> <img src="../../../../public/images/motor-vehicle/Point.svg" width="20" height="20" style="margin-right: 8px;"/>
                    {{ $t('motorVehicle.Rewardmsg') }}
                </div>
                <div class="d-flex" style="margin-top: 12px;margin-bottom: 4px;">
                    <h1 class="value">{{ pointReward }}</h1> 
                    <span class="value-text">{{ $t('motorVehicle.poin') }} 
                        <img v-if="pointReward == 0" @click="infoReward()" style="cursor: pointer;margin-left: 4px;margin-top: -3px;" src="../../../../public/images/motor-vehicle/Info-Sum.svg" />
                        <svg v-else style="margin-left: 4px;margin-top: -3px;" xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                            <path d="M7.5 10.5L9.16667 12.1667L12.5 8.83333M17.5 10.5C17.5 14.6421 14.1421 18 10 18C5.85786 18 2.5 14.6421 2.5 10.5C2.5 6.35786 5.85786 3 10 3C14.1421 3 17.5 6.35786 17.5 10.5Z" stroke="#24A148" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span> 
                </div>
                <div class="text">
                    {{ $t('motorVehicle.rewardValue', { valueReward: valueReward }) }}
                    <a class="link" :class="{disabled : Number(pointReward.split('.').join('')) === 0 }" @click="buttonAction">{{ $t('motorVehicle.rewardBtn') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Reward",
    props: {
        pointReward: {
            tyrpe: String,
            default: ""
        },
        valueReward: {
            tyrpe: String,
            default: ""
        }
    },
    data() {
        return {

        }
    },
    methods: {
        buttonAction() {
            if(Number(this.pointReward.split('.').join('')) > 0){
                this.$emit('action');
            }
        },
        infoReward(){
            this.$emit('openInfoReward');
        }
    }

}
</script>
<style scoped>
.reward {
    /* display: flex; */
    width: 100%;
    /* border-top: 1px solid #eeceb0;
    border-bottom: 1px solid #eeceb0;
    border-radius: 4px; */
    height: 88px;
    position: relative;
}
.reward .reward-icon {
    width: 12%;
    padding: 18px 9px 15px 25px;
}
.reward .reward-icon:before {
    content: "";
    display: block;
    width: 10px;
    height: 87px;
    background-image: url('../../../../public/images/motor-vehicle/reward-left.svg');
    background-position: center center;
    position: absolute;
    top: 0px;
    left: 0px;
}
.reward .reward-icon img {
    width: 46px;
    height: 46.12px;
}
.reward .reward-text {
    color: #8D8D8D;
    font-size: 12px;
    margin-top: 4px;
    align-self: stretch;
    margin-left: 0px;
    font-weight: 500;
}
.reward .reward-text h1 {
    color: #8D8D8D;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}
.reward .reward-text .d-flex .value {
    font-weight: 700;
    font-size: 24px;
    /* line-height: 32px; */
    color: #003A6E;
    align-self: stretch;
}

.reward .reward-text .d-flex .value-text {
    font-weight: 500;
    font-size: 14px;
    /* line-height: 24px; */
    /* padding-top: 4px; */
    padding-left: 4px;
    align-self: stretch;
    color: #003A6E;
}
.reward .reward-text .d-flex .value-text img path{
    stroke: #24A148;
}
.reward .reward-text .text {
    color: #8D8D8D;
    font-size: 12px;
    margin-top: 4px;
    align-self: stretch;
    margin-left: 0px;
    font-weight: 400;
}
.reward .reward-button {
    width: 35%;
    padding: 15px;
}
.reward .reward-button:before {
    content: "";
    display: block;
    width: 10px;
    height: 87px;
    background-image: url('../../../../public/images/motor-vehicle/reward-right.svg');
    background-position: center center;
    position: absolute;
    top: 0px;
    right: 0px;
}
.reward .reward-button button {
    margin-top: 8px;
}
.reward .reward-link {
    text-align: center;
    padding: 80px 0px 0px 0px;
}
.reward .link {
    float: right;
    color: #EF4A24;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: #EF4A24;
    text-decoration-thickness: 2.3px;
    text-underline-offset: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
}
.disabled{
    color: #ccc !important;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: #ccc !important;
}
.btn-reward {
    border: 1px solid #FF4713;
    border-radius: 4px;
    background-color: #fff;
    color: #FF4713;
    font-size: 12px;
    padding: 9px 15px;
}
@media screen and (max-width: 480px) {
    .reward .reward-text h1 {
        color: #8D8D8D;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
    }
    .reward .reward-text .text {
        font-size: 12px;
    }
    .reward .reward-icon {
        width: 12%;
        padding: 15px 9px 15px 12px;
    }
    .reward .reward-button button {
        margin-top: -8px;
    }
    /* .reward .reward-text {
        text-align: left;
        width: 53%;
        padding: 0px 12px;
        margin: 6px 10px;
    } */

    @media screen and (max-width: 480px) {
        .reward .reward-text h1 {
            font-size: 12px;
            line-height: 18px;
        }
        /* .reward .reward-text .text {
            margin-top: -4px;
        } */
    }
}
</style>