<template>
    <div 
        class="packageItem d-flex" 
        :class="{active: active, disabled: disable}" 
        @click="selectPackage"
    >
        <div class="package" :style="styleTitle">
            <span v-if="numberType">
                <div class="cov-label" v-if="currencySymbol">{{ $t('motorVehicle.coverage') }}</div>
                <span v-if="currencySymbol">{{ $t('motorVehicle.currencySymbol') }}</span> {{ numberFormat(text) }}
            </span>
            <span v-else>{{ text }}</span>
        </div>
        <div class="price">
            <div class="icon" v-if="active">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24.0186" height="24" rx="12" fill="#EF4A24"/>
                    <path d="M6.1709 12.8334L9.50733 16.1667L17.8484 7.83337" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="icon" v-if="!active && !numberType" style="text-align: center; display: flex;justify-content: center;">
                <div class="picker" style=""></div>
            </div>
            <div class="subtitle" v-if="!active && subtitle.length > 0">
                <div class="cov-label" v-if="currencySymbol">
                    <span v-if="!isMinus">{{ $t('motorVehicle.addPremi') }}</span>
                    <span v-else>{{ $t('motorVehicle.minusPremi') }}</span>
                </div>
                <span class="prem" v-if="Number(subtitle) < 0">
                    <span> {{ handleSign(isMinus) }} </span>
                    {{ $t('motorVehicle.currencySymbol') }} {{ numberFormat(reformat(Math.ceil(Number(subtitle)))) }}
                </span>
                <span class="prem" v-if="Number(subtitle) != 0 && Number(subtitle) > 0">
                    <span> {{ handleSign(isMinus) }} </span>
                    {{ $t('motorVehicle.currencySymbol') }} {{ numberFormat(reformat(Math.floor(Number(subtitle)))) }}
                </span>
            </div>
        </div>
    </div>
    </template>
    <script>
    export default {
        name: "PackageItem",
        data() {
            return {
                isMinus: false,
            }
        },
        props: {
            active: {
                type: Boolean,
                default: false
            },
            disable:{
                type: Boolean,
                default: false
            },
            text: {
                type: [String, Number],
                default: ""
            },
            subtitle: {
                type: [String, Number],
                default: ""
            },
            styleTitle: {
                type: String,
                default: ""
            },
            numberType: {
                type: Boolean,
                default: false
            },
            currencySymbol: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            selectPackage() {
                if (this.disable) {
                    return false
                }else {
                    this.$emit("action");
                }
            },
            numberFormat(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            millionFormat(x){
                // var x = 200000000
                // console.log(Math.abs(x) > 999999 ? Math.sign(x)*((Math.abs(x)/1000000).toFixed(1)) + 'k' : Math.sign(x)*Math.abs(x));
                // Math.abs(x) > 999 ? Math.sign(x)*((Math.abs(x)/1000).toFixed(1)) + 'k' : Math.sign(x)*Math.abs(x)
                // var z = x.toString().replace(/(.*)(?=\w{6})/gm)
                return (Math.abs(x) > 999999 ? Math.sign(x)*((Math.abs(x)/1000000).toFixed(1)) : x);
            },
            reformat(value) {
                if (value < 0) {
                    this.isMinus = true;
                    return Math.abs(value);
                }
                this.isMinus = false;
                return value;
            },
            handleSign(value) {
                if (value) return "-";
                else if (this.subtitle == 0) return "";
                else return "+";
            }
        }
    
    }
    </script>
    <style scoped>
.packageItem {
        background-color: #fff;
        padding: 8px 16px;
        border-radius: 8px;
        height: 60px;
        min-width: 112px;
        cursor: pointer;
        border: 1px solid #E3E3E3;
        /* margin-bottom: 10px; */
        transition: 0.3s;
}

@media screen and (min-width: 480px) {
    .packageItem:hover {
        border: 1px solid #161616;
    }
    
}

.packageItem .subtitle {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #000;
}

.packageItem .subtitle .prem {
    background: #F7F7F7;
    padding: 2px 4px 2px 4px;
    font-weight: 700;
    border-radius: 4px;
    color: #A8A8A8;
    font-family: Public Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; 
    letter-spacing: 0.2px;
}


.packageItem .price {
    text-align: center;
    align-self: center;
}
.packageItem .price .icon .picker{
    background: #fff;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    position: relative;
    border: 1px solid #E8E8E8;
    transition: 0.3s;
}
.packageItem .price .icon .picker:hover{
    border: 2px solid #EF4A24;
}


.packageItem .package{
    font-weight: 700;
    margin-right: auto;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #161616;
    font-family: Public Sans;
    letter-spacing: 0.28px;
    display: flex;
    align-items: center;
}
.active {
    background-color: #00539A!important;
    border: 4px solid #1584CF!important;
    padding: 4px 12px;
}
.active .package {
    color: #FFFFFF!important;
}
.disabled {
    background-color: #F4F4F4!important;
    border: 1px solid #F4F4F4!important;
    cursor: default !important;
}
.disabled .package {
    color: #ACB6C9!important;
}
.active .icon {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    margin-top: 4px;
    margin-right: 14px;
}
.cov-label {
    font-size: 10px;
    font-weight: 500;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
} 
@media screen and (max-width: 480px) {
    /* .packageItem {
        padding: 0px;
    } */
    /* .packageItem .package {
        line-height: 18px;
        padding: 0 4px;
        font-size: 10px;        
    } */
    
}
    </style>