<template>
<div class="planPackages">
    <div class="carousel">
        <div class="carousel-type">
            <div class="inner" >
                <div :class="this.callFromModal ? 'slide-area scroll-'+this.coverageOption+'-modal' : 'slide-area scroll-'+this.coverageOption">
                    <PackageItem 
                        v-for="(item, index) in optionItem" :key="index"
                        :text="String((item == 'Not covered' ? this.notCoverTxt : item))"
                        :active="item == defaultSelected"
                        @action="selectPackageItem(item,this.coverageOption,index)"
                        :class="'item-pack'+' ' + slideClassName"
                        style-title="padding: 16px 0px 0px 1px"
                        :subtitle="String(this.slideData[item])"
                        :numberType="item != 'Not covered'"
                        :currencySymbol="item != 'Not covered'"
                    />
                    {{ item }}
                </div>

                <div class="arrow-new">
                    <div class="prev-new" @click="prev(this.coverageOption)" :id="this.callFromModal ? 'scrollKiri'+this.coverageOption+'-modal' : 'scrollKiri'+this.coverageOption" :class="'transparant'" >
                        <svg height="12" viewBox="0 0 24 24" role="img" style="transform: rotate(90deg);"><path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path></svg>
                    </div>
                    <div class="next-new" @click="next(this.coverageOption)" :id="this.callFromModal ? 'scrollKanan'+this.coverageOption+'-modal' : 'scrollKanan'+this.coverageOption"  :class="'active'">
                        <svg height="12" viewBox="0 0 24 24" role="img" style="transform: rotate(270deg);"><path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path></svg>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>
</template>
<script>
import PackageItem from './PackageItem.vue';

export default {
    name: "PlanPackages",
    props: {
        packages: {
            type: Object,
        },
        paymentType: {
            type: String,
            default: ""
        },
        defaultSelected: {
            type: String,
            default: null
        },
        language: {
            type: String,
            default: "id"
        },
        coverageOption: {
            type: String,
            default: null
        },
        callFromModal: {
            type: Boolean,
            default: false
        },
        workshop:{
            type: String
        },
    },
    data() {
        return {
            innerStyles: {},
            step: "",
            slideData:{},
            from: 0,
            to: 4,
            lengthSlide: 0,
            counter: 1,
            slideClassName: "",
            width: 0,
            windowWidth: 0,
            paymentTriger: "",
            optionItem: [],
            optItems: [],
            startOfScroll: false,
            endOfScroll: false,
        }
    },
    components: { 
        PackageItem 
    },
    watch: {
        windowWidth: function(value) {
            if(value > 480) {
                this.to = 4;
            } else {
                this.to = 3;
            }
        },
        paymentType: function(value) {
            if(value !== this.paymentTriger) {
                this.paymentTriger = value;
            }
        },
        packages: function(value) {
            this.getItems();
        },
        workshop: function(value){
            this.getItems();
        }
    },
    updated(){
        this.checkScroll(this.coverageOption);
    }, 
    created(){
        this.getItems();
    }, 
    mounted () {
    
        this.lengthSlide = this.to === 3 ? Math.floor(this.optionItem.length - 3) + 1 : Math.floor(this.optionItem.length - 4) + 1;

        this.windowWidth = window.innerWidth;
        window.addEventListener('resize', () => (this.windowWidth = window.innerWidth));
        this.paymentTriger = this.paymentType;
    },
    methods: {
        checkScroll(coverage){
            var vm = this;
            let selector = this.callFromModal ? '.scroll-'+coverage+'-modal' : '.scroll-'+coverage
            $(selector).scroll( function() {
                var buttonScrollKiri = vm.callFromModal ? $('#scrollKiri'+coverage+'-modal') : $('#scrollKiri'+coverage);
                var buttonScrollKanan = vm.callFromModal ? $('#scrollKanan'+coverage+'-modal') : $('#scrollKanan'+coverage);
                var $width = $(selector).outerWidth()
                var $scrollWidth = $(selector)[0].scrollWidth; 
                var $scrollLeft = $(selector).scrollLeft();
                if ($scrollWidth - $width === $scrollLeft){
                    buttonScrollKanan.removeClass("active")
                    buttonScrollKanan.addClass("transparant")
                } else {
                    buttonScrollKanan.removeClass("transparant")
                    buttonScrollKanan.addClass("active")
                }
                if ($scrollLeft===0){
                    buttonScrollKiri.removeClass("active")
                    buttonScrollKiri.addClass("transparant")
                } else {
                    buttonScrollKiri.removeClass("transparant")
                    buttonScrollKiri.addClass("active")
                }
            });
        },
        getItems(){
            if(this.callFromModal){
                this.slideData = this.packages[this.coverageOption];
                delete this.slideData['Not covered']
            } else {
                this.slideData = this.packages[this.coverageOption];
                delete this.slideData[""];
                this.slideData['Not covered'] = "0";   
            }
            this.sortItems();
        },

        selectPackageItem(value,coverage,index) {
            this.$emit('selected', String(value));
            let selector = this.callFromModal ? '.scroll-'+coverage+'-modal' : '.scroll-'+coverage
            $(selector).each(function(){
                var scrollBoxWidth = $(this).width();
                var wrapperWidth = ($(this).find(".item-pack").length * 112) + (($(this).find(".item-pack").length -1) * 10);
                var x = 112 * index;
                if( wrapperWidth > scrollBoxWidth ){
                    $(this).animate({scrollLeft: x}, 500);
                }
            });
            this.checkScroll(coverage)
        },

        prev(coverage) {
            let selector = this.callFromModal ? '.scroll-'+coverage+'-modal' : '.scroll-'+coverage
            let content = document.querySelector(selector);
            let scrollAmount = 0;
            let slideTimer = setInterval(function(){
                content.scrollLeft -= 10;
                scrollAmount += 10;
                if(scrollAmount >= 100){
                    window.clearInterval(slideTimer);
                }
            }, 25);
            this.checkScroll(coverage)
        },
        next(coverage) {
            let selector = this.callFromModal ? '.scroll-'+coverage+'-modal' : '.scroll-'+coverage
            let content = document.querySelector(selector);
            let scrollAmount = 0;
            let slideTimer = setInterval(function(){
                content.scrollLeft += 10;
                scrollAmount += 10;
                if(scrollAmount >= 100){
                    window.clearInterval(slideTimer);
                }
            }, 25);
            this.checkScroll(coverage)
        },

        sortItems() {   
            this.optItems = Object.keys(this.slideData).sort((a, b) => {
                if (a === "Not covered" && !this.callFromModal) {
                    return -1;
                } else {
                    return parseInt(a) - parseInt(b);
                }
            });
            if(this.callFromModal){
                delete this.optItems['Not covered']
            } 
            this.optionItem = this.optItems
        },
    },
    computed: {
        notCoverTxt() {
            return this.$i18n.t('motorVehicle.notCoverTxt');
        }

    }
}
</script>
<style scoped>
.carousel {
    width: 100%;
}
.slide-area{
    overflow-x: auto;
    overflow-y: hidden;
    display: inline-flex;
    width: 100%;
    padding-bottom: 16px;
}


.slide-area::-webkit-scrollbar {
    height: 4px;
}
.slide-area::-webkit-scrollbar-track {
    margin-right: 92px;
    background: #E8E8E8;
    -webkit-border-radius: 12px;
    border-radius: 12px;
}
.slide-area::-webkit-scrollbar-thumb {
  -webkit-border-radius: 12px;
  border-radius: 12px;
  background: #F4866D;
}
.slide-area::-webkit-scrollbar-thumb:hover {
  background: #EF4A24;
}
.slide-area::-webkit-scrollbar-thumb:window-inactive {
  background: #F4866D;
}


/* * {
    scrollbar-width: thin;
    scrollbar-color: #CED6E0 #F1F2F6;
}

*::-webkit-scrollbar {
    height: 8px;
}

*::-webkit-scrollbar-track {
    background: #F2F2F2;
    border-radius: 12px;
    margin-left: 48px;
    margin-right: 48px;
}

*::-webkit-scrollbar-thumb {
    background-color: #00539A;
    border-radius: 12px;
    border: 3px solid #00539A;
} */

/* .slide-area {
    display: inline-flex;
    width: 100%;
    padding-bottom: 16px;
} */

.item-pack {
    width: 112px;
    margin-right: 8px;
}
.item-pack:last-child {
    margin-right: 0px;
}
/* optional */
button {
    margin-right: 5px;
    margin-top: 10px;
}
.indicator {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}
.indicator button {
    background:#C6DFF6;
    border: none;
    width: 20px;
}
button.active {
    background: #0071D9;
    border: none;
    width: 20px;
}
.arrow {
    position: relative;
}
.arrow .active {
    fill: #0071D9!important;
}
.prev {
    position: absolute;
    bottom: 2px;
    left: 0;
}
.next {
    position: absolute;
    bottom: 1px;
    right: 0;
}
.prev .chevron {
    fill: #C6DFF6;
    cursor: pointer;
}

.next .chevron {
    fill: #C6DFF6;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    cursor: pointer;
}

.carousel-type {
    display: block;
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.moreThan {
    width: 100%;
    margin: 0 auto;
}

.arrow-new {
    position: relative;
}
.arrow-new .active {
    fill: #EF4A24!important;
}
.transparant{
    fill: #F4866D!important;
    opacity: 0.5;
}
.prev-new {
    position: absolute;
    bottom: -14px;
    right: 44px;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    box-shadow: 0 1px 5px 0 #e3e3e3;
    -webkit-box-shadow: #e3e3e3 0 1px 1px 0!important;
    width: 36px;
    height: 36px;
    padding: 5px 10px;
    border-radius: 50%;
    cursor: pointer;
}
.next-new {
    position: absolute;
    bottom: -13px;
    right: 0px;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    box-shadow: 0 1px 5px 0 #e3e3e3;
    -webkit-box-shadow: #e3e3e3 0 1px 1px 0!important;
    width: 35px;
    height: 35px;
    padding: 5px 12px;
    border-radius: 50%;
    cursor: pointer;
}

@-webkit-keyframes slideInLeft {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes slideInLeft {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
} 

@-webkit-keyframes slideInRight {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes slideInRight {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
} 

@media screen and (max-width: 480px) {
    .packageItem {
        max-height: 175px;
    }
    .packageItem .package {
        /* padding: 20px 10px; */
        font-size: 10px!important;
    }
    .carousel {
        width: 100%!important;
        overflow: visible!important;
    }
    .packageItem .package {
        font-size: 10px;
    }

}
@media screen and (min-width: 480px) {
    .prev-new:hover {
        background: #EF4A24;
    }
    .next-new:hover {
        background: #EF4A24;
    }
}
@media screen and (min-width: 480px) {
    .prev-new:hover svg path {
        fill: #fff;
    }
    .next-new:hover svg path {
        fill: #fff;
    }
}
</style>