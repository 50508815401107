<script setup>
import { ref, watch } from "vue";
import CurrencyInput from "../CurrencyInput.vue";

const props = defineProps({
  min: Number,
  max: Number,
  value: Number,
  onChange: Function,
  label: String
});
const sliderValue = ref(props.value);

function onChange(num) {
  if (props.min <= num && num <= props.max) {
    sliderValue.value = num;
  }
}

let timeout;
watch(sliderValue, newValue => {
  if (timeout) {
    clearTimeout(timeout);
  }

  // use timeout to avoid trigger multiple change when sliding
  // human response time is 0.25 seconds for a visual stimulus
  timeout = setTimeout(() => {
    props.onChange && props.onChange(newValue);
  }, 250);
});

function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
</script>

<template>
  <div class="rj-slider">
    <div v-if="props.label" class="input-label">{{ props.label }}</div>
    <CurrencyInput
      :value="sliderValue"
      :onChange="onChange"
      :min="props.min"
      :max="props.max"
    />
    <input
      v-model="sliderValue"
      type="range"
      :min="props.min"
      :max="props.max"
      class="slider"
    />
    <div class="label-desc">
      <div class="label-min">
        <div>Min.</div>
        <div>Rp. {{ numberFormat(props.min) }}</div>
      </div>
      <div class="label-max">
        <div>Max.</div>
        <div>Rp. {{ numberFormat(props.max) }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.rj-slider {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-label {
  font-weight: bold;
  color: #525252;
  font-size: 16px;
}

.card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  height: 60px;
  flex-direction: row;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  transition: 0.3s;
  gap: 0.25rem;
  font-weight: bold;
  font-size: 18px;
  color: #161616;
}

.currency {
  color: #727272;
  font-weight: 500;
}

/*********** Baseline, reset styles ***********/
.rj-slider input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

/* Removes default focus */
.rj-slider input[type="range"]:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
.rj-slider input[type="range"]::-webkit-slider-runnable-track {
  background-color: #ffd2c3;
  border-radius: 2px;
  height: 1rem;
}

/* slider thumb */
.rj-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -4px; /* Centers thumb on the track */
  background-color: #ff5315;
  border-radius: 2px;
  height: 1.5rem;
  width: 1.8rem;
}

.rj-slider input[type="range"]:focus::-webkit-slider-thumb {
  /* outline: px solid #ff5315; */
  outline-offset: 0.125rem;
}

/*********** Firefox styles ***********/
/* slider track */
.rj-slider input[type="range"]::-moz-range-track {
  background-color: #ffd2c3;
  border-radius: 2px;
  height: 1rem;
}

/* slider thumb */
.rj-slider input[type="range"]::-moz-range-thumb {
  background-color: #ff5315;
  border: none; /*Removes extra border that FF applies*/
  border-radius: 2px;
  height: 1.5rem;
  width: 2rem;
}

.rj-slider input[type="range"]:focus::-moz-range-thumb {
  outline: 3px solid #ff5315;
  outline-offset: 0.125rem;
}

.label-desc {
  display: flex;
  justify-content: space-between;
  color: #6f6f6f;
  font-weight: 500;
}

.label-max {
  text-align: right;
}
</style>
