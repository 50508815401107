<template>
    <div class="outerContainer">
        <div class="planPackagesContainer">
            <div class="containerTitle">
                <div v-if="initialNumber == '1'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0004 21.2673C16.7721 21.2673 20.6404 17.3991 20.6404 12.6273C20.6404 7.8556 16.7721 3.98734 12.0004 3.98734C7.22865 3.98734 3.36039 7.8556 3.36039 12.6273C3.36039 17.3991 7.22865 21.2673 12.0004 21.2673ZM12.0004 22.2273C17.3023 22.2273 21.6004 17.9293 21.6004 12.6273C21.6004 7.32541 17.3023 3.02734 12.0004 3.02734C6.69846 3.02734 2.40039 7.32541 2.40039 12.6273C2.40039 17.9293 6.69846 22.2273 12.0004 22.2273Z" fill="white"/>
                        <path d="M11.7571 17.2664V9.87258H9.96484V9.09529C10.5216 9.09529 10.9541 9.05461 11.2625 8.97324C11.5708 8.89187 11.7892 8.76767 11.9177 8.60065C12.0505 8.43363 12.1233 8.22593 12.1361 7.97754H12.9841V17.2664H11.7571Z" fill="white"/>
                    </svg>
                </div>
                <div v-if="initialNumber == '2'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0004 21.2673C16.7721 21.2673 20.6404 17.3991 20.6404 12.6273C20.6404 7.8556 16.7721 3.98734 12.0004 3.98734C7.22865 3.98734 3.36039 7.8556 3.36039 12.6273C3.36039 17.3991 7.22865 21.2673 12.0004 21.2673ZM12.0004 22.2273C17.3023 22.2273 21.6004 17.9293 21.6004 12.6273C21.6004 7.32541 17.3023 3.02734 12.0004 3.02734C6.69846 3.02734 2.40039 7.32541 2.40039 12.6273C2.40039 17.9293 6.69846 22.2273 12.0004 22.2273Z" fill="white"/>
                        <path d="M8.9889 17.3216V16.5967V16.2182C8.9889 15.7435 9.07657 15.333 9.2519 14.9866C9.43152 14.6402 9.66673 14.3387 9.95753 14.0821C10.2526 13.8212 10.5734 13.5882 10.9198 13.3829C11.2704 13.1776 11.619 12.9809 11.9654 12.7927C12.316 12.6003 12.6368 12.4014 12.9276 12.1962C13.2184 11.9866 13.4515 11.7514 13.6268 11.4905C13.8064 11.2254 13.8962 10.9132 13.8962 10.554C13.8962 10.0835 13.7294 9.7008 13.3959 9.40571C13.0666 9.11063 12.6197 8.96309 12.0552 8.96309C11.4479 8.96309 10.9604 9.13415 10.5926 9.47628C10.2248 9.8184 10.0174 10.2739 9.97036 10.8426H8.79004C8.80287 10.3081 8.93544 9.82054 9.18776 9.38006C9.44007 8.9353 9.80999 8.58034 10.2975 8.3152C10.785 8.05005 11.3859 7.91748 12.1001 7.91748C12.7202 7.91748 13.259 8.02867 13.7166 8.25105C14.1742 8.46915 14.527 8.77706 14.775 9.17478C15.0274 9.56822 15.1535 10.028 15.1535 10.554C15.1535 11.0329 15.0659 11.4435 14.8905 11.7856C14.7152 12.1277 14.4821 12.4249 14.1913 12.6773C13.9048 12.9253 13.5904 13.1477 13.2483 13.3444C12.9062 13.5411 12.5619 13.7314 12.2155 13.9153C11.8691 14.0949 11.5527 14.2852 11.2662 14.4862C10.9796 14.683 10.7487 14.9096 10.5734 15.1662C10.398 15.4228 10.3103 15.7286 10.3103 16.0835V16.2696H15.2048V17.3216H8.9889Z" fill="white"/>
                    </svg>
                </div>
                <div v-if="initialNumber == '3'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 20.7674C16.7716 20.7674 20.6399 16.8992 20.6399 12.1274C20.6399 7.3557 16.7716 3.48744 11.9999 3.48744C7.22816 3.48744 3.3599 7.3557 3.3599 12.1274C3.3599 16.8992 7.22816 20.7674 11.9999 20.7674ZM11.9999 21.7274C17.3018 21.7274 21.5999 17.4294 21.5999 12.1274C21.5999 6.82551 17.3018 2.52744 11.9999 2.52744C6.69797 2.52744 2.3999 6.82551 2.3999 12.1274C2.3999 17.4294 6.69797 21.7274 11.9999 21.7274Z" fill="white"/>
                        <path d="M11.8531 16.8882C11.2168 16.884 10.6553 16.7665 10.1685 16.5359C9.68602 16.3054 9.30599 15.983 9.02843 15.5688C8.75088 15.1503 8.60143 14.655 8.58008 14.0828H9.76502C9.85469 14.6593 10.0895 15.1012 10.4696 15.4086C10.8496 15.7161 11.3257 15.8698 11.8979 15.8698C12.3505 15.8698 12.7455 15.7994 13.0829 15.6584C13.4202 15.5133 13.6807 15.3147 13.8643 15.0628C14.0479 14.8108 14.1397 14.5162 14.1397 14.1789C14.1397 13.8074 14.0372 13.4935 13.8323 13.2373C13.6273 12.9811 13.3476 12.7868 12.9932 12.6545C12.6388 12.5178 12.2438 12.4474 11.8082 12.4431L10.9756 12.4239V11.4183L11.7698 11.3991C12.1755 11.3905 12.5384 11.3137 12.8587 11.1685C13.1789 11.019 13.4309 10.8247 13.6145 10.5856C13.8024 10.3422 13.8963 10.0753 13.8963 9.78498C13.8963 9.52877 13.8173 9.29178 13.6593 9.07401C13.5013 8.85197 13.2729 8.67476 12.974 8.54239C12.6793 8.41002 12.3207 8.34383 11.8979 8.34383C11.5649 8.34383 11.2467 8.41002 10.9436 8.54239C10.6404 8.67049 10.3906 8.86905 10.1942 9.13806C9.99774 9.40707 9.89312 9.75508 9.88031 10.1821H8.70177C8.70177 9.58428 8.84696 9.07614 9.13732 8.65768C9.42769 8.23494 9.81413 7.91255 10.2966 7.69051C10.7834 7.46847 11.3193 7.35744 11.9043 7.35744C12.5448 7.35744 13.1106 7.45139 13.6017 7.63927C14.0927 7.82288 14.477 8.0919 14.7546 8.44631C15.0321 8.80073 15.1709 9.23414 15.1709 9.74654C15.1709 10.2034 15.0257 10.6176 14.7354 10.9891C14.445 11.3564 14.0009 11.6446 13.4031 11.8538C13.8088 11.9648 14.1632 12.1271 14.4664 12.3406C14.7695 12.5541 15.0044 12.8167 15.1709 13.1284C15.3375 13.4359 15.4207 13.7903 15.4207 14.1917C15.4207 14.6144 15.331 14.9945 15.1517 15.3318C14.9766 15.6649 14.729 15.9488 14.4087 16.1837C14.0885 16.4142 13.7106 16.5915 13.275 16.7153C12.8395 16.8348 12.3655 16.8925 11.8531 16.8882Z" fill="white"/>
                    </svg>
                </div>
                {{ containerLabel }}
            </div>
        </div>
        <div class="contentContainer">
            <slot></slot>
        </div>
    </div>
</template>
<script>

export default {
    name: "PlanPackagesContainer",
    props: {
        initialNumber: {
            type: String,
            default: ""
        },
        containerLabel: {
            type: String,
            default: "containerLabel",
        },
    },
    data() {
        return {
            step: "",
        }
    },
    watch: {},
    created(){}, 
    mounted () {
        var vm = this
    },
    methods: {},
    computed: {}
}
</script>
<style scoped>
.outerContainer{
    margin-bottom: -26px;
}
.planPackagesContainer {
    border-radius: 26px;
    background: linear-gradient(180deg, #0072C3 0%, #003A6E 100%);
    padding: 16px 12px 56px 12px;
}
.containerTitle {
    display: flex;
    gap: 8px;
    color: #FFF;
    font-family: Public Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.24px;
    align-items: center;
}
.contentContainer{
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    /* gap: 16px; */
    border-radius: 24px;
    background: #FFF;
    position: relative;
    top: -42px;
}
</style>