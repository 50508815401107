<template>
<div class="modal modal-mv" v-if="show">
  <div id="modalForToast" class="slideIn">
    <div class="successtips">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{tooltipsText}}
    </div>
  </div>
</div>
</template>

<script>

export default {
    name: "Toast",
    props: {
        show: {
            type: Boolean,
            default: false
        },
        tooltipsText: {
            type: String,
            default: null
        },
    },
    methods: {
        close(){
            var changeClass = $('#modalForToast')
            changeClass.removeClass('slideIn')
            changeClass.addClass('slideOut')
            setTimeout(() => {
                this.$emit("close");
            }, 500);
        }
    },
    updated() {
        setTimeout(()=>{
            this.close()
        },1500)
    }
}
</script>
<style scoped>
.successtips {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background:  rgba(0, 0, 0, 0.7) !important;
    color:  #FFF;
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
}
.modal {
    display: block;
}

.modal-mv {
    text-align: center;
    padding: 0!important;
    background-color: transparent;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 150px 0 !important;
}
.slideIn {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.slideOut {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.slideRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.slideLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.shake {
    -webkit-animation-name: shake;
    animation-name: shake;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInUp {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes slideInUp {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}
@keyframes slideInDown {
    from {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        visibility: visible;
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}


@-webkit-keyframes slideInRight {
    from { 
        -webkit-transform: translateX(100%); 
        transform: translateX(100%);
        visibility: visible;
    }

    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes slideInRight {
    from { 
        -webkit-transform: translateX(100%); 
        transform: translateX(100%);
        visibility: visible;
    }

    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes slideInLeft {
    from { 
        -webkit-transform: translateX(0); 
        transform: translateX(0);
        visibility: visible;
    }

    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}
@keyframes slideInLeft {
    from { 
        -webkit-transform: translateX(0); 
        transform: translateX(0);
        visibility: visible;
    }

    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

@-webkit-keyframes shake {
    0%, 100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%, 30%, 50%, 70%, 90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%, 40%, 60%, 80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}
@keyframes shake {
    0%, 100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%, 30%, 50%, 70%, 90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%, 40%, 60%, 80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
} 

@media screen and (max-width: 480px) {
    
}
</style>