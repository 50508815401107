<template>
    <div class="garage">
      <div
        class="provincecontainer"
        :class="{ active: selectedProvince }"
        @click="showProvince"
      >
        <div>
          <div class="provinceLabel">
            {{
              selectedProvince
                ? $t('motorVehicle.garage.choosedProvinceLabel')
                : $t('motorVehicle.garage.chooseProvinceLabel')
            }}
          </div>
          <div class="provinceSelected">{{ selectedProvince }}</div>
        </div>
        <div class="arrowDrop" :class="{ active: !showProvinceList }">
          <svg
            v-if="selectedProvince && !loading"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20 18C20 19.1046 19.1046 20 18 20L10 20C6.68629 20 4 17.3137 4 14L4 6C4 4.89543 4.89543 4 6 4L14 4C17.3137 4 20 6.68629 20 10L20 18Z"
              :fill="selectedProvince ? '#FFF' : '#EBEBEB'"
            />
            <path
              d="M12.6997 9.7L15.2997 12.3C15.6164 12.6167 15.6871 12.979 15.5117 13.387C15.3364 13.795 15.0241 13.9993 14.5747 14L9.42474 14C8.97474 14 8.66208 13.7957 8.48674 13.387C8.31141 12.9783 8.38241 12.616 8.69974 12.3L11.2997 9.7C11.3997 9.6 11.5081 9.525 11.6247 9.475C11.7414 9.425 11.8664 9.4 11.9997 9.4C12.1331 9.4 12.2581 9.425 12.3747 9.475C12.4914 9.525 12.5997 9.6 12.6997 9.7Z"
              fill="#F26848"
            />
          </svg>
          <Loading v-if="loading" />
        </div>
      </div>
      <div>
        <div v-if="showProvinceList" class="provinceListBox">
          <div class="provinceSearhBox">
            <input
              type="text"
              class="searchInput"
              :placeholder="$t('motorVehicle.garage.searchProvinceLabel')"
              v-model.trim="keywordProvince"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M17.5 17.5L12.5 12.5M14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z"
                stroke="#CCCCCC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="provinceList" id="listContainer1">
            <div
              @click="
                selectedProvince = province.idn;
                showProvinceList = false;
                keyword = '';
                keywordSearch = '';
                getGarage(workshopType == 'Dealer' ? vehicleBrand : '');
              "
              class="provinceItem"
              :class="{ active: province.idn === selectedProvince }"
              v-for="(province, index) in provinceList"
              :key="index"
            >
              {{ province.idn }}
            </div>
          </div>
          <div>
            <svg
              @click="scrollUp(1)"
              class="pointer scrollUpProvince"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 18C20 19.1046 19.1046 20 18 20L10 20C6.68629 20 4 17.3137 4 14L4 6C4 4.89543 4.89543 4 6 4L14 4C17.3137 4 20 6.68629 20 10L20 18Z"
                fill="#EBEBEB"
              />
              <path
                d="M12.6997 9.7L15.2997 12.3C15.6164 12.6167 15.6871 12.979 15.5117 13.387C15.3364 13.795 15.0241 13.9993 14.5747 14L9.42474 14C8.97474 14 8.66208 13.7957 8.48674 13.387C8.31141 12.9783 8.38241 12.616 8.69974 12.3L11.2997 9.7C11.3997 9.6 11.5081 9.525 11.6247 9.475C11.7414 9.425 11.8664 9.4 11.9997 9.4C12.1331 9.4 12.2581 9.425 12.3747 9.475C12.4914 9.525 12.5997 9.6 12.6997 9.7Z"
                fill="#F26848"
              />
            </svg>
            <svg
              @click="scrollDown(1)"
              class="pointer scrollDownProvince"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 6C4 4.89543 4.89543 4 6 4L14 4C17.3137 4 20 6.68629 20 10V18C20 19.1046 19.1046 20 18 20L10 20C6.68629 20 4 17.3137 4 14L4 6Z"
                fill="#EBEBEB"
              />
              <path
                d="M11.3003 14.3L8.70026 11.7C8.38359 11.3833 8.31292 11.021 8.48826 10.613C8.66359 10.205 8.97592 10.0007 9.42526 10L14.5753 10C15.0253 10 15.3379 10.2043 15.5133 10.613C15.6886 11.0217 15.6176 11.384 15.3003 11.7L12.7003 14.3C12.6003 14.4 12.4919 14.475 12.3753 14.525C12.2586 14.575 12.1336 14.6 12.0003 14.6C11.8669 14.6 11.7419 14.575 11.6253 14.525C11.5086 14.475 11.4003 14.4 11.3003 14.3Z"
                fill="#F26848"
              />
            </svg>
          </div>
        </div>
        <div class="provinceInfo" v-if="!selectedProvince">
          {{
            workshopType == 'Panel'
              ? $t('motorVehicle.garage.infoPanelLabel')
              : $t('motorVehicle.garage.infoDealerLabel')
          }}
        </div>
      </div>
      <div class="searchContainer" :class="{focus : searchBoxFocus}" v-if="!loading">
        <input
          type="text"
          class="searchInput"
          :placeholder="$t('motorVehicle.garage.findGaragePlaceholder')"
          v-model.trim="keyword"
          @focus="searchBoxFocus = true"
          @blur="searchBoxFocus = false"
          v-on:keyup.enter="searchGarage()"
        />
        <div class="searchButton" @click="searchGarage()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M14.9984 14L10.9947 10M12.3293 6.66667C12.3293 9.244 10.238 11.3333 7.6583 11.3333C5.07858 11.3333 2.9873 9.244 2.9873 6.66667C2.9873 4.08934 5.07858 2 7.6583 2C10.238 2 12.3293 4.08934 12.3293 6.66667Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ $t('motorVehicle.garage.findGarageButton') }}
        </div>
      </div>
      <div v-if="!loading">
        <div class="searchResultCounter">
          <div class="resultIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.8333 13.3302H10V10H9.16667M10 6.66976H10.0083M17.5 10C17.5 14.1383 14.1421 17.493 10 17.493C5.85786 17.493 2.5 14.1383 2.5 10C2.5 5.86171 5.85786 2.50696 10 2.50696C14.1421 2.50696 17.5 5.86171 17.5 10Z"
                stroke="#A8A8A8"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="resultInfo">
            {{ $t('motorVehicle.garage.infoSearchResult1') }}
            <span>{{ selectedProvince }}</span>
            <span
              v-if="keywordSearch !== ''"
              @click="clearSearch()"
              style="cursor: pointer;"
              >'{{ keywordSearch }}' x</span
            >
          </div>
        </div>
        <div class="resultInfo" v-if="!showProvinceList && garages.length > 0">
          {{ $t('motorVehicle.garage.infoSearchResult2') }}
          <span>{{ count }}</span>
          {{ $t('motorVehicle.garage.infoSearchResult3') }}
        </div>
        <div
          class="emptyList"
          v-if="selectedProvince && garages.length == 0 && keywordSearch == ''"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M16.6663 35H28.333C30.174 35 31.6663 33.5076 31.6663 31.6667V15.6904C31.6663 15.2483 31.4907 14.8244 31.1782 14.5118L22.1545 5.48816C21.8419 5.17559 21.418 5 20.976 5H11.6663C9.82539 5 8.33301 6.49238 8.33301 8.33333V26.6667M8.33301 35L16.4641 26.8689M16.4641 26.8689C17.369 27.7737 18.619 28.3333 19.9997 28.3333C22.7611 28.3333 24.9997 26.0948 24.9997 23.3333C24.9997 20.5719 22.7611 18.3333 19.9997 18.3333C17.2383 18.3333 14.9997 20.5719 14.9997 23.3333C14.9997 24.714 15.5593 25.964 16.4641 26.8689Z"
              stroke="#A8A8A8"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
          <div class="emptyTitle">
            {{ $t('motorVehicle.garage.emptyTitle1') }}
          </div>
          <div class="emptyText">{{ $t('motorVehicle.garage.emptyDesc1') }}</div>
          <div class="emptyButton pointer" @click="reloadData()">
            {{
              workshopType == 'Panel'
                ? $t('motorVehicle.garage.emptyDealerButton')
                : $t('motorVehicle.garage.emptyPanelButton')
            }}
          </div>
        </div>
        <div
          class="emptyList"
          v-if="selectedProvince && garages.length == 0 && keywordSearch !== ''"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M16.6663 35H28.333C30.174 35 31.6663 33.5076 31.6663 31.6667V15.6904C31.6663 15.2483 31.4907 14.8244 31.1782 14.5118L22.1545 5.48816C21.8419 5.17559 21.418 5 20.976 5H11.6663C9.82539 5 8.33301 6.49238 8.33301 8.33333V26.6667M8.33301 35L16.4641 26.8689M16.4641 26.8689C17.369 27.7737 18.619 28.3333 19.9997 28.3333C22.7611 28.3333 24.9997 26.0948 24.9997 23.3333C24.9997 20.5719 22.7611 18.3333 19.9997 18.3333C17.2383 18.3333 14.9997 20.5719 14.9997 23.3333C14.9997 24.714 15.5593 25.964 16.4641 26.8689Z"
              stroke="#A8A8A8"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
          <div class="emptyTitle">
            {{
              $t('motorVehicle.garage.emptyTitle2', { keyword: keywordSearch })
            }}
          </div>
          <div class="emptyText">{{ $t('motorVehicle.garage.emptyDesc2') }}</div>
          <div class="emptyButton pointer" @click="clearSearch()">
            {{ $t('motorVehicle.garage.emptyAnotherButton') }}
          </div>
        </div>
        <div
          class="garagesContainerBox"
          :class="{ singleData: garages.length < 2 }"
          v-if="garages.length > 0"
        >
          <div
            class="garagesContainer"
            :class="{ noscrollbar: garages.length < 2 }"
            id="listContainer2"
          >
            <div
              class="garageContainer"
              :class="{ singleItem: garages.length == 1 }"
              v-for="(garage, index) in garages"
              :key="index"
            >
              <div class="rowContainer">
                <div class="badgeGarage">
                  {{
                    garage.garageType == 'Panel'
                      ? $t('motorVehicle.garage.workshopPanel')
                      : $t('motorVehicle.garage.workshopDealer')
                  }}
                </div>
                <div class="badgeGarage" v-if="garage.primaryBrand">{{ garage.primaryBrand }}</div>
              </div>
              <div class="garageName">{{ garage.name }}</div>
              <div class="rowContainer">
                <div class="resultIcon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M14.7137 13.8807C13.9862 14.6083 12.5186 16.0758 11.4131 17.1813C10.6321 17.9624 9.36726 17.9623 8.58622 17.1813C7.5006 16.0957 6.06013 14.6552 5.28563 13.8807C2.68213 11.2772 2.68213 7.05612 5.28563 4.45262C7.88912 1.84913 12.1102 1.84913 14.7137 4.45262C17.3172 7.05612 17.3172 11.2772 14.7137 13.8807Z"
                      stroke="#8D8D8D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.4997 9.16667C12.4997 10.5474 11.3804 11.6667 9.99967 11.6667C8.61896 11.6667 7.49967 10.5474 7.49967 9.16667C7.49967 7.78596 8.61896 6.66667 9.99967 6.66667C11.3804 6.66667 12.4997 7.78596 12.4997 9.16667Z"
                      stroke="#8D8D8D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="garageAddress">{{ garage.soiRoad }}</div>
              </div>
              <div class="rowContainer" v-if="garage.phoneNumber1">
                <div class="resultIcon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M2.5 4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H6.89937C7.25806 2.5 7.57651 2.72953 7.68994 3.06981L8.93811 6.81434C9.06926 7.20777 8.89115 7.63776 8.52022 7.82322L6.63917 8.76375C7.55771 10.801 9.19898 12.4423 11.2363 13.3608L12.1768 11.4798C12.3622 11.1088 12.7922 10.9307 13.1857 11.0619L16.9302 12.3101C17.2705 12.4235 17.5 12.7419 17.5 13.1006V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H15C8.09644 17.5 2.5 11.9036 2.5 5V4.16667Z"
                      stroke="#8D8D8D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="garagePhone">{{ garage.phoneNumber1 }}</div>
              </div>
            </div>
          </div>
          <div v-if="garages.length > 1">
            <svg
              @click="scrollUp(2)"
              class="pointer scrollUp"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 18C20 19.1046 19.1046 20 18 20L10 20C6.68629 20 4 17.3137 4 14L4 6C4 4.89543 4.89543 4 6 4L14 4C17.3137 4 20 6.68629 20 10L20 18Z"
                fill="#EBEBEB"
              />
              <path
                d="M12.6997 9.7L15.2997 12.3C15.6164 12.6167 15.6871 12.979 15.5117 13.387C15.3364 13.795 15.0241 13.9993 14.5747 14L9.42474 14C8.97474 14 8.66208 13.7957 8.48674 13.387C8.31141 12.9783 8.38241 12.616 8.69974 12.3L11.2997 9.7C11.3997 9.6 11.5081 9.525 11.6247 9.475C11.7414 9.425 11.8664 9.4 11.9997 9.4C12.1331 9.4 12.2581 9.425 12.3747 9.475C12.4914 9.525 12.5997 9.6 12.6997 9.7Z"
                fill="#F26848"
              />
            </svg>
            <svg
              @click="scrollDown(2)"
              class="pointer scrollDown"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 6C4 4.89543 4.89543 4 6 4L14 4C17.3137 4 20 6.68629 20 10V18C20 19.1046 19.1046 20 18 20L10 20C6.68629 20 4 17.3137 4 14L4 6Z"
                fill="#EBEBEB"
              />
              <path
                d="M11.3003 14.3L8.70026 11.7C8.38359 11.3833 8.31292 11.021 8.48826 10.613C8.66359 10.205 8.97592 10.0007 9.42526 10L14.5753 10C15.0253 10 15.3379 10.2043 15.5133 10.613C15.6886 11.0217 15.6176 11.384 15.3003 11.7L12.7003 14.3C12.6003 14.4 12.4919 14.475 12.3753 14.525C12.2586 14.575 12.1336 14.6 12.0003 14.6C11.8669 14.6 11.7419 14.575 11.6253 14.525C11.5086 14.475 11.4003 14.4 11.3003 14.3Z"
                fill="#F26848"
              />
            </svg>
          </div>
        </div>
      </div>
      <!-- <div class="foot-btn"  @click="close">
              {{ $t('motorVehicle.close') }}
          </div> -->
    </div>
  </template>
  <script>
  import Modal from './Modal';
  import Global from '@/store/global.js';
  import Mixin from '@/components/Mixin';
  import Loading from './Loading';
  import provinces from '../data/provinces';
  export default {
    name: 'Garage',
    components: { Loading, Modal },
    mixins: [Mixin],
    data() {
      return {
        provinces: provinces,
        masterGarages: [],
        garages: [],
        masterCount: null,
        count: null,
        search: [],
        selectedProvince: null,
        keyword: '',
        keywordSearch: '',
        showProvinceList: true,
        loading: false,
        keywordProvince: '',
        provinceList: provinces,
        searchBoxFocus: false,
      };
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      workshopType: {
        type: String,
        default: '',
      },
      vehicleBrand: {
        type: String,
        default: '',
      },
    },
    watch: {
      keywordProvince: function(value) {
        if (value !== '') {
          let key = value.replace(/[^\w\s]/gi, '').toLowerCase();
          var condition = new RegExp(key);
          var result = this.provinces.filter(function({ idn, en }) {
            return condition.test(idn.toLowerCase());
          });
          this.provinceList = result;
        } else this.provinceList = this.provinces;
      },
    },
    methods: {
      close() {
        this.$emit('close');
      },
      toTitleCase(str) {
        return str.replace(/\w\S*/g, function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      },
      reloadData() {
        let brand = this.workshopType == 'Dealer' ? '' : this.vehicleBrand;
        this.$emit(
          'changeTitle',
          this.workshopType == 'Dealer' ? 'Panel' : 'Dealer'
        );
        this.getGarage(brand);
      },
      showProvince() {
        if (this.selectedProvince) {
          this.showProvinceList = !this.showProvinceList;
        }
      },
      clearSearch() {
        this.garages = this.masterGarages;
        this.count = this.masterCount;
        this.keywordSearch = '';
      },
      searchGarage() {
        if (this.keyword !== '') {
          this.keywordSearch = this.keyword.replace(/[^\w\s]/gi, '').toLowerCase();
          this.keyword = '';
          var condition = new RegExp(this.keywordSearch);
          var result = this.masterGarages.filter(function({ name, soiRoad }) {
            return (
              condition.test(name.toLowerCase()) ||
              condition.test(soiRoad.toLowerCase())
            );
          });
          this.garages = result;
          this.count = result.length;
        }
      },
      async getGarage(brand) {
        this.loading = true;
        try {
          let reqGarage = {
            province: this.selectedProvince,
            primaryBrands: brand === '' ? '' : [brand],
          };
          const response = await this.request().post(
            Global.roojaiService.url + '/garageData/getGarageData',
            reqGarage,
            {
              headers: { Authorization: 'Bearer ' + Global.roojaiService.token },
            }
          );
          if (response.data.success == true) {
            this.loading = false;
            this.garages = response.data.items;
            this.count = response.data.countSearch;
            this.masterGarages = response.data.items;
            this.masterCount = response.data.countSearch;
            return response;
          } else {
            this.loading = false;
            Global.isValidated.postcode = false;
            this.garages = [];
            return response;
          }
        } catch (error) {
          this.loading = false;
        }
      },
      scrollUp(id) {
        let content = document.querySelector('#listContainer' + id);
        let scrollAmount = 0;
        let slideTimer = setInterval(function() {
          content.scrollTop -= 5;
          scrollAmount += 5;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 10);
      },
      scrollDown(id) {
        let content = document.querySelector('#listContainer' + id);
        let scrollAmount = 0;
        let slideTimer = setInterval(function() {
          content.scrollTop += 5;
          scrollAmount += 5;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 15);
      },
    },
  };
  </script>
  <style scoped>
  .garage {
    padding: 16px;
    min-height: 450px;
  }
  .provincecontainer {
    display: flex;
    height: 58px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 0.5px solid #00539a;
    background: #fff;
    cursor: pointer;
  }
  .provincecontainer.active {
    border: 0.5px solid #0e6027;
    background: #deffe7;
  }
  .provinceLabel {
    color: #00539a;
    font-family: Public Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
  }
  .provinceSelected {
    color: #000;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
    height: 20px;
  }
  .arrowDrop {
    width: 24px;
    height: 24px;
  }
  .arrowDrop.active {
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .provinceListBox {
    margin: 4px 0;
    display: flex;
    flex-direction: column;
    padding: 8px;
    margin-right: 0px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 58, 109, 0.48);
    position: absolute;
    width: 90%;
    z-index: 999;
  }
  .provinceSearhBox {
    display: flex;
    padding: 6px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #ebebeb;
  }
  .provinceList {
    height: 256px;
    overflow-y: scroll;
    width: 97%;
    align-self: flex-start;
    padding-right: 10px;
  }
  .provinceItem {
    display: flex;
    padding: 6px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    cursor: pointer;
    border-bottom: 1px solid #fafafa;
    background: #fff;
    color: #525252;
    font-family: 'Public Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
  .provinceItem:hover {
    background: #ffece5;
  }
  .provinceItem.active {
    background: #ffded6;
  }
  .provinceInfo {
    display: flex;
    padding: 4px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: #ebf5fa;
    color: #003a6e;
    font-family: 'Public Sans';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.2px;
    position: absolute;
    top: 440px;
    width: 90%;
  }
  .searchContainer {
    display: flex;
    padding: 16px 24px 16px 16px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 40px;
    border: 0.5px solid #ebebeb;
    background: #fff;
    margin: 8px 0;
  }
  .searchContainer:hover {
    border: 0.5px solid #6F6F6F;
  }
  .searchContainer.focus {
    border: 0.5px solid #00315D;
  }
  .searchButton {
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;
    border-radius: 8px;
    background: linear-gradient(180deg, #ff6d39 0%, #ff5011 100%);
    color: #fff;
    text-align: center;
    font-family: 'Public Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    cursor: pointer;
  }
  .searchButton:hover{
    background: #D4430E;
  }
  .searchButton:active{
    background: #AA350B;
  }
  .searchInput {
    border: #fff;
    border-width: 0;
    display: flex;
    padding: 8px 0;
    height: 24px;
    max-width: 70%;
  }
  .searchInput:focus {
    outline-width: 0;
  }
  .searchInput::placeholder {
    text-align: left;
    color: #a8a8a8;
    font-family: 'Public Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
  .searchInput::-webkit-input-placeholder {
    text-align: left;
  }
  .searchResultCounter {
    display: flex;
    padding: 8px 0;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }
  .loading {
    display: flex;
    width: 100%;
    height: 90px;
    justify-content: center;
  }
  .resultIcon {
    width: 20px;
    height: 20px;
  }
  .resultInfo {
    color: #6f6f6f;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
  .resultInfo span {
    /* display: flex; */
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: #ebf1f5;
    color: #2a5c88;
    font-family: 'Public Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .garagesContainerBox {
    padding: 16px;
    border-radius: 16px;
    background: #fff;
    margin-top: 16px;
    display: flex;
    height: 272px;
  }
  .garagesContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: scroll;
  }
  .garageContainer {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    background: #fff;
    width: 95%;
  }
  .rowContainer {
    display: flex;
    gap: 8px;
  }
  .scrollUpProvince{
    position: absolute;
    bottom: 240px;
    right: 6px;
  }
  .scrollDownProvince{
    position: absolute;
    bottom: 17px;
    right: 6px;
  }
  .scrollUp{
    position: absolute;
    bottom: 250px;
    right: 22px;
    z-index: 900;
  }
  .scrollDown{
    position: absolute;
    bottom: 30px;
    right: 22px;
  }
  .badgeGarage {
    display: flex;
    padding: 4px 6px;
    justify-content:flex-start;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: #ffefeb;
    color: #f26848;
    font-family: Public Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 10px;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 115px;
  }
  .garageName {
    color: #393939;
    font-family: Public Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-transform: capitalize;
  }
  .garageAddress {
    color: #8d8d8d;
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .garagePhone {
    color: #ef4a24;
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
  }
  .foot-btn {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 4px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 8px;
    background: linear-gradient(180deg, #ef4a24 0%, #f26848 100%);
    color: #fff;
    text-align: center;
    font-family: Public Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    margin: 16px 0 0 0;
  }
  ::-webkit-scrollbar {
    scrollbar-color: #f4866d #dddddd;
    width: 4px;
    height: 80%;
  }
  
  ::-webkit-scrollbar-track {
    background: #dddddd;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 35px;
    right: 20px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #f4866d;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ff5011;
  }
  
  .pointer {
    cursor: pointer;
  }
  .emptyList {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: #fafafa;
    margin-top: 16px;
  }
  .emptyTitle {
    color: #393939;
    text-align: center;
    font-family: Public Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.32px;
  }
  .emptyText {
    color: #8d8d8d;
    text-align: center;
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .emptyButton {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 4px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 8px;
    background: linear-gradient(180deg, #ef4a24 0%, #f26848 100%);
    color: #fff;
    text-align: center;
    font-family: Public Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-transform: capitalize;
  }
  .emptyButton:hover{
    background: #D4430E;
  }
  .emptyButton:active{
    background: #AA350B;
  }
  .noscrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .noscrollbar::-webkit-scrollbar {
    display: none;
  }
  .singleData {
    height: auto;
  }
  .singleItem {
    width: 100%;
  }
  
  </style>