<script setup>
import { ref, watch } from "vue";
const props = defineProps({
  value: String,
  min: Number,
  max: Number,
  onChange: Function
});

const inputValue = ref(numberFormat(props.value || props.min || 0));

watch(
  () => props.value,
  newValue => {
    inputValue.value = numberFormat(newValue);
  }
);

function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function toNumber(num) {
  return Number(num.replace(/\D/g, ""));
}

function onChange(e) {
  let num = toNumber(e.target.value);
  if (props.max && props.max < num) {
    num = props.max;
  }

  inputValue.value = numberFormat(num);

  if (props.min <= num <= props.max && props.onChange) {
    props.onChange(num);
  }
}
</script>

<template>
  <div class="currency-input">
    <input class="form-control" @input="onChange" v-model="inputValue" />
  </div>
</template>

<style scoped>
.currency-input {
  position: relative;
  font-size: 18px;
}

.currency-input input {
  border: 1px solid #e3e3e3;
  padding: 8px 16px 8px 48px;
  height: 60px;
  font-weight: 600;
  color: #161616;
}

.currency-input::before {
  content: "Rp.";
  position: absolute;
  top: 0;
  bottom: 0;
  align-self: center;
  left: 16px;
  font-weight: 500;
  color: #727272;
}
</style>
