export default [
    {
      "id": 1,
      "idn": "Bali",
      "en": "Bali",
      "lat": -8.739184,
      "lng": 115.171127
  },
  {
      "id": 2,
      "idn": "Bangka Belitung",
      "en": "Bangka Belitung",
      "lat": -1.95839,
      "lng": 105.53741
  },
  {
      "id": 3,
      "idn": "Banten",
      "en": "Banten",
      "lat": -6.4058172,
      "lng": 106.0640179
  },
  {
      "id": 4,
      "idn": "Bengkulu",
      "en": "Bengkulu",
      "lat": -3.788892,
      "lng": 102.266579
  },
  {
      "id": 5,
      "idn": "DI Yogyakarta",
      "en": "DI Yogyakarta",
      "lat": -7.797068,
      "lng": 110.370529
  },
  {
      "id": 6,
      "idn": "DKI Jakarta",
      "en": "DKI Jakarta",
      "lat": -6.211544,
      "lng": 106.845172
  },
  {
      "id": 7,
      "idn": "Jambi",
      "en": "Jambi",
      "lat": -1.609972,
      "lng": 103.607254
  },
  {
      "id": 8,
      "idn": "Jawa Barat",
      "en": "Jawa Barat",
      "lat": -6.943097,
      "lng": 107.633545
  },
  {
      "id": 9,
      "idn": "Jawa Tengah",
      "en": "Jawa Tengah",
      "lat": -6.995016,
      "lng": 110.418427
  },
  {
      "id": 10,
      "idn": "Jawa Timur",
      "en": "Jawa Timur",
      "lat": -7.536064,
      "lng": 112.238402
  },
  {
      "id": 11,
      "idn": "Kalimantan Barat",
      "en": "Kalimantan Barat",
      "lat": -0.278781,
      "lng": 111.475285
  },
  {
      "id": 12,
      "idn": "Kalimantan Selatan",
      "en": "Kalimantan Selatan",
      "lat": -3.092642,
      "lng": 115.283758
  },
  {
      "id": 13,
      "idn": "Kalimantan Tengah",
      "en": "Kalimantan Tengah",
      "lat": -1.681488,
      "lng": 113.382355
  },
  {
      "id": 14,
      "idn": "Kalimantan Timur",
      "en": "Kalimantan Timur",
      "lat": 0.538659,
      "lng": 116.419389
  },
  {
      "id": 15,
      "idn": "Kepulauan Riau",
      "en": "Kepulauan Riau",
      "lat": 3.945651,
      "lng": 108.142867
  },
  {
      "id": 16,
      "idn": "Lampung",
      "en": "Lampung",
      "lat": -4.5585849,
      "lng": 105.4068079
  },
  {
         "id": 17,
      "idn": "Maluku",
      "en": "Maluku",
      "lat": -3.654703,
      "lng": 128.190643
  },
  {
      "id": 18,
      "idn": "Nanggroe Aceh Darussalam",
      "en": "Nanggroe Aceh Darussalam",
      "lat": 4.695135,
      "lng": 96.7493993
  },
  {
      "id": 19,
      "idn": "Nusa Tenggara Barat",
      "en": "Nusa Tenggara Barat",
      "lat": -8.6529334,
      "lng": 117.3616476
  },
  {
      "id": 20,
      "idn": "Nusa Tenggara Timur",
      "en": "Nusa Tenggara Timur",
      "lat": -8.6573819,
      "lng": 121.0793705
  },
  {
      "id": 21,
      "idn": "Papua",
      "en": "Papua",
      "lat": -4.269928,
      "lng": 138.0803529
  },
  {
      "id": 22,
      "idn": "Papua Barat",
      "en": "Papua Barat",
      "lat": -1.3361154,
      "lng": 133.1747162
  },
  {
      "id": 23,
      "idn": "Riau",
      "en": "Riau",
      "lat": 0.2933469,
      "lng": 101.7068294
  },
  {
      "id": 24,
      "idn": "Sulawesi Selatan",
      "en": "Sulawesi Selatan",
      "lat": -3.6687994,
      "lng": 119.9740534
  },
  {
      "id": 25,
      "idn": "Sulawesi Tengah",
      "en": "Sulawesi Tengah",
      "lat": -1.4300254,
      "lng": 121.4456179
  },
  {
      "id": 26,
      "idn": "Sulawesi Tenggara",
      "en": "Sulawesi Tenggara",
      "lat": -4.14491,
      "lng": 122.174605
  },
  {
      "id": 27,
      "idn": "Sulawesi Utara",
      "en": "Sulawesi Utara",
      "lat": 0.6246932,
      "lng": 123.9750018
  },
  {
      "id": 28,
      "idn": "Sumatera Barat",
      "en": "Sumatera Barat",
      "lat": -0.7399397,
      "lng": 100.8000051
  },
  {
      "id": 29,
      "idn": "Sumatera Selatan",
      "en": "Sumatera Selatan",
      "lat": -3.3194374,
      "lng": 103.914399
  },
  {
      "id": 30,
      "idn": "Sumatera Utara",
      "en": "Sumatera Utara",
      "lat": 2.1153547,
      "lng": 99.5450974
  }
]
