
<template>
  <div class="loader"></div>
</template>
<script>
export default {
    name: "Loading",
    data() {
        return {}
    },
    props: {
        color: {
            type: String,
            default: ""
        },
    },
}
</script>
<style scoped>
.loader {
  border: 2px solid #198038;
  border-radius: 50%;
  border-top: 2px solid #f3f3f3;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>