<template>
<div class="infoCard" v-if="show">
    <div class="content-left">
        <div class="image-left" :class="{'arrow':isArrow}"><i :class="iconClass"></i></div>
    </div>
    <div class="content-right">
        <div class="info-text">
            {{ text }}
        </div>
        <span @click="show = false;">
            <img src="images/motor-vehicle/crossCloseButton.svg"/>
        </span>
    </div>
</div>
</template>
<script>
export default {
    name: "InfoCard",
    props: {
        text: {
            type: String,
            default: ""
        },
        iconClass: {
            type: String,
            default: "bar-cover-car"
        },
        isArrow:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            show: true,
        }
    }
}
</script>
<style scoped>
.infoCard {
    display: flex;
    background: #00539A;
    padding: 16px;
    border-radius: 8px;
    height: 68px;
    margin-bottom: 16px;
    /* border: 1px solid skyblue;
    border-left-width: 8px;
    border-radius: 8px 16px 0px 0px */
}

.infoCard .content-left .image-left.arrow:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    display: block;
    content: '';
    width: 12px;
    height: 12px;
    border: 1px solid #00539A;
    position: absolute;
    border-top: 0;
    border-left: 0;
    top: 44px;
    left: 6px;
    background: #00539A;
}
.infoCard .content-right {
    width: 92%;
    align-self: center;
    display: flex; 
    gap: 16px;
    align-items: center;
    justify-content: space-between;
}
.infoCard .content-right span:hover{
    background: #0072C3;
    border-radius: 50%;
    cursor: pointer;
}
.infoCard .content-left {
    width: 8%;
    height: 24px;
}
.infoCard .content-left .image-left {
    position: relative;
    text-align: left;
}
.infoCard .content-right .info-text {
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    text-align: left;
}


/* Icon */
.bar-cover-car {
    position: relative;
}
.bar-cover-car::before {
    content: '';
    background: url('../../../../public/images/motor-vehicle/Megaphone.svg') no-repeat 0px 0px;
    top: 7px;
    height: 24px;
    width: 24px;
    background-size: cover;
    position: relative;
    float: left;
}
.bar-cover-car2 {
    position: relative;
}
.bar-cover-car2::before {
    content: '';
    background: url('../../../../public/images/motor-vehicle/Megaphone.svg') no-repeat 0px 0px;
    top: 7px;
    height: 24px;
    width: 24px;
    background-size: cover;
    position: relative;
    float: left;
}
.bar-cover-garage {
    position: relative;
}
.bar-cover-garage::before {
    content: '';
    background: url('../../../../public/images/motor-vehicle/Megaphone.svg') no-repeat 0px 0px;
    top: 7px;
    height: 24px;
    width: 24px;
    background-size: cover;
    position: relative;
    float: left;
}

.bar-cover-injured {
    position: relative;
}
.bar-cover-injured::before {
    content: '';
    background: url('../../../../public/images/motor-vehicle/Megaphone.svg') no-repeat 0px 0px;
    top: 7px;
    height: 24px;
    width: 24px;
    background-size: cover;
    position: relative;
    float: left;
}
@media screen and (max-width: 480px) {
    .infoCard .content-right .info-text {
        font-size: 12px;
    }
    .infoCard .content-left {
        width: 12%;
        height: 24px;
    }
    .infoCard .content-right {
        width: 88%;
        margin-left: 4px;
        align-self: center;
    }
    /* .bar-cover-car2::before {
        top: 7px;
    }
    .bar-cover-injured::before {
        top: -10px;
    }
    .bar-cover-car::before {
        top:0px;
    } */
    
}
</style>