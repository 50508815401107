<template>
<div class="warranty" :class="className">
    <div class="header-top">
        {{ $t('motorVehicle.accessoriessTitle') }}
        <div class="maximum-text">{{ $t('motorVehicle.accessoriessTitle2') }}</div>
        <div class="error-message" v-if="showError">{{ $t('motorVehicle.accessoriesWarning') }}</div>
        <!-- <div class="error-message" v-if="isReachLimit">{{ $t('motorVehicle.accMaximumProtection') }}</div> -->
    </div>
    <div class="content-warranty">
        <div class="options" v-for="(item, index) in accessoriesLists" :key="index">
            <div class="item-option" >
                <div 
                    class="picker" 
                    :class="{active: isSelected(item.id)}"
                    @click="pickAccessories(item)"
                >
                    <i class="glyphicons glyphicons-ok"></i>
                </div>
                <span class="label" :class="{active: accessoriesSelected == item.id}">{{ $t('motorVehicle.accessoriess.label' + item.id ) }}</span>
            </div>
            <div class="option-price" v-if="isSelected(item.id)" :id="`accessories-${item.id}`">
                <div class="text">{{ $t('motorVehicle.maxAccProtection') }}</div>
                <div class="protection">
                    <div class="price" v-for="(price, index) in item.price" :key="index">
                        <button @click="selectPrice(price, item.id)" :class="{active : isPriceSelected(price, item.id)}"> {{ $t('motorVehicle.currencySymbol') }}. {{ numberFormat(price) }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-warranty">
        <button class="cancel" @click="cancel">{{ $t('motorVehicle.cancel') }}</button>
        <button class="save" @click="save" :disabled="isDisabled">{{ $t('motorVehicle.save') }}</button>
    </div>
</div>
</template>

<script>
export default {
    name: "Warranty",
    props: {
        vehicleAccessoriesSelected: {
            type: Array,
            default: () => []
        },
        showError: {
            type: Boolean,
            default: false
        },
        GlobalSumInsured: {
            type: Number,
            default: null
        },
        className: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            accessoriesLists: [
                {
                    id: 1,
                    text: "Body Kit",
                    value:"Body_Kit",
                    price: [5000000, 10000000]
                },
                {
                    id: 2,
                    text: "Pelek & Ban",
                    value: "Rims_Tyres",
                    price: [5000000, 10000000]
                },
                {
                    id: 3,
                    text: "Kaca Film",
                    value: "Solar_Film",
                    price: [5000000, 10000000]
                },
                {
                    id: 4,
                    text: "Audio/Video Sistem untuk Hiburan",
                    value: "In_Car_Entertainment",
                    price: [5000000, 10000000]
                },
                {
                    id: 5,
                    text: "Kamera Belakang",
                    value: "Rear_Camera",
                    price: [5000000, 10000000]
                },
            ],
            accessoriesSelected: [],
            accListId:[],
            isDisabled: true,
            isReachLimit: false
        }
    },
    watch:{
        accessoriesSelected: {
            deep: true,
            handler: function(value) {
                const sum = this.calculateSelectedAccessories(value);
                const sumInsure = this.GlobalSumInsured;
                const tenPercent = sumInsure * 0.1;
                const maxInsure = 20000000;

                if(tenPercent < maxInsure) {
                    if(sum > tenPercent) {
                        this.reachLimit()
                        this.isReachLimit = true;
                        this.isDisabled = true;
                    } else {
                        this.isReachLimit = false;
                        this.isDisabled = false;
                    }
                } else if(tenPercent > maxInsure) {
                    if(sum > maxInsure) {
                        this.reachLimit()
                        this.isReachLimit = true;
                        this.isDisabled = true;
                    } else {
                        this.isReachLimit = false;
                        this.isDisabled = false;
                    }
                } 
            }
            
        }
    },
    mounted() {
        if(this.vehicleAccessoriesSelected.length > 0) {
            // this.accessoriesSelected= this.vehicleAccessoriesSelected;
            let selection = [];
            if(this.vehicleAccessoriesSelected.length > 0){
                this.vehicleAccessoriesSelected.forEach((item) => {
                    this.accessoriesLists.forEach((itm) => {
                        if(itm.value === item.vehicleAccessoryName) {
                            itm.selectPrice = item.vehicleAccessoryValue;
                            selection.push(itm);
                        } 
                    })
                })
            }
            this.accessoriesSelected = selection;
        }
    },
    methods: {
        pickAccessories(value) {
            const isFound = this.isSelected(value.id);
            if(!isFound) {
                this.accessoriesSelected.push(value);
                this.accListId.push(value.id);
                this.scrollToId(`accessories-${value.id}`)
            } else {
                this.accessoriesSelected.splice(this.accListId.indexOf(value.id), 1);
                this.accListId.splice(this.accListId.indexOf(value.id), 1);
            }
        },
        reachLimit(){
            this.$emit("limit");
        },
        cancel() {
            this.$emit("cancel");
        },
        selectPrice(value, id){
            const accessories = this.accessoriesSelected.find(key => key.id === id);
            accessories.selectPrice = value;
        },
        isSelected(id) {
            
            const check = this.accessoriesSelected.find(key => key.id == id);
            return !check !== true;
        },
        isPriceSelected(price, id) {
            const check = this.accessoriesSelected.find(key => key.id === id);
            return check.selectPrice == price;
        },
        scrollToId(element) {
            setTimeout(()=> {
                const elmnt = document.getElementById(element);
                if (elmnt) {
                document.getElementById(element).scrollIntoView({
                    behavior: "smooth"
                });
                }
            });
        },
        changeDisableBtn(value) {
            this.isDisabled = value;
        },
        save() {
            this.$emit("save", this.accessoriesSelected)
        },
        calculateSelectedAccessories(value) {
            const sum = value.reduce((acc, curr) => acc + Number(curr['selectPrice']), 0);
            return sum;
        },
        numberFormat(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    },
}
</script>

<style scoped>
.warranty {
    background: #fff;
    border-radius: 8px;
}
.warranty .header-top {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #015CAF;
    padding: 30px;
    border-radius: 8px;
    padding: 24px 16px;
}
.warranty .header-top .maximum-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #666666;
    margin-top: 8px;
}
.warranty .content-warranty {
    margin: 0 16px;
    margin-bottom: 24px;
    overflow: hidden;
    max-height: 300px;
    overflow-y: scroll;
    position: relative;
}
* {
    scrollbar-width: auto;
    scrollbar-color: #c1c1c1 #ffffff;
}

*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 15px;
    border: 3px solid #ffffff;
}
.warranty .content-warranty .picker {
    height: 28px;
    width: 28px;
    border: 1px solid #FF4713;
    display: block;
    border-radius: 50%;
    position: relative;
    background: #fff;
    cursor: pointer;
    margin-right: 8px;
}
.warranty .content-warranty .picker i{
    padding: 3px 6px;
    position: absolute;
    top: 2px;
    color: #fff;
}

.warranty .content-warranty .options .item-option {
    display: flex;
}
.warranty .content-warranty .options .item-option .label{
    padding: 2px;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #829FBF;
    width: 90%;
}

.warranty .content-warranty .protection {
    display: flex;
    margin: 8px 0px 20px 0px;
}
.warranty .content-warranty .protection .price{
    width: 50%;
    margin: 0px 5px 0px 0px;
}
.warranty .content-warranty .protection .price:last-child{
    width: 50%;
    margin: 0px 0px 0px 5px;
}

.warranty .content-warranty .protection .price button{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #FF4713;
    background: #FFFFFF;
    border:#FF4713 1px solid;
    padding: 8px 12px;
    border-radius: 8px;
    width: 100%;
}

.warranty .content-warranty .protection .price button.active{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    background: #0071D9;
    border:#0071D9 1px solid;
    padding: 8px 12px;
    border-radius: 8px;
    width: 100%;
}

.warranty .content-warranty .picker.active {
    background: #FF4713!important;
}
.warranty .content-warranty span.label.active {
    color: #0E5DA7!important;
}

.warranty .content-warranty .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #0E5DA7;
    margin: 8px 0px;
}
.warranty .footer-warranty {
    display: flex;
    width: 100%;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
.warranty .footer-warranty .cancel {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    background: #819EBF;
    width: 50%;
    padding: 15px 20px;
    cursor: pointer;
    border-bottom-left-radius: 8px;
    border: 0px;
}
.warranty .footer-warranty .save {
    width: 50%;
    padding: 15px 20px;
    background: #FF4713;
    text-align: center;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    border-bottom-right-radius: 8px;
    border: 0px;
}

.warranty .footer-warranty .save:disabled{
    width: 50%;
    padding: 15px 20px;
    background: #f97d5a;
    text-align: center;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: not-allowed;
    border-bottom-right-radius: 8px;
    border: 0px;
}
.error-message {
    color: #de1000;
    font-weight: 400;
    font-size: 13px;
    margin-top: 10px;
    text-align: center;
}
.option-price {
    
}

.shake {
    -webkit-animation-name: shake;
    animation-name: shake;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes shake {
    0%, 100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%, 30%, 50%, 70%, 90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%, 40%, 60%, 80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}
@keyframes shake {
    0%, 100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%, 30%, 50%, 70%, 90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%, 40%, 60%, 80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
} 

</style>